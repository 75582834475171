import React from "react";
import { getAddressGroup } from "../../../services/appService";
import geoService from "../../../services/geoService";
import countryCodes from "../../../services/countryCodes.json";
import {
    allStateCountyCityReceived, getDataTypeDropdwonItems,
    getFormAllCountryStates, getFormAllStateCountyCity
} from "../../store/bprmForm";
import { useDispatch, useSelector } from "react-redux";

export default function Dropdown(props) {
    const { controleId,
        comp,
        stateData: data,
        stateErrors: errors,
        isDisabled,
        ddTypeCodMngr,
        setDdTypeCodMngr,
        stateCountyCity,
        setStateCountyCity,
        wfForm,
        //allStateList,
        //allCountryList,
        handeOnChange,
        handleChange,
        setIsCountryStateChanged
    } = props;
    const allStatesSelector = useSelector(getFormAllCountryStates);
    const allStateCountyCitySelector = useSelector(getFormAllStateCountyCity);

    const dispatch = useDispatch();

    const getCodemangerOption = (id) => {
        if (ddTypeCodMngr) {
            const ddType = ddTypeCodMngr.filter((x) => x.controlId === id);
            return (
                ddType.length > 0 &&
                ddType[0].optionData.map((d, key) => (
                    <option key={key} value={d.name} data-code={d.code}>
                        {d.name}
                    </option>
                ))
            );
        }
    };

    const handleCustomChange = async ({ currentTarget: select }) => {
        const selectedItem = select.selectedOptions[0];
        const nextAttribute = select.dataset.childattribute;
        const nextType = nextAttribute.split(".");
        try {
            const items = getDataTypeDropdwonItems(nextType);
            const filterData = items.filter(
                (x) => x.parentCode === parseInt(selectedItem.dataset.code)
            );
            const ddTypeObj = { controlId: nextAttribute, optionData: filterData };
            const removeOldData = ddTypeCodMngr.filter(
                (x) => x.controlId !== nextAttribute
            );
            const addedType = [...removeOldData, ddTypeObj];
            handleChange(select);
            setDdTypeCodMngr(addedType);
            handleOnChangeTrigger(nextAttribute);
        } catch (err) {
            console.log(err);
        }
    };

    const handleOnChangeTrigger = (id) => {
        var element = document.getElementById(id);
        if (element) {
            var event = new Event("change", { bubbles: true });
            element.dispatchEvent(event);
        }

    }

    const handleDropdownValueChange = ({ currentTarget }) => {
        // const { ddTypeCodMngr, data, errors } = this.state;
        // const ddType = ddTypeCodMngr.filter(
        //   (x) => x.controlId === currentTarget.id
        // );
        // if (ddType.length > 0) {
        //   const filter = ddType[0].optionData.filter((x) => {
        //     return x.name.match(new RegExp(`${currentTarget.value}`, "gi"));
        //   });
        //   if (filter.length === 0) {
        //     data[currentTarget.id] = currentTarget.value;
        //     errors[currentTarget.id] = "Not Found";
        //   } else {
        //     data[currentTarget.id] = currentTarget.value;
        //     errors[currentTarget.id] = "";
        //   }

        //   this.setState({ data, errors });
        // }
    };

    const handleDropdownShowHide = (id, label) => {
        // if (id !== undefined) {
        //   const { ddTypeCodMngr } = this.state;
        //   const ddType = ddTypeCodMngr.filter((x) => x.controlId === id);
        //   this.setState({
        //     dropdownShowHide: !this.state.dropdownShowHide,
        //     dropdwonTitle: label,
        //     selectedDropdwonData: ddType,
        //   });
        // } else {
        //   this.setState({
        //     dropdownShowHide: !this.state.dropdownShowHide,
        //     dropdwonTitle: "",
        //     selectedDropdwonData: [],
        //   });
        // }
    };

    const handleCountryOnChange = ({ currentTarget: select }) => {
        const selectedOption = select.selectedOptions[0];
        handleChange(select);
        bindCountryDropdown(selectedOption.dataset.geonameid, select);
        setIsCountryStateChanged(true);
    };

    const bindCountryDropdown = async (geonameId, select) => {
        const stateList = geonameId > 0 ? await geoService.getGeoData(geonameId) : [];
        const ddName = select.name.split(".")[0];
        const compId = select.name;
        let stList = allStateCountyCitySelector.filter(x => x.ddType === ddName);
        if (stList.length > 0) {
            const stObj = stList[0].stateObjects.filter(x => x.compId === controleId);
            const ctObj = stList[0].countyObjects;
            const cctObj = stList[0].cityObjects;
            let childCompId = "";
            if (stObj.length > 0) {
                const upSt = stList[0].stateObjects.map(c => {
                    if (c.name === data[select.id] && c.compId === controleId) {
                        const nc = { ...c }
                        nc.name = select.selectedOptions[0].value;
                        nc.list = stateList;
                        nc.id = parseInt(geonameId);
                        nc.compId = compId;
                        childCompId = nc.childCompId;
                        return nc;
                    }
                    else {
                        return c
                    }
                })

                let childCompId1 = "";
                const upCt = ctObj.map(x => {
                    let nx = { ...x };
                    if (x.compId === childCompId) {
                        nx.list = [];
                        childCompId1 = nx.childCompId;
                    }
                    return nx;
                })

                const upCct = cctObj.map(x => {
                    let nx = { ...x };
                    if (x.compId === childCompId1) {
                        nx.list = [];
                    }
                    return nx;
                })

                let newUpdateStates = [...allStateCountyCitySelector];
                newUpdateStates = newUpdateStates.filter(x => x.ddType === ddName).map(a => {
                    const xa = { ...a };
                    xa.stateObjects = upSt;
                    xa.countyObjects = upCt;
                    xa.cityObjects = upCct;
                    return xa;
                });
                dispatch(allStateCountyCityReceived(newUpdateStates));
            }
            else {
                const sCode = stList[0].countryList.filter((x) => x.countryName === select.selectedOptions[0].value);
                if (sCode.length > 0 && comp?.childAttributeType) {
                    const childCompId2 = comp.childAttributeType.toLowerCase() + "." + comp.childAttributeObject;
                    const stNewObj = {
                        name: sCode[0].countryName,
                        list: stateList,
                        id: sCode[0].geonameId,
                        compId: controleId,
                        childCompId: childCompId2
                    }

                    let newUpdateStates = [...allStateCountyCitySelector];
                    newUpdateStates = newUpdateStates.filter(x => x.ddType === ddName).map(a => {
                        const xa = { ...a };
                        xa.stateObjects = [...xa.stateObjects, stNewObj];
                        //xa.countyObjects = [];
                        //xa.cityObjects = [];
                        return xa;
                    });
                    dispatch(allStateCountyCityReceived(newUpdateStates));
                }
            }
        }
    }

    const handleStateOnChange = ({ currentTarget: select }) => {
        const selectedOption = select.selectedOptions[0];
        handleChange(select);
        bindCountyDropdown(selectedOption.dataset.geonameid, select);
        setIsCountryStateChanged(true);
    };

    const bindCountyDropdown = async (geonameId, select) => {
        const countyList = geonameId > 0 ? await geoService.getGeoData(geonameId) : [];
        const ddName = select.name.split(".")[0];
        const compId = select.name;
        let stList = allStateCountyCitySelector.filter(x => x.ddType === ddName);
        if (stList.length > 0) {
            const stObj = stList[0].stateObjects;
            const ctObj = stList[0].countyObjects.filter(x => x.compId === controleId);
            const cctObj = stList[0].cityObjects;
            let childCompId = "";
            if (ctObj.length > 0) {
                const upSt = stList[0].countyObjects.map(c => {
                    if (c.name === data[select.id] && c.compId === controleId) {
                        const nc = { ...c }
                        nc.name = select.selectedOptions[0].value;
                        nc.list = countyList;
                        nc.id = parseInt(geonameId);
                        nc.compId = compId
                        childCompId = nc.childCompId;
                        return nc;
                    }
                    else {
                        return c
                    }
                })

                const upCct = cctObj.map(x => {
                    let nx = { ...x };
                    if (x.compId === childCompId) {
                        nx.list = [];
                    }
                    return nx;
                })

                let newUpdateStates = [...allStateCountyCitySelector];
                newUpdateStates = newUpdateStates.filter(x => x.ddType === ddName).map(a => {
                    const xa = { ...a };
                    xa.countyObjects = upSt;
                    xa.cityObjects = upCct;
                    return xa;
                });
                dispatch(allStateCountyCityReceived(newUpdateStates));
            }
            else {
                const sCode = stObj.filter((x) => x.childCompId === controleId);
                if (sCode.length > 0) {
                    const ssObj = sCode[0].list.filter(x => x.name === select.selectedOptions[0].value);
                    if (ssObj.length > 0 && comp?.childAttributeType) {
                        const childCompId1 = comp.childAttributeType.toLowerCase() + "." + comp.childAttributeObject;
                        const ctObj = {
                            name: select.selectedOptions[0].value,
                            list: countyList,
                            id: ssObj[0].geonameId,
                            compId: controleId,
                            childCompId: childCompId1
                        }
                        let newUpdateStates = [...allStateCountyCitySelector];
                        newUpdateStates = newUpdateStates.filter(x => x.ddType === ddName).map(a => {
                            const xa = { ...a };
                            xa.countyObjects = [...xa.countyObjects, ctObj];
                            //xa.cityObjects = [];
                            return xa;
                        });
                        dispatch(allStateCountyCityReceived(newUpdateStates));
                    }
                }
            }
        }
    }

    const handleCountyOnChange = ({ currentTarget: select }) => {
        const selectedOption = select.selectedOptions[0];
        handleChange(select);
        bindCityDropdown(selectedOption.dataset.geonameid, select);
        setIsCountryStateChanged(true);
    };

    const bindCityDropdown = async (geonameId, select) => {
        const cityList = geonameId > 0 ? await geoService.getGeoData(geonameId) : [];
        const ddName = select.name.split(".")[0];
        const compId = select.name;
        let stList = allStateCountyCitySelector.filter(x => x.ddType === ddName);
        if (stList.length > 0) {
            const ctObj = stList[0].countyObjects;
            const stObj = stList[0].cityObjects.filter(x => x.compId === controleId);
            if (stObj.length > 0) {
                const upSt = stList[0].cityObjects.map(c => {
                    if (c.name === data[select.id] && c.compId === controleId) {
                        const nc = { ...c }
                        nc.name = select.selectedOptions[0].value;
                        nc.list = cityList;
                        nc.id = parseInt(geonameId);
                        nc.compId = compId
                        return nc;
                    }
                    else {
                        return c
                    }
                })

                let newUpdateStates = [...allStateCountyCitySelector];
                newUpdateStates = newUpdateStates.filter(x => x.ddType === ddName).map(a => {
                    const xa = { ...a };
                    xa.cityObjects = upSt;
                    return xa;
                });
                dispatch(allStateCountyCityReceived(newUpdateStates));
            }
            else {
                const sCode = ctObj.filter((x) => x.childCompId === controleId);
                if (sCode.length > 0) {
                    const ssObj = sCode[0].list.filter(x => x.name === select.selectedOptions[0].value);
                    if (ssObj.length > 0 && comp?.childAttributeType) {
                        const childCompId1 = comp.childAttributeType.toLowerCase() + "." + comp.childAttributeObject;
                        const ctObj = {
                            name: select.selectedOptions[0].value,
                            list: cityList,
                            id: ssObj[0].geonameId,
                            compId: controleId,
                            childCompId: childCompId1
                        }
                        let newUpdateStates = [...allStateCountyCitySelector];
                        newUpdateStates = newUpdateStates.filter(x => x.ddType === ddName).map(a => {
                            const xa = { ...a };
                            xa.cityObjects = [...xa.cityObjects, ctObj];
                            return xa;
                        });
                        dispatch(allStateCountyCityReceived(newUpdateStates));
                    }
                }
            }
        }
    }

    const getCountryListById = (name, compId) => {
        const attName = comp.attributeType?.toLowerCase();
        const ctList = allStateCountyCitySelector.filter(x => x.ddType === attName);
        let countryList = [];
        if (ctList.length > 0) {
            countryList = ctList[0].countryList;
        }
        return countryList;
    }

    const getStateListByCountry = (name, childCompId) => {
        if (comp.addressGroup === "apiusstatecounty") {
            const usStateList = geoService.getStateList();
            return usStateList;
        }
        const attName = comp.attributeType?.toLowerCase();
        const stList = allStateCountyCitySelector.filter(x => x.ddType === attName);
        let stateList = [];
        if (stList.length > 0) {
            const stObj = stList[0].stateObjects?.filter(x => x.childCompId === childCompId);
            if (stObj?.length > 0) {
                stObj.map(c => {
                    c.list.map(s => {
                        if (s.name === name) {
                            stateList = c.list;
                            return;
                        }
                    })
                })

                if (stateList.length === 0) {
                    stateList = stObj[0].list;
                }
            }
        }
        return stateList;
    }

    const getCountyListByState = (name, childCompId) => {
        if (!name) return [];
        const attName = comp.attributeType?.toLowerCase();
        const ctList = allStateCountyCitySelector.filter(x => x.ddType === attName);
        let countyList = [];
        if (ctList.length > 0) {
            const stObj = ctList[0].countyObjects?.filter(x => x.childCompId === childCompId);
            if (stObj?.length > 0) {
                stObj.map(c => {
                    c.list.map(s => {
                        if (s.name === name) {
                            countyList = c.list;
                            return;
                        }
                    })
                })

                if (countyList.length === 0) {
                    countyList = stObj[0].list;
                }
            }
        }
        return countyList;
    }

    const getCityListByCounty = (name, childCompId) => {
        if (!name) return [];
        const attName = comp.attributeType?.toLowerCase();
        const ctList = allStateCountyCitySelector.filter(x => x.ddType === attName);

        let cityList = [];
        if (ctList.length > 0) {
            const stObj = ctList[0].cityObjects?.filter(x => x.childCompId === childCompId);
            if (stObj?.length > 0) {
                stObj.map(c => {
                    c.list.map(s => {
                        if (s.name?.toString() === name) {
                            cityList = c.list;
                            return;
                        }
                    })
                })

                if (cityList.length === 0) {
                    cityList = stObj[0].list;
                }
            }
        }
        return cityList;
    }

    const handeDropOnChange = ({ target }) => {
        handeOnChange({ target: { name: target.name, value: target.selectedOptions[0].value } })
    }

    let selectField = "";
    if (comp?.dropdownType === "search") {
        selectField = (
            <div className="d-flex">
                <input
                    type="text"
                    className="form-control mr-2"
                    name={controleId}
                    id={controleId}
                    value={!data[controleId] ? "" : data[controleId]}
                    onChange={handleDropdownValueChange}
                />
                <button
                    className="btn btn-outline-primary"
                    onClick={() => handleDropdownShowHide(controleId, comp.label)}
                >
                    <i className="fa fa-search"></i>
                </button>
            </div>
        );
    }

    if (comp.dropdownType === "countrycode") {
        selectField = (
            <select
                name={controleId}
                id={controleId}
                className="form-control"
                value={!data[controleId] ? "" : data[controleId]}
                onChange={handeOnChange}
                disabled={isDisabled}
            >
                <option value="">Select</option>
                {countryCodes.map((item, key) => (
                    <option key={key} value={item.dial_code + "_" + item.code}>
                        {item.dial_code} {item.name}
                    </option>
                ))}
            </select>
        );
    }
    else {
        selectField = (
            <select
                name={controleId}
                id={controleId}
                className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                value={!data[controleId] ? "" : data[controleId]}
                onChange={comp?.childAttributeType && comp?.childAttributeObject ? handleCustomChange : handeDropOnChange}
                disabled={isDisabled}
                data-childattribute={comp.childAttributeType?.toLowerCase() + "." + comp.childAttributeObject}
            >
                <option value="">Select</option>
                {comp?.dropdownvalue.filter((x) => x.name !== "").length > 0
                    ? comp?.dropdownvalue.map(
                        (d, key) => <option key={key} value={d.value}>{d.name}</option>
                    )
                    : getCodemangerOption(controleId)}
            </select>
        );
    }

    const adGroup = getAddressGroup();
    if (adGroup.indexOf(comp.addressGroup) > -1) {
        if (comp.addressObjList === "country") {

            let countryList = getCountryListById(data[controleId], controleId);
            selectField = (
                <select
                    name={controleId}
                    id={controleId}
                    className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                    value={!data[controleId] ? "" : data[controleId]}
                    onChange={handleCountryOnChange}
                    disabled={isDisabled}
                >
                    <option value="">Select</option>
                    {countryList.map((s, key) => (
                        <option
                            key={key}
                            value={s.countryName}
                            data-geonameid={s.geonameId}
                        >
                            {s.countryName}
                        </option>
                    ))}
                </select>
            );
        }
        if (comp.addressObjList === "state") {
            let stateList = [];
            stateList = getStateListByCountry(data[controleId], controleId);

            selectField = (
                <select
                    name={controleId}
                    id={controleId}
                    className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                    value={!data[controleId] ? "" : data[controleId]}
                    onChange={handleStateOnChange}
                    disabled={isDisabled}
                >
                    <option value="">Select</option>
                    {stateList.map((s, key) => (
                        <option
                            key={key}
                            value={s.name}
                            data-geonameid={s.geonameId}
                        >
                            {s.name}
                        </option>
                    ))}
                </select>
            );
        }
        if (comp.addressObjList === "county") {
            let countyList = [];
            countyList = getCountyListByState(data[controleId], controleId);

            selectField = (
                <select
                    name={controleId}
                    id={controleId}
                    className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                    value={!data[controleId] ? "" : data[controleId]}
                    onChange={handleCountyOnChange}
                    disabled={isDisabled}
                >
                    <option value="">Select</option>
                    {countyList.map((s, key) => (
                        <option
                            key={key}
                            value={s.name}
                            data-geonameid={s.geonameId}
                        >
                            {s.name}
                        </option>
                    ))}
                </select>
            );
        }
        if (comp.addressObjList === "city") {
            let cityList = [];
            cityList = getCityListByCounty(data[controleId], controleId);

            selectField = (
                <select
                    name={controleId}
                    id={controleId}
                    className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                    value={!data[controleId] ? "" : data[controleId]}
                    onChange={handeOnChange}
                    disabled={isDisabled}
                >
                    <option value="">Select</option>
                    {cityList.map((s, key) => (
                        <option
                            key={key}
                            value={s.name}
                            data-geonameid={s.geonameId}
                        >
                            {s.name}
                        </option>
                    ))}
                </select>
            );
        }
    }

    return (
        <div className={`form-group ${comp?.isRequired ? "required" : ""}`}>
            <label className="form-control-label" htmlFor={controleId}>
                {comp.label}
            </label>
            {selectField}
            {errors[controleId] && (
                <div className="alert alert-danger">{errors[controleId]}</div>
            )}
        </div>
    )
}