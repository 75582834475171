import React, { Component, useEffect, useState } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import geo from "../../services/geoService";
import BprmForm from "../../comman/BprmForm";
import countryCodes from "../../services/countryCodes.json";
import WorkflowElement from "../../app/components/WorkflowElement";
import { useDispatch, useSelector } from "react-redux";
import { getAppDefaultData, getStartedAppData, loadAppDefaultData, startNewApplication } from "../../app/store/application";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";
import { addNewSubcontractor, addNewSubcontractorUser, checkSubcontractorEmail, checkSubcontractorUsrEmail, checkSubcontractorUsrMobile, checkedSubUsrMobile, getCheckSubEmailStatus, getCheckSubUsrEmailStatus, getCheckSubUsrMobileStatus, getNewSubcontractorId, getNewSubcontractorUser, getSubcontractorBprmForm, getValidateDuplicateDataStatus, loadSubcontractorBprmForm, validatedDuplicateData } from "../../app/store/subcontractor";
import { currentWfTaskReceived, getCurrentWorkflowTask, loadCurrentWorkflowTask } from "../../app/store/WorkflowEngine";
import { addFormLibrary } from "../../app/store/forms";
import { getCurrentUser } from "../../app/store/auth";

const AddSubcontractor = (props) => {
  const { selectedObj, handleAddEditSubcontractor, selectedTab } = props;

  const [wfTask, setWfTask] = useState(null);
  const [appDefaultData, setAppDefaultData] = useState("");
  const [aWorkfinityApp, setAworkfinityApp] = useState("");
  const [appId, setAppId] = useState(1);
  const [stateData, setStateData] = useState(null);
  const [updatedStateData, setUpdatedStateData] = useState(null);
  const [uuid, setUuid] = useState("");
  const [subContactName, setSubContactName] = useState("");
  const [reqCount, setReqCount] = useState(0);
  const [wfId, setWfId] = useState(config.workflowsIds.subcontractor);
  const [saveBtnProcessStatus, setSaveBtnProcessStatus] = useState(false);
  const [newAddedSubcontractor, setNewAddedSubcontractor] = useState(false);
  const [wfForm, setWfForm] = useState(null);

  const appDefaultDataSelector = useSelector(getAppDefaultData);
  const newSubcontractorIdSelector = useSelector(getNewSubcontractorId);
  const newSubcontractorUserSelector = useSelector(getNewSubcontractorUser);
  const appStartedDataSelector = useSelector(getStartedAppData);
  const currentWorkflowTaskSelector = useSelector(getCurrentWorkflowTask);
  const checkSubEmailStatusSelector = useSelector(getCheckSubEmailStatus);
  const checkSubUsrEmailStatusSelector = useSelector(getCheckSubUsrEmailStatus);
  const checkSubUsrMobileStatusSelector = useSelector(getCheckSubUsrMobileStatus);
  const subcontractorBprmFormSelector = useSelector(getSubcontractorBprmForm);

  const currentUser = getCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAppDefaultData(1))
  }, []);

  useEffect(() => {
    setWfTask({ formId: config.formIds.subcontractor, ciid: 0 });
    dispatch(loadSubcontractorBprmForm(config.formIds.subcontractor))
    if (selectedObj?.contact1) {
      setUpdatedStateData(selectedObj.contact1.performerData);
    }
    else {
      setUpdatedStateData(null);
      setStateData(null);
    }
  }, [selectedObj, selectedTab]);

  useEffect(() => {
    if (subcontractorBprmFormSelector) setWfForm(subcontractorBprmFormSelector);
  }, [subcontractorBprmFormSelector])


  useEffect(() => {
    if (appDefaultDataSelector !== "") {
      setAppDefaultData(appDefaultDataSelector);
    }
  }, [appDefaultDataSelector]);

  const handleSubmit = async (stData) => {
    const aWorkfinityApp = { ...appDefaultData };
    const appShortName = Object.keys(aWorkfinityApp)[1];
    let aWorkfinityAppEntity = { ...aWorkfinityApp[appShortName] };

    let subcontractorenrollment = {};
    const data = {};
    Object.keys(stData).forEach(function (keyValue) {
      const dd = keyValue.split(".");
      if (dd.length > 1) {
        if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
          subcontractorenrollment["@" + dd[1]] = stData[keyValue];
        data[dd[1]] = stData[keyValue];
      }
    });

    const guid = uuidv4();
    subcontractorenrollment["EntityId"] = guid;

    data["EntityId"] = guid;
    const usrDdtype = "subcontractorenrollmentform";
    aWorkfinityAppEntity[usrDdtype] = subcontractorenrollment;
    aWorkfinityApp[appShortName] = aWorkfinityAppEntity;

    let contactName = data["Key_Contact1_First_Name"];
    if (
      !data["Key_Contact1_Middle_Name"] &&
      data["Key_Contact1_Middle_Name"] !== ""
    )
      contactName += " " + data["Key_Contact1_Middle_Name"];
    if (!data["Key_Contact1_Last_Name"])
      contactName += " " + data["Key_Contact1_Last_Name"];

    setStateData(data);
    setUuid(guid)
    setSubContactName(contactName);
    setAppDefaultData(aWorkfinityApp);

    if (!selectedObj || selectedObj === "") {
      const partEmail = data["Company_Email"];
      const keyEmail = data["Key_Contact1_Email"];
      const keyMobile = data["Key_Contact1_Mobile"];
      if (partEmail && partEmail !== "") {
        dispatch(checkSubcontractorEmail(partEmail, "Sub-Contractors"));
      } else if (keyEmail && keyEmail !== "") {
        dispatch(checkSubcontractorUsrEmail(keyEmail, "Sub-Contractors"));
      } else if (keyMobile && keyMobile !== "") {
        dispatch(checkSubcontractorUsrMobile(keyMobile, "Sub-Contractors"));
      } else {
        saveSubcontractor(data);
      }
    }
    else {
      saveSubcontractor(data);
    }
  }

  useEffect(() => {
    const keyEmail = stateData ? stateData["Key_Contact1_Email"] : "";
    const keyMobile = stateData ? stateData["Key_Contact1_Mobile"] : "";

    if (checkSubEmailStatusSelector) {
      async function userConfirmation() {
        const rs = await getConfirmAlert("Warning", "Subcontractor Email alreday exist. Do you want Add duplicate Email Address?", "warning");
        if (rs.isConfirmed) {
          if (keyEmail && keyEmail !== "") {
            dispatch(checkSubcontractorUsrEmail(keyEmail));
          } else if (keyMobile && keyMobile !== "") {
            dispatch(checkSubcontractorUsrMobile(keyMobile));
          }
          else {
            saveSubcontractor();
          }
        }
      }
      userConfirmation();
    }
    else if (checkSubEmailStatusSelector === false) {
      if (keyEmail && keyEmail !== "") {
        dispatch(checkSubcontractorUsrEmail(keyEmail));
      } else if (keyMobile && keyMobile !== "") {
        dispatch(checkSubcontractorUsrMobile(keyMobile));
      }
      else {
        saveSubcontractor();
      }
    }
  }, [checkSubEmailStatusSelector]);

  useEffect(() => {
    const keyMobile = stateData ? stateData["Key_Contact1_Mobile"] : "";
    if (checkSubUsrEmailStatusSelector) {
      async function userConfirmation() {
        const rs = await getConfirmAlert("Warning", "Subcontractor key contact Email alreday exist. Do you want Add duplicate Email Address?", "warning");
        if (rs.isConfirmed) {
          if (keyMobile && keyMobile !== "") {
            dispatch(checkSubcontractorUsrMobile(keyMobile))
          }
          else {
            saveSubcontractor(stateData);
          }
        }
      }
      userConfirmation();
    }
    else if (checkSubUsrEmailStatusSelector === false) {
      if (keyMobile && keyMobile !== "") {
        dispatch(checkSubcontractorUsrMobile(keyMobile));
      }
      else {
        saveSubcontractor(stateData);
      }
    }
  }, [checkSubUsrEmailStatusSelector]);

  useEffect(() => {
    if (checkSubUsrMobileStatusSelector) {
      async function userConfirmation() {
        const rs = await getConfirmAlert("Warning", "Subcontractor key contact Mobile Number alreday exist. Do you want Add duplicate Mobile Number?", "warning");
        if (rs.isConfirmed) {
          saveSubcontractor(stateData);
        }
      }
      userConfirmation();
    }
    else if (checkSubUsrMobileStatusSelector === false) {
      saveSubcontractor(stateData);
    }
  }, [checkSubUsrMobileStatusSelector]);

  const saveSubcontractor = async (data) => {
    let fuid = 0;
    if (selectedObj?.fuid)
      fuid = selectedObj.fuid;

    const partnerObj = {
      fuid: fuid,
      label: data["Company_Name"],
      state: data["Company_State"],
      city: data["Company_City"],
      zip: data["Company_Zip_Code"],
      partnerType: "Sub-Contractors",
      contactName: subContactName,
      entryStartNumber: 1,
      entryEndNumber: 100,
      totalEntryRecords: 100,
      email: data["Company_Email"],
      accessKey: data["Company_Address_Line1"],
      portalUrl: data["Company_Address_Line2"],
      appId: appId,
    };
    dispatch(addNewSubcontractor(partnerObj));
  }

  const saveSubcontractorUser = (partId) => {
    const data = { ...stateData };
    let regId = 0;
    if (selectedObj?.id)
      regId = selectedObj?.id;

    const reg = {
      id: regId,
      first_Name: data["Key_Contact1_First_Name"],
      middle_Name: !data["Key_Contact1_Middle_Name"] ? "" : data["Key_Contact1_Middle_Name"],
      last_Name: data["Key_Contact1_Last_Name"],
      email: data["Key_Contact1_Email"],
      mobile: data["Key_Contact1_Mobile"],
      workflowId: wfId,
      entityId: uuid,
      type: "subcontractor",
      address_Line1: data["Company_Address_Line1"],
      address_Line2: data["Company_Address_Line2"],
      us_State: data["Company_State"],
      us_County: data["Company_Country"],
      us_City: data["Company_City"],
      zip_Code: data["Company_Zip_Code"],
      title: data["Key_Contact1_Title"],
      isTeamLead: true,
      partnerId: partId,
    };
    const aWorkfinityApp = { ...appDefaultData };
    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("registration", JSON.stringify(reg));
    dispatch(addNewSubcontractorUser(formData));
    setSaveBtnProcessStatus(true);
  }

  useEffect(() => {
    if (newSubcontractorIdSelector > 0) {
      saveSubcontractorUser(newSubcontractorIdSelector);
    }
  }, [newSubcontractorIdSelector]);

  useEffect(() => {
    if (newSubcontractorUserSelector) {
      setSaveBtnProcessStatus(false);
      let regId = 0;
      if (selectedObj?.id)
        regId = selectedObj?.id;
      if (regId > 0) {
        getAlert("", "Updated Successfully", "success");
        setNewAddedSubcontractor(false);
        handleAddEditSubcontractor();
      } else {
        getAlert("", "Added Successfully", "success");
        handleRunApplication(newSubcontractorUserSelector);
      }
    }
  }, [newSubcontractorUserSelector]);

  const handleRunApplication = async (userObj) => {
    const newApplication = {
      workflowId: userObj.workflowId,
      performerId: parseInt(userObj.performerid),
      applicationId: userObj.applicationId,
    };
    dispatch(startNewApplication(newApplication));
    setNewAddedSubcontractor(true);
    handleAddEditSubcontractor();
  };

  useEffect(() => {
    if (appStartedDataSelector?.ciid > 0 && newAddedSubcontractor) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        setReqCount(1);
      }, 3000);
    }
  }, [appStartedDataSelector]);

  useEffect(() => {

    if (currentWorkflowTaskSelector?.formId === null && reqCount <= 2 && newAddedSubcontractor) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        const rqCount = reqCount + 1;
        setReqCount(rqCount);
      }, 3000);
    }
    // else if(currentWorkflowTaskSelector?.wiid > 0 && newAddedSubcontractor){
    //   const formsObj = [
    //     {
    //       formId: 47,
    //       formName: "ACH Payment Information",
    //       remarks:
    //         "This information is captured for making Payments to your company by ACH",
    //       formType: "ACH Information",
    //     },
    //     {
    //       formId: 22,
    //       formName: "Insurance Details",
    //       remarks:
    //         "Upload your company's insurance certificate(s) as per the contract requirements",
    //       formType: "Insurance Information",
    //     },
    //   ];
    //   setNewAddedSubcontractor(false);
    //   dispatch(currentWfTaskReceived(""));
    //   formsObj.map((item) => addReqFormLibrary(currentWorkflowTaskSelector, item));
    // }
  }, [currentWorkflowTaskSelector]);

  const addReqFormLibrary = async (currentTask, frmObj) => {
    let workflowTask = { ...currentTask };
    workflowTask["ApplicantId"] = workflowTask.performerId;
    workflowTask["performerId"] = currentUser.id;
    const frmData = new FormData();
    frmData.append("workflowTask", JSON.stringify(workflowTask));
    frmData.append("formId", frmObj.formId);
    frmData.append("formName", frmObj.formName);
    frmData.append("remarks", frmObj.remarks);
    frmData.append("id", "");
    frmData.append("formType", frmObj.formType);
    frmData.append("status", "1");
    dispatch(addFormLibrary(frmData));
  };

  const handleNextTask = (data) => {
    if (data)
      handleSubmit(data);
  }

  return (
    <div className="col-md-12">
      <div className="row">

        {wfForm && <WorkflowElement
          wfTask={wfTask}
          wfForm={wfForm}
          handleNextTask={handleNextTask}
          viewType={false}
          appSummary={false}
          codeVerified={true}
          wfTaskType={"form"}
          saveBtnProcessStatus={saveBtnProcessStatus}
          setSaveBtnProcessStatus={setSaveBtnProcessStatus}
          updatedStateData={updatedStateData}
        />}

        <div className="col-md-12 text-center">
          {/* <Button
            variant="primary"
            disabled={btnProcess}
            onClick={this.doSubmit}
          >
            {btnProcess && (
              <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
            )}
            Save
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default AddSubcontractor;
