import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Joi from "joi-browser";
import SubForm from "../../components/PartnerManage/SubForm";
import {
    getBprmForm,
    loadBprmForm,
    getStateCountyCity,
    getDataTypeCodemanager,
    saveBprmForm,
    updateBprmForm,
    getUpdateStatus,
    allStateCountyCityReceived,
    getTaskFormSavedStatus,
    taskFormSaved
} from "../../app/store/bprmForm";
import { getApplication, loadApplication, getApplicationData } from "../../app/store/application";
import { workflowTaskDone, executeBprmFormRule, getExecuteRuleAppData, getTaskDoneStatus, taskDone } from "../store/WorkflowEngine";
import Label from "./BprmDynamicControls/Label";
import TextBox from "./BprmDynamicControls/TextBox";
import Dropdown from "./BprmDynamicControls/Dropdown";
import Date from "./BprmDynamicControls/Date";
import Button from "./BprmDynamicControls/Button";
import Multiline from "./BprmDynamicControls/Multiline";
import { getCurrentUser } from "../store/auth";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";
import Radio from "./BprmDynamicControls/Radio";
import FileUpload from "./BprmDynamicControls/FileUpload";
import geoService, { getAllCountries, getStateList } from "../../services/geoService";
import CheckBox from "./BprmDynamicControls/CheckBox";

export default function WorkflowElement(props) {
    const { wfTask, handleNextTask, appSummary,
        viewType, codeVerified, updatedStateData,
        wfTaskType, saveBtnProcessStatus,
        setSaveBtnProcessStatus, workflowFormData,
        documentInfo, handleCloseButton, actionType,
        wfForm } = props;

    const [stateSchema, setStateSchema] = useState({});
    const [stateData, setStateData] = useState({});
    const [stateErrors, setStateErrors] = useState({});
    const [encryptionDecryptions, setEncryptionDecryptions] = useState({});
    //const [wfForm, setWfForm] = useState("");
    const [documents, setDocuments] = useState([]);
    const [application, setApplication] = useState("");
    const [aWorkfinityAppEntity, setAworkfinityAppEntity] = useState("");
    const [aWorkfinityApp, setAworkfinityApp] = useState("");
    const [stateCountyCity, setStateCountyCity] = useState([]);
    const [subformDataType, setSubformDataType] = useState([]);
    const [ddTypeCodMngr, setDdTypeCodMngr] = useState([]);
    const [frmDDTypes, setFrmDDTypes] = useState([]);
    const [subformItemIndex, setSubformItemIndex] = useState(1);
    const [codeIsVerified, setCodeIsVerified] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [btnProcessStatus, setBtnProcessStatus] = useState(saveBtnProcessStatus);
    const [isTaskForm, setIsTaskForm] = useState(false);
    const [isCountryStateChanged, setIsCountryStateChanged] = useState(true);
    const [currentButtonType, setCurrentButtonType] = useState("");

    const [allCountryList, SetAllCountryList] = useState([]);
    const [allStateList, setAllStateList] = useState([]);
    const [allCountyList, setAllCountyList] = useState([]);
    const [allCityList, setAllCityList] = useState([]);

    //const bprmFormSelector = useSelector(getBprmForm);
    const applicationSelector = useSelector(getApplication);
    const ruleAppDataSelector = useSelector(getExecuteRuleAppData);
    const updateStatusSelector = useSelector(getUpdateStatus)
    const taskFormSavedStatusSelector = useSelector(getTaskFormSavedStatus);
    const taskDoneStatusSelector = useSelector(getTaskDoneStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!saveBtnProcessStatus) {
            setBtnProcessStatus(false);
            if (setSaveBtnProcessStatus)
                setSaveBtnProcessStatus(null);
        }
    }, [saveBtnProcessStatus])

    useEffect(() => {
        // if (wfTask === "")
        //     setWfForm("");

        if (wfTask?.ciid > 0 && !appSummary)
            dispatch(loadApplication(wfTask?.ciid));

        // if (wfTask?.formId > 0)
        //     dispatch(loadBprmForm(wfTask?.formId));

        if (viewType) {
            setIsDisabled(true);
            setCodeIsVerified(false);
        }
        else {
            setIsDisabled(false);
            setCodeIsVerified(true);
        }
    }, [wfTask]);

    useEffect(() => {
        if (codeVerified) setCodeIsVerified(true);
    }, [codeVerified]);

    useEffect(() => {
        if (applicationSelector.length > 0) {
            const latestApp = applicationSelector[applicationSelector.length - 1];
            setApplication(latestApp);
            const obj = getApplicationData(latestApp.applicationData);
            if (obj?.aWorkfinityApp)
                setAworkfinityApp(obj.aWorkfinityApp);
            if (obj?.aWorkfinityAppEntity)
                setAworkfinityAppEntity(obj?.aWorkfinityAppEntity);

            if (workflowFormData && obj?.data) {
                const newData = { ...obj.data, ...workflowFormData };
                setStateData(newData);

            } else if (obj?.data) {
                setStateData(obj.data);
            }

        } else {
            setAworkfinityApp("");
            setAworkfinityAppEntity("");
            setStateData({});
        }
    }, [applicationSelector]);

    useEffect(() => {
        if (wfForm)
            loadStateCounty();
    }, [stateData]);

    useEffect(() => {
        if (wfForm && wfForm !== "") {
            if ((wfForm?.isEdit !== null && !wfForm?.isEdit && wfTaskType !== "viewForm") || wfTask?.taskDoneStatus === 4) setIsDisabled(true);

            const loadDDType = async () => {
                const ddResult = await getDataTypeCodemanager(wfForm);
                setDdTypeCodMngr(ddResult.ddTypeCodMngr);
                setSubformDataType(ddResult.subformDataType);
            }
            loadDDType();
            setBtnProcessStatus(false);
            setDocuments([]);
        }
    }, [wfForm])

    useEffect(() => {
        if (updateStatusSelector === "success") {
            getAlert("", "Updated successfully", "success");
            handleNextTask();
        }
    }, [updateStatusSelector]);

    useEffect(() => {
        if (updatedStateData && updatedStateData !== "") {
            const obj = getApplicationData(updatedStateData);
            if (obj?.aWorkfinityApp)
                setAworkfinityApp(obj.aWorkfinityApp);

            if (obj?.aWorkfinityAppEntity)
                setAworkfinityAppEntity(obj?.aWorkfinityAppEntity);
            if (obj?.data)
                setStateData(obj.data);
        }
        else {
            setStateData({});
        }
    }, [updatedStateData, wfTask]);

    const loadStateCounty = async () => {
        const addResult = await getStateCountyCity(wfForm, stateData);
        const presult = await Promise.all(Object.keys(addResult).map(async (x) => {
            const obj = addResult[x];
            if (obj) {
                const nObj = await Promise.all(obj.stateObjects.map(async (s) => {
                    const nX = { ...s }
                    const rs = await geoService.getGeoData(s.id);
                    nX.list = rs;
                    return nX;
                }));

                obj.stateObjects = nObj;

                const cObj = await Promise.all(obj.countyObjects.map(async (s) => {
                    const stList = nObj.filter(x => x.childCompId === s.compId);
                    if (stList.length > 0) {
                        const stObj = stList[0].list.filter(x => x.name === s.name);
                        if (stObj.length > 0) {
                            const nX = { ...s }
                            const rs = await geoService.getGeoData(stObj[0].geonameId);
                            nX.list = rs;
                            nX.id = stObj[0].geonameId;
                            return nX;
                        }
                    }
                }));

                obj.countyObjects = cObj;

                const ccObj = await Promise.all(obj.cityObjects.map(async (s) => {
                    const ctList = cObj.filter(x => x.childCompId === s.compId);
                    if (ctList.length > 0) {
                        const stObj = ctList[0].list.filter(x => x.name === s.name);
                        if (stObj.length > 0) {
                            const nX = { ...s }
                            const rs = await geoService.getGeoData(stObj[0].geonameId);
                            nX.list = rs;
                            nX.id = stObj[0].geonameId;
                            return nX;
                        }
                    }
                }));

                obj.cityObjects = ccObj;
            }
            return obj;
        }));
        dispatch(allStateCountyCityReceived(presult));
    }

    const handleSubformModalShowHide = () => {

    }

    const handleSubformSave = (aWorkfinityAppEntity, subformId) => {
        //setState({ aWorkfinityAppEntity, ["showHide" + subformId]: false });
    };

    const handeOnChange = ({ target: input }) => {
        handleChange(input);
        if (input.dataset?.encryption) {
            addEncryptionFieldData(input.name, input.value);
        }
    };

    const addEncryptionFieldData = (name, value) => {
        const encryp = { ...encryptionDecryptions };
        const data = { ...stateData }
        const newValue = value.replace(/\-/g, "");

        let modifiedValue = "";
        for (let i = 0; i < newValue.length; i++) {
            modifiedValue += "*";
        }
        if (encryp[name + "encrypt"]) {
            data[name] = modifiedValue;
            encryp[name] = encryp[name] + value.replace(/[^\d]/g, "");
        } else {
            data[name] = value.replace(/[^\d]/g, "");
            encryp[name] = modifiedValue;
        }
        setEncryptionDecryptions(encryp);
        setStateData(data);
    };

    const handleEncryptionDecryption = (name) => {
        const updatedData = { ...stateData };
        const encryp = { ...encryptionDecryptions };
        if (encryp[name + "encrypt"]) {
            const actualValue = encryp[name];
            const encryptedValue = updatedData[name];
            encryp[name] = encryptedValue;
            updatedData[name] = actualValue;
            encryp[name + "encrypt"] = false;
        } else {
            const encryptedValue = encryp[name];
            const actualValue = updatedData[name];
            encryp[name] = actualValue;
            updatedData[name] = encryptedValue;
            encryp[name + "encrypt"] = true;
        }
        setEncryptionDecryptions(encryp);
        setStateData(updatedData);
    };

    const handleChange = (input) => {
        const data = { ...stateData };
        const errors = { ...stateErrors };
        const errorMessage = validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        data[input.name] = input.value;
        setStateData(data);
        setStateErrors(errors);
    };

    const onFileChange = (event) => {
        const docs = [...documents];
        const data = { ...stateData };

        const docIndex = docs.findIndex((x) => x.refId === event.target.name);
        if (docIndex > -1) {
            docs.splice(docIndex, 1);
        }
        let indx = 0;
        if (docs.length > 0) {
            indx = docs[docs.length - 1].id;
        }
        for (const file of event.target.files) {
            indx += 1;
            const ext = file.name.split(".");
            var doc = {
                id: indx,
                name: file.name,
                newName: file.name,
                type: file.type,
                file: file,
                refId: event.target.name,
                extension: ext.length > 1 ? ext[1] : "",
            };
            docs.push(doc);
        }

        data[event.target.id] = event.target.files[0].name;
        validateDocuments(docs);
        setDocuments(docs);
        setStateData(data);
    };

    const validateDocuments = (docs) => {
        const errors = { ...stateErrors };
        const data = { ...stateData };
        if (docs.length > 0) {
            data["docs"] = docs.length.toString();
            delete errors["docs"];
        } else {
            const obj = { schedule: "" };
            const schema = { schedule: stateSchema["docs"] };
            const { error } = Joi.validate(obj, schema);
            errors["docs"] = error.details[0].message;
            data["docs"] = "";
        }
        setStateData(data);
        setStateErrors(errors);
    };

    const validateProperty = ({ name, value }) => {
        if (stateSchema[name] != undefined) {
            const obj = { [name]: value };
            const schema = { [name]: stateSchema[name] };
            const { error } = Joi.validate(obj, schema);
            return error ? error.details[0].message : null;
        } else {
            return null;
        }
    };

    const validate = () => {
        if (wfTask.status) return true;
        const options = { abortEarly: false, allowUnknown: true };
        const result = Joi.validate(stateData, stateSchema, options);
        if (!result.error) return null;
        const errors = {};
        for (let item of result.error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    const doSubmit = (btn) => {
        setBtnProcessStatus(true);
        const btnType = btn.name;
        setCurrentButtonType(btnType);
        if (wfTaskType === "form") {
            setBtnProcessStatus(true);
            handleNextTask(stateData, btnType);
        }
        else {
            const processId = btn.dataset.process;
            Object.keys(stateData).forEach(function (keyValue) {
                const dd = keyValue.split(".");
                if (dd.length > 1) {
                    if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
                        if (aWorkfinityAppEntity[dd[0].toLowerCase()] !== undefined) {
                            var item = stateData[keyValue];
                            if (Array.isArray(item))
                                aWorkfinityAppEntity[dd[0].toLowerCase()][dd[1]] = item;
                            else aWorkfinityAppEntity[dd[0].toLowerCase()]["@" + dd[1]] = item;
                        }
                }
            });

            const appShortName = Object.keys(aWorkfinityApp)[1];
            aWorkfinityApp[appShortName] = aWorkfinityAppEntity;
            const frmData = new FormData();
            frmData.append("appEntity", JSON.stringify(aWorkfinityApp));
            frmData.append("workItem", JSON.stringify(wfTask));
            frmData.append("upDocs", JSON.stringify(documents));
            documents.map((file, key) =>
                frmData.append("files[" + key + "]", file.file)
            );

            if (documentInfo) {
                frmData.append("docType", documentInfo.docType);
                frmData.append("updType", documentInfo.updType);
                frmData.append("message", documentInfo.message);
            }

            dispatch(saveBprmForm(frmData));

            if (wfTaskType === "viewForm") {
                setBtnProcessStatus(true);
                handleNextTask(frmData);
            }
            else if (btnType !== "save" && wfTask?.wiid > 0) {
                setBtnProcessStatus(true);
                setIsTaskForm(true);
            }
        }
    }

    useEffect(() => {
        if (taskFormSavedStatusSelector && isTaskForm) {
            dispatch(workflowTaskDone(wfTask));
            dispatch(taskFormSaved(false));
            setIsTaskForm(false);
        }
        else if (taskFormSavedStatusSelector) {
            setIsTaskForm(false);
            dispatch(taskFormSaved(false))
            setBtnProcessStatus(false);
        }
    }, [taskFormSavedStatusSelector]);

    useEffect(() => {
        if (taskDoneStatusSelector) {
            dispatch(taskDone({ status: false }))
            handleNextTask(currentButtonType);
        }
    }, [taskDoneStatusSelector])

    const updateBprmFormData = async () => {
        const disValus = _.uniq(frmDDTypes);
        Object.keys(stateData).forEach(function (keyValue) {
            const dd = keyValue.split(".");
            if (dd.length > 1) {
                if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
                    if (aWorkfinityAppEntity[dd[0].toLowerCase()] !== undefined) {
                        var item = stateData[keyValue];
                        if (Array.isArray(item))
                            aWorkfinityAppEntity[dd[0].toLowerCase()][dd[1]] = item;
                        else aWorkfinityAppEntity[dd[0].toLowerCase()]["@" + dd[1]] = item;
                    }
            }
        });
        const appShortName = Object.keys(aWorkfinityApp)[1];
        aWorkfinityApp[appShortName] = aWorkfinityAppEntity;
        const currentUser = getCurrentUser();
        const frmData = new FormData();
        frmData.append("appEntity", JSON.stringify(aWorkfinityApp));
        frmData.append("ciid", wfTask.ciid);
        frmData.append("ddTypes", disValus);
        frmData.append("formId", wfTask.formId);
        frmData.append("performerId", currentUser.id);
        frmData.append("userName", currentUser.name);
        dispatch(updateBprmForm(frmData));
    };

    const getEvaluatedValue = async (comp) => {
        const ruleId = comp.rule;
        const data = { ...stateData };
        Object.keys(data).forEach(function (keyValue) {
            const dd = keyValue.split(".");
            if (dd.length > 1) {
                if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
                    if (aWorkfinityAppEntity[dd[0].toLowerCase()] !== undefined) {
                        var item = data[keyValue];
                        if (Array.isArray(item))
                            aWorkfinityAppEntity[dd[0].toLowerCase()][dd[1]] = item;
                        else aWorkfinityAppEntity[dd[0].toLowerCase()]["@" + dd[1]] = item;
                    }
            }
        });
        const appShortName = Object.keys(aWorkfinityApp)[1];
        aWorkfinityApp[appShortName] = aWorkfinityAppEntity;
        const formData = new FormData();
        formData.append("appEntity", JSON.stringify(aWorkfinityApp));
        formData.append("appId", 1);
        formData.append("ruleId", ruleId);
        dispatch(executeBprmFormRule(formData));
    };

    useEffect(() => {

        if (ruleAppDataSelector !== "") {
            const appShortName = Object.keys(ruleAppDataSelector)[1];
            const newAWorkfinityAppEntity = ruleAppDataSelector[appShortName];
            const data = { ...stateData };
            if (newAWorkfinityAppEntity !== "") {
                Object.keys(newAWorkfinityAppEntity).forEach(function (keyValue) {
                    if (
                        keyValue !== "@xmlns:xsd" &&
                        keyValue !== "@xmlns:xsi" &&
                        keyValue !== "EntityId"
                    ) {
                        Object.keys(newAWorkfinityAppEntity[keyValue]).forEach(function (
                            keyValue1
                        ) {
                            if (keyValue1 !== "EntityId") {
                                var item = newAWorkfinityAppEntity[keyValue][keyValue1];
                                if (typeof item === "object") {
                                    if (Array.isArray(item))
                                        data[keyValue + "." + keyValue1.replace("@", "")] = item;
                                    else data[keyValue + "." + keyValue1.replace("@", "")] = [item];
                                } else {
                                    data[keyValue + "." + keyValue1.replace("@", "")] = item;
                                }
                            }
                        });
                    }
                });
            }
            setStateData(data);
        }
    }, [ruleAppDataSelector])

    const getSubformTableHeader = (comp) => {
        const tblHd = subformDataType?.filter(
            (x) =>
                x.dataType ===
                comp.attributeType &&
                x.attribute ===
                comp.attributeObject
        );
        if (tblHd.length === 0) return <tr>
            <th>Datatype not found</th>
        </tr>
        return <tr>
            {tblHd[0].itemList.map(item => <th>
                {item.split(".")[1]}
            </th>)}
            {comp.isEdit && <th>Action</th>}
        </tr>
    }

    const getSubformTableBody = (comp) => {
        const dataObj = aWorkfinityAppEntity[comp.attributeType.toLowerCase()][comp.attributeObject];
        if (dataObj && dataObj?.length > 0) {
            return <tr>
                {dataObj.map((obj, key) => {
                    const tblBd = subformDataType?.filter(
                        (x) =>
                            x.dataType ===
                            comp.attributeType &&
                            x.attribute ===
                            comp.attributeObject
                    );

                    if (tblBd.length > 0) {
                        return tblBd[0].itemList.map(item => <td>
                            {obj["@" + item.split(".")[0]]}
                        </td>)
                    }
                    return comp.isEdit && <td>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                                handleSubformModalShowHide(
                                    "showHide" +
                                    comp.subform +
                                    "-" +
                                    key
                                )
                            }
                        >
                            Edit
                        </button>
                    </td>
                })}
            </tr>
        }

        return <tr><td>No Data</td></tr>
    }

    const getHighestColNum = (elements) => {
        const col = elements.reduce(function (prev, current) {
            return prev.components.length > current.components.length
                ? prev
                : current;
        });
        return col.components.length;
    }

    const handlePrintPdf = async (event) => {
        // const frmData = new FormData();
        // //console.log(this.refs.printBody.outerHTML);
        // frmData.append("html", this.refs.printBody.outerHTML);
        // const { data } = await http.post(config.PrintTemplate, frmData);
        // let pdfWindow = window.open("");
        // pdfWindow.document.write(
        //   "<iframe width='100%' height='100%' src='" +
        //     data +
        //     "' style='border: none;'></iframe>"
        // );
    };

    const handleClose = () => {
        if (handleCloseButton)
            handleCloseButton();
    }

    let elements = [];
    if (wfForm?.elementJson) {
        try {
            elements = JSON.parse(wfForm?.elementJson);

        } catch {
            elements = [];
        }
    }

    const compNum = elements.length > 0 && wfTaskType === "template" ? getHighestColNum(elements) : 0;
    return (
        <fieldset disabled={false}>
            {wfTaskType === "template" &&
                <div id="printBody">
                    <table className="table table-bordered bg-white">
                        {elements.map((col) => (
                            <tr>
                                {col.components.filter(x => x.dataType !== "button").map((comp) => {
                                    let controleId = "";
                                    if (comp.attributeType)
                                        controleId = comp.attributeType?.toLowerCase();

                                    if (comp.attributeObject)
                                        controleId += "." + comp.attributeObject;

                                    return (
                                        <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                            colSpan={
                                                col.components.length === 1
                                                    ? compNum
                                                    : compNum - col.components.length
                                            }
                                        >
                                            {/* {this.rendorHtmlComponent(comp)} */}
                                            {comp.dataType === "label" &&
                                                <Label comp={comp} controleId={controleId} stateData={stateData} />}
                                            {comp.dataType === "textBox" &&
                                                <TextBox
                                                    comp={comp}
                                                    controleId={controleId}
                                                    stateData={stateData}
                                                    stateErrors={stateErrors}
                                                    stateSchema={stateSchema}
                                                    codeIsVerified={codeIsVerified}
                                                    isDisabled={isDisabled}
                                                    handleChange={handleChange}
                                                    handeOnChange={handeOnChange}
                                                    getEvaluatedValue={getEvaluatedValue}
                                                />}
                                            {comp.dataType === "multiLineBox" && <Multiline
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                isDisabled={isDisabled}
                                                encryptionDecryptions={encryptionDecryptions}
                                                handeOnChange={handeOnChange}
                                                getEvaluatedValue={getEvaluatedValue}
                                                handleEncryptionDecryption={handleEncryptionDecryption}
                                            />
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </table>
                </div>}
            {wfTaskType !== "template" &&
                <div className="row">
                    {elements.map((col) =>
                        <div className={`col-${col.width} ${col?.cssclass ? col.cssclass : ""}`}>
                            {col.components.map((comp, key) => {
                                let controleId = "";
                                if (comp.attributeType)
                                    controleId = comp.attributeType?.toLowerCase();

                                if (comp.attributeObject)
                                    controleId += "." + comp.attributeObject;

                                if (comp.isRequired && controleId != "" && comp.dataType !== "esign") {
                                    stateSchema[controleId] = Joi.string().required().label(comp.label);
                                }

                                if (comp.attributeType && frmDDTypes.indexOf(comp.attributeType) === -1) {
                                    frmDDTypes.push(comp.attributeType);
                                }

                                if (comp.dataType === "list") {
                                    const formDD = subformDataType?.filter(
                                        (x) =>
                                            x.dataType ===
                                            comp.attributeType &&
                                            x.attribute ===
                                            comp.attributeObject
                                    );
                                    return (
                                        <React.Fragment>
                                            <SubForm
                                                showHide={stateData["showHide" + comp.subform]}
                                                handleModalShowHide={handleSubformModalShowHide}
                                                title={comp.label}
                                                subFormId={comp.subform}
                                                aWorkfinityAppEntity={aWorkfinityAppEntity}
                                                subFormDataType={comp.attributeType}
                                                subFormAttribute={comp.attributeObject}
                                                handleSubformSave={handleSubformSave}
                                                subformItemIndex={subformItemIndex}
                                                formDD={formDD.length > 0 && formDD[0]}
                                            ></SubForm>
                                            <table
                                                className="table table-bordered"
                                                id={`table-to-xls-${comp.subform}`}
                                            >
                                                <thead className="thead-light">
                                                    {getSubformTableHeader(comp)}
                                                </thead>
                                                <tbody>
                                                    {getSubformTableBody(comp)}
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                } else
                                    if (comp.dataType === "button") {
                                        if (comp.buttonType?.type === "editForm" || comp.buttonType?.type === "subform") {
                                            return <Button comp={comp}
                                                btnProcessStatus={btnProcessStatus}
                                                isDisabled={isDisabled}
                                                isEdit={wfForm?.isEdit}
                                                handleClose={handleClose}
                                            />
                                        }
                                    } else {
                                        if (comp.dataType === "label") {
                                            return <Label comp={comp} controleId={controleId} stateData={stateData} />
                                        }
                                        if (comp.dataType === "textBox") {
                                            return <TextBox
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                stateSchema={stateSchema}
                                                codeIsVerified={codeIsVerified}
                                                isDisabled={isDisabled}
                                                handleChange={handleChange}
                                                handeOnChange={handeOnChange}
                                                getEvaluatedValue={getEvaluatedValue}
                                            />
                                        }

                                        if (comp.dataType === "dropDown") {
                                            return <Dropdown
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                codeIsVerified={codeIsVerified}
                                                isDisabled={isDisabled}
                                                stateCountyCity={stateCountyCity}
                                                setStateCountyCity={setStateCountyCity}
                                                ddTypeCodMngr={ddTypeCodMngr}
                                                setDdTypeCodMngr={setDdTypeCodMngr}
                                                handleChange={handleChange}
                                                handeOnChange={handeOnChange}
                                                setIsCountryStateChanged={setIsCountryStateChanged}
                                            //allStateList={allStateList}
                                            //allCountryList={allCountryList}
                                            />
                                        }

                                        if (comp.dataType === "date") {
                                            return <Date
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                codeIsVerified={codeIsVerified}
                                                isDisabled={isDisabled}
                                                handleChange={handleChange}
                                            />
                                        }

                                        if (comp.dataType === "multiLineBox") {
                                            return <Multiline
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                isDisabled={isDisabled}
                                                encryptionDecryptions={encryptionDecryptions}
                                                handeOnChange={handeOnChange}
                                                getEvaluatedValue={getEvaluatedValue}
                                                handleEncryptionDecryption={handleEncryptionDecryption}
                                            />
                                        }

                                        if (comp.dataType === "radio") {
                                            return <Radio
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                isDisabled={isDisabled}
                                                handeOnChange={handeOnChange}
                                            />
                                        }

                                        if (comp.dataType === "checkBox") {
                                            return <CheckBox
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                isDisabled={isDisabled}
                                                handleChange={handleChange}
                                                ddTypeCodMngr={ddTypeCodMngr}
                                            />
                                        }

                                        if (comp.dataType === "file") {
                                            return <FileUpload
                                                comp={comp}
                                                controleId={controleId}
                                                stateData={stateData}
                                                stateErrors={stateErrors}
                                                isDisabled={isDisabled}
                                                onFileChange={onFileChange}
                                            />
                                        }
                                    }
                            })}
                        </div>
                    )}
                    {!viewType &&
                        <div className="col-md-12">
                            <div className="row">
                                {appSummary &&
                                    <div className="col-md-12 text-center mb-2">
                                        <button
                                            className="btn btn-success"
                                            disabled={viewType}
                                            onClick={() => updateBprmFormData()}
                                        >
                                            Update
                                        </button>
                                    </div>
                                }

                                {!appSummary &&
                                    <div className="col-md-12 text-center mb-2">
                                        {elements.map((col) =>
                                            col.components.filter(x => x.dataType === "button").map((comp) => {
                                                if (comp.buttonType?.type !== "taskCompleted"
                                                    && comp.buttonType?.type !== "submit"
                                                    && comp.buttonType?.type !== "saveAndContinue")
                                                    return <Button comp={comp}
                                                        btnProcessStatus={btnProcessStatus}
                                                        //setBtnProcessStatus={setBtnProcessStatus}
                                                        isDisabled={isDisabled}
                                                        setIsDisabled={setIsDisabled}
                                                        validate={validate}
                                                        doSubmit={doSubmit}
                                                        isEdit={wfForm?.isEdit}
                                                        handleClose={handleClose}
                                                    />
                                            })
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="card shadow-none border">
                                <div className="d-flex flex-row justify-content-center card-header text-center bg-lighter p-2">

                                    {actionType === "workflow" &&

                                        <h4 className="mb-0 text-dark">
                                            <i
                                                className="fa fa-arrow-right mr-2"
                                                aria-hidden="true"
                                            ></i>
                                            WORKFLOW ACTION &nbsp;&nbsp; </h4>}


                                    {!appSummary &&
                                        <>
                                            {elements.map((col) =>
                                                col.components.filter(x => x.dataType === "button").map((comp) => {
                                                    if (comp.buttonType?.type === "taskCompleted"
                                                        || comp.buttonType?.type === "submit"
                                                        || comp.buttonType?.type === "saveAndContinue")
                                                        return <Button comp={comp}
                                                            btnProcessStatus={btnProcessStatus}
                                                            //setBtnProcessStatus={setBtnProcessStatus}
                                                            isDisabled={isDisabled}
                                                            setIsDisabled={setIsDisabled}
                                                            validate={validate}
                                                            doSubmit={doSubmit}
                                                            isEdit={wfForm?.isEdit}
                                                            handleClose={handleClose}
                                                            size="btn-sm"
                                                        />
                                                })
                                            )}
                                        </>
                                    }

                                </div>
                            </div>
                        </div>}
                </div>}

            {wfTaskType === "template" && <div className="col-md-12 text-center">
                <button className="btn btn-outline-dark" onClick={handlePrintPdf}>
                    Print
                </button>
            </div>}
        </fieldset>
    )
}