import React, { useEffect, useState } from "react";
import PageHeader from "../../PageHeader";
import WorkflowRolesTable from "../../WorkflowRolesTable";
import PageSizeOptions from "../../PageSizeOptions";
import Pagination from "../../../comman/pagination";
import { paginate, calculateIndex } from "../../../utlis/paginate";
import AddEditPermission from "../../AddEditPermission";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRoles, loadWorkflowRoles, getRoleTypes, loadRoleTypes,
  loadWorkflowRolesByFilter, getFilterAllRoles
} from "../../../app/store/workflowRole";
import { useNavigate, Link } from "react-router-dom"
import Moment from "react-moment";
import { Tab, Tabs } from "react-bootstrap";
import { setCurrentPageTitle, setCurrentRoute } from "../../../app/store/layoutUI";
import { getAlert } from "../../../services/appAlerts";

export default function WorkflowUser(props) {
  const workflowRolesList = useSelector(getAllRoles);
  const workflowRolesFilterList = useSelector(getFilterAllRoles);
  const roleTypes = useSelector(getRoleTypes);
  const totalUsers = workflowRolesList.reduce((a, v) => (a = a + v.activeUsers), 0);
  const [workflowRoles, setWorkflowRoles] = useState([]);
  const [filterData, setFilterData] = useState([]);
  //const [roleTypes, setRoleTypes] = useState([]);
  //const [totalUsers,setTotalUsers]=useState(0);
  const [rowsCount, setRowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [applicationId, setApplicationId] = useState(0);
  const [permissionShowHide, setPermissionShowHide] = useState(false);
  const [rType, setrType] = useState("");
  const [rName, setrName] = useState("");
  const [rId, setRId] = useState("");
  const [dataType, setDataType] = useState("");
  const [selectedObj, setSelectedObj] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [selectedKey,setSelectedKey]=useState("home");

  const route = [
    { url: "", name: "System Administration" },
    { url: "", name: "Authorized Users" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setCurrentRoute(route));
    dispatch(setCurrentPageTitle("Main Users"))
  }, [])

  useEffect(() => {
    dispatch(loadWorkflowRoles(pageSize, currentPage));
    dispatch(loadRoleTypes("WF Role Type"));
  }, []);

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    setSelectedRoleId(0)
    if (dataType === "filter")
      getPagedData(workflowRolesFilterList, page, pageSize)
    else
      dispatch(loadWorkflowRoles(pageSize, page));
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    setSelectedRoleId(0)
    if (dataType === "filter")
      getPagedData(workflowRolesFilterList, currentTarget.value, pageSize);
    else
      dispatch(loadWorkflowRoles(currentTarget.value, currentPage));
  };

  const handleApplicationFiltered = ({ currentTarget }) => {
    setApplicationId(currentTarget.value);
  };

  const getPagedData = (list, currentPage, pageSize) => {
    const items = paginate(list, currentPage, pageSize);
    if (items.length > 0)
      setRowsCount(items[0].totalCount);

    setWorkflowRoles(items);
  };

  useEffect(() => {
    if (workflowRolesList.length > 0) {
      if (workflowRolesList.length > 0)
        setRowsCount(workflowRolesList[0].totalCount);
      setWorkflowRoles(workflowRolesList);
    }
  }, [workflowRolesList]);

  useEffect(() => {
    setPageSize(5);
    setCurrentPage(1);
    getPagedData(workflowRolesFilterList, 1, 5);
  }, [workflowRolesFilterList]);

  const handleFilter = async () => {
    setDataType("filter");
    setWorkflowRoles([]);
    var dt = new FormData();
    dt.append("name", rName);
    dt.append("type", rType);
    dt.append("id", rId);
    dispatch(loadWorkflowRolesByFilter(dt));
    setSelectedRoleId(0);
  };

  const handleFilterCancel = () => {

    dispatch(loadWorkflowRoles(pageSize, currentPage))

    setrName("");
    setrType("");
    setRId("");
    setDataType("");
    setSelectedRoleId(0)
  };

  const handlePermissionShowHide = () => {
    setSelectedRoleId(0);
    setSelectedObj(null);
    setSelectedKey("home");
    getAlert("","Updated Successfully","success");
  };

  const handleManagePerformer = () => {
    const roleObj=workflowRoles.filter(x=>x.roleid === selectedRoleId);
    if(roleObj.length > 0){
      navigate("/ManageWorkflowPerformer", { state: roleObj[0] });
    }
  }

  const handleResetSelection = () => {
    setSelectedRoleId(0);
    setSelectedObj(null);
  }

  const handleSelectRole =(item)=>{
    setSelectedRoleId(item.roleid);
    setSelectedObj(item);
  }

  const handleTabSelect=(tab)=>{
    setSelectedKey(tab);
  }

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card mb-4 shadow-none">
          <div className="card-header border-0 p-2">
            <div className="row">
              <div className="col-md-10">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-2 text-right">
                <button
                  className="btn btn-sm btn-outline-default"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  style={{minWidth:"65%"}}

                >
                  <i className="fa fa-search" aria-hidden="true"></i> Search
                </button>
              </div>
              <div className="col-md-12">
                <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label
                          htmlFor="rId"
                          className="form-control-label"
                        >
                          Role ID
                        </label>
                        <input
                          type="text"
                          name="rId"
                          id="rId"
                          className="form-control"
                          placeholder="Role Id"
                          value={rId}
                          onChange={({ currentTarget }) => setRId(currentTarget.value)}
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label
                          htmlFor="roleType"
                          className="form-control-label"
                        >
                          Role Type
                        </label>
                        <select
                          name="roleType"
                          id="roleType"
                          className="form-control"
                          value={rType}
                          onChange={({ currentTarget }) =>
                            setrType(currentTarget.value)
                          }
                        >
                          <option value="">Select Role Type</option>
                          {roleTypes.map((r) => (
                            <option value={r.name}>{r.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4 form-group">
                        <label
                          htmlFor="userName"
                          className="form-control-label"
                        >
                          Role Name
                        </label>
                        <input
                          type="text"
                          name="userName"
                          id="userName"
                          className="form-control"
                          placeholder="Role Name"
                          value={rName}
                          onChange={({ currentTarget }) => setrName(currentTarget.value)}
                        />
                      </div>
                      <div className="col-md-12 text-center ">
                        <button
                          className="btn-sm btn-outline-default mr-2"
                          onClick={() => handleFilter()}
                        >
                          <i className="fa fa-search mr-2" aria-hidden="true"></i>Search
                        </button>
                        <button
                          className="btn-sm btn-outline-default mr-2"
                          onClick={handleFilterCancel}
                        >
                          <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
                        </button>
                        <button
                          className="btn-sm btn-outline-danger mr-2"
                          data-toggle="collapse"
                          data-target="#collapseExample"
                          onClick={handleFilterCancel}
                        >
                          <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <Tabs
              activeKey={selectedKey}
              onSelect={handleTabSelect}
              id="uncontrolled-tab-example"
              className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
            >
              <Tab eventKey="home" title="Workflow Roles" 
              tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
                <Tab.Content className="border p-0 border-top-0">
                {/* <button className="btn btn-sm btn-link" onClick={handleResetSelection}>Reset Selection</button> */}
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Select
                            <span style={{cursor:"pointer"}} onClick={handleResetSelection}>
                              <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                              </span>
                          </th>
                          <th>ID</th>
                          <th>Role Type</th>
                          <th>Role Name</th>
                          <th>Date & Time</th>
                          <th>Maximum Users</th>
                          <th>Active Users</th>
                        </tr>
                      </thead>
                      <tbody>
                        {workflowRoles.map(item =>
                          <tr>
                            <td>
                              <div className="custom-control custom-radio">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id={item.roleid}
                                  name="role"
                                  onChange={()=>handleSelectRole(item)}
                                  value={selectedRoleId}
                                  checked={selectedRoleId === item.roleid}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={item.roleid}
                                >

                                </label>
                              </div>
                            </td>
                            <td>{item.roleid}</td>
                            <td>{item.roleType}</td>
                            <td>{item.rolename}</td>
                            <td>
                              <Moment format="MMM Do YYYY">{item.dateTimeChanged}</Moment></td>
                            <td>
                              {item.totalEntryRecords === 0 ? "Unrestricted" : item.totalEntryRecords}
                            </td>
                            <td>
                              {item.activeUsers}
                            </td>
                           
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      pageSize={pageSize}
                      itemsCount={rowsCount}
                      onPageChange={handlePageChange}
                      startIndex={startIndex}
                      endIndex={endIndex}
                    ></Pagination>
                   
                  </div>
                </Tab.Content>
              </Tab>
              <Tab eventKey="profile" title={`${selectedRoleId > 0 ? "Edit" : "Add"} Permissions`} tabClassName="page-tab border border-top-0 radius-0">
                <Tab.Content className="border p-3 border-top-0">
                  {selectedRoleId > 0 ? 
                  <AddEditPermission roleid={selectedRoleId} handlePermissionShowHide={handlePermissionShowHide} /> : 
                  <div className="col-md-12">
                    <h3 className="text-danger mt-1"> Alert : Please select the Workflow Role first</h3>
                  </div>
                  }
                </Tab.Content>
              </Tab>
              <Tab eventKey="users" title={<span onClick={handleManagePerformer}>Assign/Manage Users</span>}
               tabClassName="page-tab border-0">
                <Tab.Content className="mt-2">
                  {selectedRoleId > 0 ? 
                  <button className="btn btn-sm btn-outline-default" onClick={handleManagePerformer}>
                    Assign/Manage Users
                  </button> : <div className="col-md-12">
                    <h3 className="text-danger mt-1"> Alert : Please select the Workflow Role first</h3>
                  </div> }
                </Tab.Content>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
     
    </React.Fragment>
  );
}
