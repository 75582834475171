import React, { Component, useEffect, useState } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";
import { useDispatch, useSelector } from "react-redux";
import { addReviewNotes, getAllNotes, getNotesAddedStatus, loadAllReviewNotes, reviewNotesAdded } from "../../app/store/reviewNotes";
import { getCurrentUser } from "../../app/store/auth";
import { allowFormLibraryEdit, formLibraryEditAllowed, getFormLibraryEditStatus } from "../../app/store/forms";

const AddEditNotes = (props) => {
  const { noteShowHide, handleNoteShowHide, notesType,
    candidateObj, timeLogObj, selectedObj } = props;
  const [allNotes, setAllNotes] = useState([]);
  const [notes, setNotes] = useState("");
  const [btnAddedStatus, setBtnAddedStatus] = useState(false);

  const allNotesSelector = useSelector(getAllNotes);
  const notesAddedStatusSelector = useSelector(getNotesAddedStatus);
  const formLibraryEditStatusSelector = useSelector(getFormLibraryEditStatus);

  const dispatch = useDispatch();

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (candidateObj?.performerid) {
      dispatch(loadAllReviewNotes(notesType, candidateObj.performerid))
    }
  }, [candidateObj]);

  useEffect(() => {
    if (allNotesSelector.length > 0 && timeLogObj?.id > 0) {
      let filterNotes = allNotesSelector;

      if (timeLogObj?.id > 0)
        filterNotes = filterNotes.filter((x) => x.ciid === timeLogObj.id);

      setAllNotes(filterNotes);
      setNotes("");
      if (btnAddedStatus) {
        setBtnAddedStatus(false);
        handleNoteShowHide("save", "");
      }
    }
  }, [allNotesSelector]);

  useEffect(() => {
    if (allNotesSelector.length > 0 && timeLogObj?.id > 0) {
      let filterNotes = allNotesSelector;

      if (timeLogObj?.id > 0)
        filterNotes = filterNotes.filter((x) => x.ciid === timeLogObj.id);

      setAllNotes(filterNotes);
      setNotes("");

    }
  }, [timeLogObj])

  const doSubmit = async () => {
    if (notesType === "formEditReq") {
      const result = await getConfirmAlert("Warning", "Are you sure you want to give permission to Edit this Form?", "warning");
      if (result.isConfirmed) {
        dispatch(allowFormLibraryEdit(selectedObj.id, 1, notes));
      }
    } else {
      const frmData = new FormData();
      frmData.append("comment", notes);
      frmData.append("type", notesType);
      frmData.append("userId", currentUser.id);
      frmData.append("performerId", candidateObj.performerid);
      if (timeLogObj?.id > 0)
        frmData.append("ciid", timeLogObj.id);

      dispatch(addReviewNotes(frmData));
    }
  };

  useEffect(() => {
    if (notesAddedStatusSelector) {
      getAlert("", "Sent Successfully", "success");
      setBtnAddedStatus(true);
      if (candidateObj?.performerid) {
        dispatch(loadAllReviewNotes(notesType, candidateObj.performerid));
      }
      dispatch(reviewNotesAdded(false));
    }
  }, [notesAddedStatusSelector]);

  useEffect(() => {
    if (formLibraryEditStatusSelector) {
      getAlert("", "Allowed Successfully", "success");
      dispatch(formLibraryEditAllowed(false));
      handleNoteShowHide("save", "");
    }
  }, [formLibraryEditStatusSelector]);


  return (
    <Modal show={noteShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleNoteShowHide("", "")}>
        <Modal.Title>
          {notesType === "formEditReq" ? "Allow Edit Form" : "Notes"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          {notesType !== "formEditReq" && (
            <div className="col-md-12" style={{ maxHeight: "350px", overflow: "auto" }}>
              <div className="table-responsive">
                <table className="table table-striped align-items-center table-flush border bg-white">
                  <thead className="thead-light-green">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Notes</th>
                      <th>Added By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allNotes.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <Moment format="MMM Do YYYY, h:mm a">
                            {item.dateTime}
                          </Moment>
                        </td>
                        <td>{item.comments}</td>
                        <td>
                          {item.performer}
                          <br />
                          {item.email}
                          <br />
                          {item.role}
                          {item.department}
                          {item.partner}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="col-md-12">
            <label htmlFor="notes" className="form-control-label">Notes</label>
            <textarea cols={2} rows={2}
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes"
              value={notes}
              onChange={({ currentTarget }) => setNotes(currentTarget.value)}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleNoteShowHide("", "")}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button
          className="btn btn-sm btn-outline-default"
          disabled={!notes}
          onClick={doSubmit}
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i> {notesType !== "formEditReq" ? "Send" : "Allow"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditNotes;
