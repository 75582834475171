import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import PageHeader from "../PageHeader";
import CandidateList from "./CandidateList";
import CandidatePay from "./CandidatePay";
import http from "../../services/HttpService";
import config from "../../config.json";
import AddNewTimeLog from "./AddNewTimeLog";
import AddEditNotes from "./AddEditNotes";
import UploadInvoice from "./UploadInvoice";
import Swal from "sweetalert2";
import { getAllMonths, getAllYears, getFieldData, getFormatedNumber, getMonthLastDay, getMonthName, getTotalDays, getTotalHours } from "../../services/appService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAlert } from "../../services/appAlerts";
import { getTimeLogInvoice, loadTimeLogInvoice } from "../../app/store/timeLogInvoice";
import { getTimeLogs, loadTimeLogs } from "../../app/store/timeLog";
import InvoiceReview from "./InvoiceReview";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";

export default function CandidateInvoice(props) {
  const { mainItem, type, module } = props;
  console.log(props);
  //console.log("Review Workflow Task");
  const route = [
    { name: "Onboarding and Contracts", url: "" },
    {
      name: `${module === "Recruiter" ? "Recruiters" : "Subcontractors"}`,
      url: "/ListSubcontractor/application",
    },
    { name: "Work In Progress", url: "/CustomerApps" }
    // {
    //   name: `${module === "Recruiter" ? "Recruiter" : "Subcontractor"
    //     } Details`,
    //   url: "/ReviewTask",
    //   state: { item: mainItem, type, module },
    // },
  ];

  const [timeLogs, setTimeLogs] = useState([]);
  const [timeLogInvoices, setTimeLogInvoices] = useState([]);
  const [invoiceType, setInvoiceType] = useState("subcontractor");
  const [timeLogObj, setTimeLogObj] = useState("");
  const [candidateObj, setCandidateObj] = useState(null);
  const [rate, setRate] = useState(0);
  const [rateDate, setRateDate] = useState(null);
  const [perfData, setPerfData] = useState("");
  const [invRevShowHide, setInvRevShowHide] = useState(false);
  const [payShowHide, setPayShowHide] = useState(false);
  const [noteShowHide, setNoteShowHide] = useState(false);
  const [timeLogShowHide, setTimeLogShowHide] = useState(false);
  const [notesType, setNotesType] = useState("TimeLog");
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");

  const timeLogSelector = useSelector(getTimeLogs);
  const timeLogsInvoiceSelector = useSelector(getTimeLogInvoice);

  const years = getAllYears();
  const months = getAllMonths();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Candidate Invoice Detail"));
    //dispatch(setCurrentRoute(route));
  }, [])

  useEffect(() => {
    const state = location.state;
    //console.log(location);
    let revewRoute = {
      name: "Review Workflow Task", url: "/ReviewTask", state: {
        task: state?.task,
        item: state?.mainItem,
        app: state?.app,
        type: 1,
        parentApplication: state?.parentApplication,
        appType: state?.appType,
        module: state?.module,
      }
    }
    const newRoute = [...route, revewRoute]
    dispatch(setCurrentRoute(newRoute));

    if (state?.item?.performerid) {
      setCandidateObj(state.item);
      setPerfData(state.item.performerData);
      dispatch(loadTimeLogs(state.item.performerid))
      dispatch(loadTimeLogInvoice(state.item.performerid))
    }
    if (state?.module === "Recruiter") {
      setInvoiceType("recruiter");
      if (state?.item?.rates) {
        setRate(state.item.rates.recruiterRate);
        setRateDate(state.item.rates.recruiterEffectiveDate);
      }
    }
    else {
      if (state?.item?.rates) {
        setRate(state.item.rates.subcontractorRate);
        setRateDate(state.item.rates.subcontractorEffectiveDate);
      }
    }
  }, []);

  useEffect(() => {
    if (timeLogSelector.length > 0)
      setTimeLogs(timeLogSelector);

    if (timeLogsInvoiceSelector.length > 0) {
      const filterInvoice = timeLogsInvoiceSelector.filter(x => x.invoiceType === invoiceType);
      setTimeLogInvoices(filterInvoice);
    }

  }, [timeLogSelector, timeLogsInvoiceSelector]);

  useEffect(() => {

    let filterTimeLogs = [...timeLogSelector];
    if (filterYear > 0)
      filterTimeLogs = filterTimeLogs.filter(x => x.year === parseInt(filterYear));

    if (filterMonth > 0)
      filterTimeLogs = filterTimeLogs.filter(x => x.month === parseInt(filterMonth));

    setTimeLogs(filterTimeLogs);

  }, [filterMonth, filterYear])

  const getMonthlyPeriod = (obj) => {
    if (!obj?.workingHrs || obj?.workingHrs === "") return "";
    const wt = JSON.parse(obj.workingHrs);
    const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
    if (sortWt.length > 0) {
      return (
        <div>
          <Moment format="MMM Do YYYY">{sortWt[0].start}</Moment> -{" "}
          <Moment format="MMM Do YYYY">
            {sortWt[sortWt.length - 1].type === "m"
              ? getMonthLastDay(sortWt[sortWt.length - 1].start)
              : sortWt[sortWt.length - 1].start}
          </Moment>
        </div>
      );
    } else {
      return "";
    }
  };

  const getFinalAmout = (obj) => {
    let fAmt = parseFloat(getInvoiceAmount(obj));
    const invItems = timeLogInvoices.filter(
      (x) => x.timeLogId === obj.id
    );
    if (invItems.length > 0) {
      const item = invItems[0];
      if (item?.adjustmentMinues > 0)
        fAmt -= item.adjustmentMinues;
      if (item?.adjustmentPlus > 0)
        fAmt += item.adjustmentPlus;
      if (item?.adpDeduction > 0)
        fAmt -= item.adpDeduction;
      return parseFloat(fAmt).toFixed(2);
    } else {
      return parseFloat(fAmt).toFixed(2);
    }
  };

  const getInvoiceAmount = (item) => {
    let invAmt = getTotalHours(item?.workingHrs) * rate;
    if (entType !== "W2 Basis") return parseFloat(invAmt).toFixed(2);
    const taxRate = getFieldData(perfData, "candidateonboardingform", "Tax") / 100;
    invAmt += invAmt * taxRate;
    return parseFloat(invAmt).toFixed(2);
  };

  const getInvoiceDocs = (item) => {
    const tmLogs = timeLogInvoices
      .filter(
        (x) =>
          x.timeLogId === item.id && x.invDoc !== null
      );
    if (tmLogs.length === 0) return "";

    if (tmLogs.length > 0) {
      return <>
        {tmLogs.map((inv) => (
          <React.Fragment>
            {inv.invoiceNumber}
            <br />
            <button
              className="btn btn-sm btn-link pl-0"
              onClick={() => handleDownloadFile(inv.invDoc)}
            >
              {inv.invDoc.documentName}
            </button>
          </React.Fragment>
        ))}
      </>
    }

  }

  const getInvoiceStatus = (item) => {
    const tmLogs = timeLogInvoices
      .filter(
        (x) =>
          x.timeLogId === item.id &&
          x.status !== null
      )

    if (tmLogs.length === 0) return "";

    if (tmLogs.length > 0) {
      return <>
        {tmLogs.map((inv) => (
          <React.Fragment>
            {inv.status === true && (
              <button
                className="btn btn-sm btn-link text-left pl-0 w-100"
              >
                Approved
              </button>
            )}
            {inv.status === false && (
              <button
                className="btn btn-sm btn-link text-left pl-0 w-100"
              >
                Rejected
              </button>
            )}
            <br />
            <Moment format="MMM Do YYYY">
              {inv.reviewDateTime}
            </Moment>
          </React.Fragment>
        ))}
      </>
    }

  }

  const getPaymentStatus = (item) => {
    const tmLogs = timeLogInvoices.filter(
      (x) =>
        x.timeLogId === item.id &&
        x.paymentMode !== null
    );

    if (tmLogs.length === 0) {
      return <button
        className="btn btn-link btn-sm pl-0"
        onClick={() => handlePayShowHide(item)}
      >
        To Pay
      </button>
    }

    return <>
      {tmLogs.map((pay) => (
        <React.Fragment>
          <button
            className="btn btn-link btn-sm pl-0"
            onClick={() => handlePayShowHide(item)}
          >
            {pay.paymentMode !== null
              ? "Paid By IPCS"
              : "To Pay"}
          </button>
          <br />
          {pay?.docList?.map((doc) => (
            <button
              className="btn btn-sm btn-link p-0"
              onClick={() =>
                handleDownloadFile(
                  doc
                )
              }
            >
              {doc.documentName}
            </button>
          ))}

          {pay?.docList?.length > 0 && <br />}
          <Moment format="MMM Do YYYY, h:mm a">
            {pay.payDateTime}
          </Moment>
          <br />
          Paid : {parseFloat(pay.paidAmount).toFixed(2)}
        </React.Fragment>
      ))}
    </>
  }

  const getDueAmountStatus = (item) => {
    const tmLogs = timeLogInvoices
      .filter(
        (x) =>
          x.timeLogId === item.id &&
          x.paidAmount !== null
      );
    if (tmLogs.length === 0) return "";

    return <>
      {tmLogs.map((inv) => parseFloat(getFinalAmout(item) - inv.paidAmount).toFixed(2))}
    </>

  }

  const handleInvRevShowHide = (obj) => {
    if (obj === "save") {
      setInvRevShowHide(false);
      setTimeLogObj("");
      if (candidateObj?.performerid)
        dispatch(loadTimeLogInvoice(candidateObj?.performerid))
    }
    else {
      setInvRevShowHide(!invRevShowHide);
      setTimeLogObj(obj);
    }
  };

  const handleShowHide = async (obj) => {
    if (obj === "save") {
      setTimeLogShowHide(false);
      setTimeLogObj("");
      if (candidateObj?.performerid)
        dispatch(loadTimeLogs(candidateObj.performerid));
    } else {
      setTimeLogShowHide(!timeLogShowHide);
      setTimeLogObj(obj);
    }
  };

  const handlePayShowHide = async (obj) => {
    if (obj === "save") {
      setPayShowHide(false);
      setTimeLogObj("");
      if (candidateObj?.performerid)
        dispatch(loadTimeLogInvoice(candidateObj.performerid))
    } else {
      if (obj === "close") {
        setPayShowHide(false);
        setTimeLogObj("");
      } else {
        const chkInvoice = timeLogInvoices.filter(
          (x) =>
            x.timeLogId === obj.id && x.invDoc !== null
        );
        // if (chkInvoice.length > 0 || entType !== "C2C Basis") {
        if (chkInvoice.length > 0) {
          setPayShowHide(!payShowHide);
          setTimeLogObj(obj);
        } else {
          getAlert("", "Invoice not generated", "error");
        }
      }
    }
  };

  const handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.id, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.documentName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNoteShowHide = (obj, type) => {
    if (type === "save") {
      setTimeLogObj("")
      setNoteShowHide(false);
    } else {
      setTimeLogObj(obj);
      setNoteShowHide(!noteShowHide);
    }
  };

  let item = "";
  let entType = "";
  if (candidateObj) {
    item = candidateObj;
    getFieldData(
      item.performerData,
      "candidateonboardingform",
      "CandidateEntity"
    );
  }


  return (
    <React.Fragment>
      {/* <PageHeader
        route={route}
        parentTitle="Candidate Invoice Detail"
        headerTitle="Candidate Invoice Detail"
      ></PageHeader> */}
      <div className="col-md-12">
        <div className="text-right mb-2">
          <button
            className="btn btn-sm btn-outline-default btn-min-width"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left mr-2"></i>Back
          </button>
        </div>
        <div className="card mb-4 shadow-none">
          <div className="row">
            <div className="col-md-12 form-group">
              {item !== "" && (
                <div className="table-responsive">
                  <table className="table align-items-center table-flush table-hover table-striped mb-2 border">
                    <thead className="thead-light">
                      <tr>
                        <th>CANDIDATE #<br />IPCS ID</th>
                        <th>Candidate Details</th>
                        <th>Entity Type</th>
                        <th>Agency / Client</th>
                        <th>Duration</th>
                        <th>Onboarding Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {item.performerid}
                          <br />
                          {item.maxloans}
                        </td>
                        <td>
                          <span className="text-uppercase font-weight-bold">
                            {item.performername} {item.lastName}
                          </span>
                          <br />
                          {item.performeremail}
                          <br />
                          {item.contactNo && getFormatedNumber(item.contactNo)}
                          <br />
                          {getFieldData(
                            item.performerData,
                            "projectengagement",
                            "PositionTitle"
                          )}
                        </td>
                        <td>
                          {getFieldData(
                            item.performerData,
                            "candidateonboardingform",
                            "CandidateEntity"
                          )}
                        </td>
                        <td>
                          {getFieldData(
                            item.performerData,
                            "projectengagement",
                            "AgencyName"
                          )}
                        </td>
                        <td>
                          {getFieldData(
                            item.performerData,
                            "projectengagement",
                            "EngagementDuration"
                          )}{" "}
                          Months
                          <br />
                          <Moment format="MMM Do YYYY">
                            {getFieldData(
                              item.performerData,
                              "projectengagement",
                              "StartDate"
                            )}
                          </Moment>
                          <br />
                          <Moment format="MMM Do YYYY">
                            {getFieldData(
                              item.performerData,
                              "projectengagement",
                              "EndDate"
                            )}
                          </Moment>
                        </td>
                        <td>
                          {item.appSubmit && (
                            <button className="btn btn-sm btn-link p-0">
                              Done
                            </button>
                          )}
                          {!item.appSubmit && (
                            <button className="btn btn-sm btn-link p-0">
                              Pending
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="col-md-12">
              <div className="card card-body p-3 shadow-none border">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="year" className="form-control-label">
                      Year
                    </label>
                    <select
                      name="filterYear"
                      id="year"
                      className="form-control form-control-sm"
                      value={filterYear}
                      onChange={({ currentTarget }) => setFilterYear(currentTarget.value)}
                    >
                      <option value="">All</option>
                      {years.map((y) => (
                        <option value={y.name}>{y.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="month" className="form-control-label">
                      Month
                    </label>
                    <select
                      name="filterMonth"
                      id="month"
                      className="form-control form-control-sm"
                      value={filterMonth}
                      onChange={({ currentTarget }) => setFilterMonth(currentTarget.value)}
                    >
                      <option value="">All</option>
                      {months.map((m) => (
                        <option value={m.id}>{m.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table align-items-center table-flush table-striped mb-2 border">
                  <thead className="thead-light">
                    <tr>
                      <th>Year</th>
                      <th>Month</th>
                      <th>Period</th>
                      <th>Time Logged</th>
                      <th>Hrs Worked<br />Days Worked</th>
                      <th>Rate/Hr ($)</th>
                      <th>Invoice<br />Amount ($)</th>
                      <th>Invoice</th>
                      <th>IPCS REVIEW <br />ADJUSTMENT (+,-)</th>
                      <th>Final<br />Pay Amount</th>
                      <th>Payment</th>
                      <th>Due Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeLogs.map((item) => (
                      <tr>
                        <td>{item.year}</td>
                        <td>{getMonthName(item.month)}</td>
                        <td>{getMonthlyPeriod(item)}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-link pl-0"
                            onClick={() => handleShowHide(item)}
                          >
                            View
                          </button>
                          <br />
                          {item.docList.map((doc) => (
                            <li className="list-group-item p-0 border-0 bg-transparent">
                              <button
                                className="btn btn-sm btn-link pl-0"
                                onClick={() => handleDownloadFile(doc)}
                              >
                                {doc.documentName}
                              </button>
                            </li>
                          ))}
                        </td>

                        <td>
                          {getTotalHours(item.workingHrs)}
                          <br />
                          {getTotalDays(item.workingHrs) + " Days"}
                        </td>
                        <td>
                          {rate}
                          <br />
                          {rateDate && (
                            <Moment format="MMM Do YYYY">{rateDate}</Moment>
                          )}
                        </td>

                        <td>
                          {getInvoiceAmount(item)}
                        </td>

                        <td>
                          {getInvoiceDocs(item)}
                        </td>

                        <td>
                          {getInvoiceStatus(item)}
                        </td>
                        <td>
                          {getFinalAmout(item)}
                        </td>
                        <td>
                          {getPaymentStatus(item)}
                        </td>
                        <td>
                          {getDueAmountStatus(item)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CandidatePay
        payShowHide={payShowHide}
        handlePayShowHide={handlePayShowHide}
        candidateObj={candidateObj}
        selectedObj={timeLogObj}
        invType={invoiceType}
        timeLogInvoices={timeLogInvoices}
        getInvoiceAmount={getInvoiceAmount}
        entType={entType}
      ></CandidatePay>

      <InvoiceReview
        invRevShowHide={invRevShowHide}
        handleInvRevShowHide={handleInvRevShowHide}
        candidateObj={candidateObj}
        selectedObj={timeLogObj}
        invType={invoiceType}
        timeLogInvoices={timeLogInvoices}
        getInvoiceAmount={getInvoiceAmount}
        entType={entType}
      ></InvoiceReview>

      <AddNewTimeLog
        showHide={timeLogShowHide}
        handleShowHide={handleShowHide}
        selectedObj={timeLogObj}
        timeLogs={timeLogs}
        allTimeLogs={timeLogs}
        candidateObj={candidateObj}
      ></AddNewTimeLog>

      <AddEditNotes
        noteShowHide={noteShowHide}
        handleNoteShowHide={handleNoteShowHide}
        notesType={notesType}
        candidateObj={candidateObj}
        timeLogObj={timeLogObj}
      ></AddEditNotes>
    </React.Fragment>
  );
}
