import React, { Component } from "react";

class UploadDocument extends Component {
  convertToBytes = (x) => {
    const units = ["bytes", "KB", "MB"];
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  handleOnChange = (event) => {
    console.log(event.target.files);
  }

  render() {
    const {
      documents,
      onFileChange,
      handleFileRemove,
      btnLable,
      acceptedFile,
    } = this.props;
    return (
      <div>
        <div className="dropdown col-md-12 text-right pr-0">
          <button
            className="btn btn-outline-default btn-sm dropdown-toggle mr-0"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
            {btnLable ? btnLable : "Add"}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item">
              <label
                htmlFor="AdhocMessagedocName"
                className="custom-file-upload mb-0"
              >
                <i className="fa fa-desktop mr-2" aria-hidden="true" />
                Upload from Local Drive
              </label>
              <input
                id="AdhocMessagedocName"
                type="file"
                style={{ display: "none" }}
                onChange={(event) => onFileChange(event)}
                //multiple
                name="files"
              />
            </a>
            {/* <a className="dropdown-item" href="!#">
              <i class="fa fa-database mr-2" aria-hidden="true"></i>
              Upload From DDRMS
            </a>
            <a className="dropdown-item" href="!#">
              <i class="fa fa-dropbox mr-2" aria-hidden="true"></i>
              Upload From Dropbox
            </a>
            <a className="dropdown-item" href="!#">
              <i class="fa fa-google mr-2" aria-hidden="true"></i>
              Upload From Google Drive
            </a> */}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped align-items-center table-flush mt-2 bg-white border">
            <thead className="thead-light">
              <tr>
                <th>S.No.</th>
                <th>Document Name</th>
                <th>Size</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {documents && documents.map((item, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{item.name}</td>
                  <td>{this.convertToBytes(item.size)}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-link"
                      title="Remove"
                      onClick={() => handleFileRemove(item)}
                    >
                      <i class="fa fa-times text-red" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UploadDocument;
