import React, { Component, useEffect, useState } from "react";
import Form from "../../comman/form";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import Swal from "sweetalert2";
import Subcontractor from "./AddCandidate/Subcontractor";
import Candidate from "./AddCandidate/Candidate";
import Agency from "./AddCandidate/Agency";
import Msp from "./AddCandidate/Msp";
import Recruiter from "./AddCandidate/Recruiter";
import { useDispatch, useSelector } from "react-redux";
import { candidateBprmFormReceived, getCandidateBprmForm, getFilterCandidates, getSelectedCandidateObj, loadCandidateBprmForm, loadCandidatesByFilter, loadSelectedCandidatesByFilter } from "../../app/store/candidate";
import { getFieldData } from "../../services/appService";
import { getAlert } from "../../services/appAlerts";

const AddCandidate = (props) => {

  const { selectedObj, actType, setSelectedTabKey,
    handleAddEditCandidate, selectedTabKey, setSelectedCandidateId } = props;

  const candidateEntity = [
    { id: 1, name: "C2C Basis" },
    { id: 2, name: "W2 Basis" },
    { id: 3, name: "1099 Basis" },
  ];
  const w2Basis = [
    { id: 1, name: "W2 Hourly with Benefits" },
    { id: 2, name: "W2 Hourly with No-Benefits" },
  ];

  const [entityType, setEntityType] = useState(null);
  const [w2Type, setW2type] = useState(null);
  const [selectedKey, setSelectedKey] = useState("0");
  const [wfTask, setWfTask] = useState(null);
  const [wfId, setWfId] = useState(0);
  const [actionType, setActionType] = useState(actType);
  const [candidateObj, setCandidateObj] = useState(null);
  const [wfForm, setWfForm] = useState(null);

  const selectedCandidateObjSelector = useSelector(getSelectedCandidateObj);
  const candidateBprmFormSelector = useSelector(getCandidateBprmForm);

  const dispatch = useDispatch();
  useEffect(() => {
  
  }, [selectedTabKey])

  useEffect(() => {
    if (selectedObj !== "" && actType === "edit") {
      setCandidateObj(selectedObj);
      const entType = getFieldData(selectedObj.performerData, "candidateonboardingform", "CandidateEntity");
      const w9type = getFieldData(selectedObj.performerData, "candidateonboardingform", "w9_Type");
      setEntityType(entType);
      setW2type(w9type);
      dispatch(candidateBprmFormReceived(null));
      setCandidateType(entType);
      setSelectedKey("0");

    }
    else {
      setEntityType("");
      setW2type(null);
      setWfTask(null);
      setWfId(0);
      setCandidateObj(null);
      setSelectedKey("0");
      dispatch(candidateBprmFormReceived(null));
    }
  }, [selectedObj, selectedTabKey]);


  const handleSelectEntity = ({ currentTarget }) => {
    setEntityType(currentTarget.value);
    setCandidateType(currentTarget.value);
  }

  const setCandidateType = (entType) => {

    let appCiid = 0;
    if (selectedObj?.applications?.length > 0) {
      const allWfTasks = selectedObj?.applications[0].workflowTasks;
      if (allWfTasks?.length > 0) {
        appCiid = allWfTasks[allWfTasks.length - 1].ciid;
      }
    }

    if (entType === "C2C Basis") {
      setWfTask({ formId: config.formIds.candidate.C2C_Basis, ciid: appCiid });
      setWfId(config.workflowsIds.candidate.C2C_Basis);
      dispatch(loadCandidateBprmForm(config.formIds.candidate.C2C_Basis))
    }
    if (entType === "W2 Basis") {
      setWfTask({ formId: config.formIds.candidate.W2_Basis, ciid: appCiid });
      setWfId(config.workflowsIds.candidate.W2_Basis);
      dispatch(loadCandidateBprmForm(config.formIds.candidate.W2_Basis));
    }
    if (entType === "1099 Basis") {
      setWfTask({ formId: config.formIds.candidate.Ten_99_Basis, ciid: appCiid });
      setWfId(config.workflowsIds.candidate.Ten_99_Basis);
      dispatch(loadCandidateBprmForm(config.formIds.candidate.Ten_99_Basis))
    }
  }

  useEffect(() => {
    setWfForm(candidateBprmFormSelector);
  }, [candidateBprmFormSelector])

  const setKey = (key) => {
    if (!candidateObj) {
      getAlert("", "Candidate not added", "warning");
      return;
    }
    setSelectedKey(key);
  };

  const handleNextTab = (btnType) => {
    let nexTab = 1;
    if (entityType !== "C2C Basis" && selectedKey === "0") nexTab = 2;
    if (btnType !== "save" && btnType !== "exit") {
      setSelectedKey((parseInt(selectedKey) + nexTab).toString());
    }
    if (btnType === "exit") {
      setSelectedTabKey("home");
      setSelectedCandidateId(0);
    }
  };

  const updateSelectedObject = async (id) => {
    const frmBody = new FormData();
    frmBody.append("id", id);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    dispatch(loadSelectedCandidatesByFilter(frmBody));
  }

  useEffect(() => {
    if (selectedCandidateObjSelector.length > 0) {
      setCandidateObj(selectedCandidateObjSelector[0]);
      setActionType("edit");
      handleAddEditCandidate();
    }
  }, [selectedCandidateObjSelector])


  return (
    <>
      <div className="row">
        <div className="col-lg-12 form-group">
          <label htmlFor="CandidateEntity" className="form-control-label">
            Candidate Entity
          </label>
          <select
            name="CandidateEntity"
            id="CandidateEntity"
            className="form-control"
            onChange={handleSelectEntity}
            value={entityType}
          >
            <option value="">Select</option>
            {candidateEntity.map((item) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>
        {entityType === "W2 Basis" && (
          <div className="col-md-12 form-group">
            <label htmlFor="w2type" className="form-control-label">
              Select Benefits
            </label>
            <select
              name="w2type"
              id="w2type"
              className="form-control"
              onChange={({ currentTarget }) => setW2type(currentTarget.value)}
              value={w2Type}
            >
              <option value="">Select</option>
              {w2Basis.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
            </select>

          </div>
        )}
        {entityType &&
          <div className="col-md-12">
            <Tabs
              id="controlled-tab-example"
              activeKey={selectedKey}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              variant="pills"
            >
              <Tab eventKey="0" title="Candidate" tabClassName="mr-2">
                <Tab.Content className="border pt-3">
                  <Candidate
                    updateSelectedObject={updateSelectedObject}
                    candidateObj={candidateObj}
                    handleNextTab={handleNextTab}
                    entityType={entityType}
                    wfId={wfId}
                    wfTask={wfTask}
                    wfForm={wfForm}
                    actionType={actionType}
                  ></Candidate>
                </Tab.Content>
              </Tab>
              {entityType === "C2C Basis" && (
                <Tab eventKey="1" title="Subcontractor" tabClassName="mr-2">
                  <Tab.Content className="border pt-3">
                    <Subcontractor
                      candidateObj={candidateObj}
                      handleNextTab={handleNextTab}
                      updateSelectedObject={updateSelectedObject}
                      actType={actType}
                    ></Subcontractor>
                  </Tab.Content>
                </Tab>
              )}

              <Tab eventKey="2" title="Recruiter" tabClassName="mr-2">
                <Tab.Content className="border pt-3">
                  <Recruiter
                    candidateObj={candidateObj}
                    handleNextTab={handleNextTab}
                    updateSelectedObject={updateSelectedObject}
                    actType={actType}
                  ></Recruiter>
                </Tab.Content>

              </Tab>

              <Tab eventKey="3" title="Agency/Client" tabClassName="mr-2">
                <Tab.Content className="border pt-3">
                  <Agency
                    candidateObj={candidateObj}
                    handleNextTab={handleNextTab}
                    updateSelectedObject={updateSelectedObject}
                    actType={actType}
                  ></Agency>
                </Tab.Content>

              </Tab>
              <Tab
                eventKey="4"
                title="Managed Service Provider (MSP)"
                tabClassName="mr-2"
              >
                <Tab.Content className="border pt-3">
                  <Msp
                    candidateObj={candidateObj}
                    updateSelectedObject={updateSelectedObject}
                    actType={actType}
                    setSelectedTabKey={setSelectedTabKey}
                    setSelectedCandidateId={setSelectedCandidateId}
                  ></Msp>
                </Tab.Content>

              </Tab>
            </Tabs>
          </div>
        }
      </div>
    </>
  );
}

export default AddCandidate;
