import React, { useState } from "react";
import ReviewSubProcessPopup from "../../components/OMCRegistry/ReviewSubProcessPopup";
import ProccessApp from "./Subprocess/ProccessApp";

const JourneySteps = (props) => {

  const [subProccessShowHide, setSubProccessShowHide] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const { custApp, handleJorneySteps,
    handleToolObjShowHide, handleToolShowHide, candidateObj, compList } = props;

  const totalPhases = custApp.allWfPhase.filter(x => x.wfPhase !== ""
    //&& x.wfPhase !== "Onboarding" 
    && x.wfPhase !== "Application"
    && x.wfPhase !== "Rejected");

  const allTasks = custApp.workflowTasks.filter(x => x.itemType !== 3 &&
    x.itemType !== 2 &&
    x.itemType !== 4).sort((a, b) => a.orderNumber - b.orderNumber);

  const subprocessIndex = [];
  const subprocessLastIndex = [];

  totalPhases.map(item => {
    const wfTasks = allTasks.filter(
      (x) => x.wfPhase === item.wfPhase && x.itemType === 14
    );

    const newOrder = wfTasks.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );
    //console.log(newOrder);
    if (newOrder.length > 0) {
      const objItem = { wfPhase: item.wfPhase, itemId: newOrder[0].itemId };
      const objItem1 = {
        wfPhase: item.wfPhase,
        itemId: newOrder[newOrder.length - 1].itemId,
      };
      subprocessIndex.push(objItem);
      subprocessLastIndex.push(objItem1);
    }

  })

  const handleProcessShowHide = (tsk) => {
    setSubProccessShowHide(!subProccessShowHide);
    setSelectedTask(tsk);
  };
  var keyCount = 0;
  return (
    <div className="col-md-12">
      <ul id="progress">
        {custApp && totalPhases.map((item, key) => {
          let keyNum = 5;
          if (item.wfPhase === "Review" || item.wfPhase === "Onboarding")
            keyNum = 2;
          if (item.wfPhase === "Approved")
            keyNum = 3
          if (item.wfPhase === "Denied")
            keyNum = 4;
          if (keyNum === 5) {
            keyNum += keyCount;
            keyCount += 1;
          }
          return <li>
            <div className="row" style={{ minHeight: "60px", fontSize: "14px" }}>
              <div className={`design step${keyNum} d-flex align-items-center justify-content-center`}>
                <i
                  className="fa fa-cog progress-icon-size"
                  aria-hidden="true"
                ></i>
                {(totalPhases.length - 1) !== key &&
                  <i
                    className={`fa fa-chevron-down progress-icon progress-icon-step${keyNum}`}
                    aria-hidden="true"
                  ></i>}
              </div>
              <div className="p-1 w-10">
                <h5 className="text-left mb-1">{item.wfPhase}</h5>
                <ul style={{ listStyleType: "none" }}>
                  {item.wfPhase === "Review" && (
                    <li className="text-primary pb-2 text-left">
                      <span
                        className="text-blue"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleToolObjShowHide(1, item.wfPhase)}
                      >
                        Application Notes
                      </span>
                    </li>
                  )}
                  {allTasks.filter(x => x.wfPhase === item.wfPhase).map(task => {
                    return <>

                      {task.itemType === 14 &&
                        subprocessIndex.filter(
                          (s) =>
                            s.wfPhase === item.wfPhase &&
                            s.itemId === task.itemId
                        ).length > 0 && (
                          <li
                            style={{
                              listStyle: "none",
                              //marginLeft: "-60px",
                              padding: "5px 0px 5px 0px",
                            }}
                          >
                            <p className="mb-0">
                              <img
                                src="/assets/Images/WFAssociationIcons/subprocess.gif"
                                title="Subprocess"
                                style={{
                                  height: "18px",
                                  marginRight: "0px",
                                  marginTop: "-3px",
                                }}
                              />{" "}
                              Subprocess
                            </p>
                          </li>
                        )}

                      <li style={{
                        marginLeft: `${task.itemType === 14 && "16px"}`
                      }} className="pb-2 text-left">

                        {task.taskDoneStatus === 0 &&
                          task.itemType === 1 &&
                          task.itemName}

                        {task.taskDoneStatus === 4 &&
                          task.itemType === 1 && (
                            <span
                              className="text-blue"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleJorneySteps(task.wiid)
                              }
                            >
                              {task.itemName}{" "}
                              <i
                                className="fa fa-check-square text-green"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}

                        {task.taskDoneStatus === 2 &&
                          task.itemType === 1 && (
                            <span
                              className="text-blue"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleJorneySteps(task.wiid)
                              }
                            >
                              {task.itemName}
                            </span>
                          )}

                        {task.itemType !== 1 &&
                          task.itemType !== 14 && (
                            <span
                              className={`${task.taskDoneStatus > 0 && "text-blue"
                                }`}
                            >
                              {task.itemName}
                            </span>
                          )}

                        {task.itemType === 14 && (
                          <span
                            className={`${task.taskDoneStatus > 0 && "text-blue"
                              }`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              task.taskDoneStatus > 0 &&
                              handleProcessShowHide(task)
                            }
                          >
                            {task.itemName}{" "}
                            {task.taskDoneStatus === 4 && (
                              <i
                                className="fa fa-check-square text-green"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        )}
                      </li>
                    </>
                  })}
                </ul>
              </div>
            </div>
          </li>
        }

        )}

        {/* <li class="active">
          <div className="row">
            <div className="col-auto design step2 d-flex align-items-center justify-content-center">
              <i
                className="fa fa-cubes progress-icon-size"
                aria-hidden="true"
              ></i>

              <i
                className="fa fa-chevron-down progress-icon progress-icon-step2"
                aria-hidden="true"
              ></i>
            </div>
            <div className="col-auto p-1">
              <h4 className="text-left">Approved</h4>
              <ul>
                <li className="text-primary text-underline pb-2 text-left">
                  Contract Management
                </li>
                <li className="text-primary text-underline pb-2 text-left">
                  Send Reporting Details
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div className="row">
            <div className="col-auto design step3 d-flex align-items-center justify-content-center">
              <i
                className="fa fa-database progress-icon-size"
                aria-hidden="true"
              ></i>
            </div>
            <div className="col-auto p-1">
              <h4 className="text-left">Compliance</h4>
              <ul>
                <li className="text-primary text-underline pb-2 text-left">
                  Employee Handbook
                </li>
                <li className="text-primary text-underline pb-2 text-left">
                  Training
                </li>
                <li className="text-primary text-underline pb-2 text-left">
                  Policy Broadcasts
                </li>
                <li className="text-primary text-underline pb-2 text-left">
                  Policy Broadcasts
                </li>
              </ul>
            </div>
          </div>
        </li> */}
      </ul>
      {selectedTask && <ProccessApp
        processShowHide={subProccessShowHide}
        handleProcessShowHide={handleProcessShowHide}
        subProccessTask={selectedTask}
        candidateObj={candidateObj}
      />}
      {/* <ReviewSubProcessPopup
          processShowHide={processShowHide}
          handleProcessShowHide={handleProcessShowHide}
          compList={compList}
          handleToolShowHide={handleToolShowHide}
          selectedTask={selectedTask}
          custApp={custApp}
          candidateObj={candidateObj}
        ></ReviewSubProcessPopup> */}
    </div>
  );
};

export default JourneySteps;
