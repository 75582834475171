import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Moment from "react-moment";
import PageHeader from "../PageHeader";
import ViewRegistration from "./ViewRegistration";
import PaymentDetails from "../PartnerManage/PaymentDetails";
import TaskReAssignPopup from "../TaskReAssignPopup";
import WorkflowMapPopup from "../WorkflowMapPopup";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import config from "../../config.json";
import AppDocumnets from "../PartnerManage/AppDocumnets";
import PartnerWorkHub from "./PartnerWorkHub";
import AddEditDuration from "./AddEditDuration";
import { getCurrentUser } from "../../app/store/auth";
import {
  loadCandidateApps, getCandidateApps,
  loadCandidateParentApps, getCandidateParentApps,
  SendReminder, getReminderStatus
} from "../../app/store/candidate";
import Swal from "sweetalert2";
import ActiveDeactiveHistory1 from "../SystemAdministration/ManagePartner/ActiveDeactiveHistory";
import ReminderAlertMessage from "../ReminderAlertMessage";
import { getAlert } from "../../services/appAlerts";
import { getFieldData, getFormatedNumber } from "../../services/appService";
import CandidateInfo from "../../app/components/CandidateInfo";
import PartnerInfo from "../../app/components/PartnerInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
//import TaskProgress from "./TaskProgress";
//import MySteps from "./MySteps";

export default function CustomerApps() {
  const routeArray = [
    { name: "Onboarding and Contracts", url: "" },
    { name: "", url: "" },
  ];

  const [route, setRoute] = useState(routeArray);
  const [item, setItem] = useState("");
  const [module, setModule] = useState("");
  const [selectedApp, setSelectedApp] = useState(0);
  const [appType, setAppType] = useState("");
  const [type, setType] = useState("");
  const [actionType, setActionType] = useState("action");

  const [currentWfTask, setCurrentWfTask] = useState("");
  const [selectedObj, setSelectedObj] = useState("");
  const [regId, setRegId] = useState("");
  const [partnerObj, setPartnerObj] = useState("");
  const [editDurType, setEditDurType] = useState("");
  const [procType, setProcType] = useState(1);

  const [applications, setApplications] = useState([]);
  const [allApplication, setAllApplication] = useState([]);
  const [parentApplication, setParentApplication] = useState([]);

  const [workflowShowHide, setWorkflowShowHide] = useState(false);
  const [reAssignShowHide, setReAssignShowHide] = useState(false);
  const [paymentShowHide, setPaymentShowHide] = useState(false);
  const [regShowHide, setRegShowHide] = useState(false);
  const [mainAppShowHide, setMainAppShowHide] = useState(false);
  const [docShowHide, setDocShowHide] = useState(false);
  const [partHubShowHide, setPartHubShowHide] = useState(false);
  const [durationShowHide, setDurationShowHide] = useState(false);
  const [remainderMsgShowHide, setRemainderMsgShowHide] = useState(false);
  const [logShowHide, setLogShowHide] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = getCurrentUser();
  const candidateApps = useSelector(getCandidateApps);
  const candidateParentApps = useSelector(getCandidateParentApps);
  const remiderStatus = useSelector(getReminderStatus);

  useEffect(() => {
    const state = location?.state;
    if (state?.item)
      setItem(state.item);
    if (state?.module)
      setModule(state.module);
    if (state?.selectedApp)
      setSelectedApp(selectedApp);
    if (state?.appType)
      setAppType(state?.appType);
    if (state?.type)
      setType(state?.type);

    if (state?.item?.id)
      dispatch(loadCandidateApps(state.item.id, "admin", currentUser.id, state.selectedApp));

    if (state?.module === "Subcontractor") {
      route[1] = { name: "Subcontractors", url: `ListSubcontractor/${state?.type}` };
    }
    if (state?.module === "Recruiter") {
      route[1] = { name: "Recruiter", url: `Recruiter/${state?.type}` };
    }
    if (state?.module === "Candidate") {
      route[1] = { name: "Candidates", url: `ListCondidate/${state?.type}` };
    }

  }, []);

  useEffect(() => {
    dispatch(setCurrentRoute(route));
    dispatch(setCurrentPageTitle("Application"))
  }, [])

  useEffect(() => {
    const state = location?.state;
    //console.log(state);
    if (state?.item?.applications) {
      const subApp = state?.item?.applications.filter(
        (x) => x.applicationId === selectedApp
      );
      if (subApp.length > 0 && subApp[0].processType === 2) {
        dispatch(loadCandidateParentApps(state?.item?.id, "admin", currentUser.id, subApp[0].parentCiid));
        setProcType(2);
      }
    }
  }, [])

  useEffect(() => {
    setApplications(candidateApps);
    setMainAppShowHide(false)
  }, [candidateApps]);

  useEffect(() => {
    setParentApplication(candidateParentApps);
  }, [candidateParentApps]);

  useEffect(() => {
    if (remiderStatus === "success") {
      getAlert("", "Reminder Sent Successfully", "success");
      dispatch(loadCandidateApps(item?.id, "admin", currentUser.id, selectedApp));
      const subApp = item?.applications.filter(
        (x) => x.applicationId === selectedApp
      );
      if (subApp.length > 0 && subApp[0].processType === 2) {
        dispatch(loadCandidateParentApps(item?.id, "admin", currentUser.id, subApp[0].parentCiid));
      }
    }
  }, [remiderStatus]);

  const handleRemainder = async (id) => {
    dispatch(SendReminder(id));
  };

  const handleDownloadFile = async (item) => {
    const { data } = await http.get(config.DownloadDocument + item.id, {
      responseType: "blob",
    });
    if (data.size > 0) {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", item.documentName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  const handleWorkflowModalShowHide = (obj) => {
    setWorkflowShowHide(!workflowShowHide);
    setSelectedObj(obj);
  };

  // const handleReAssignModalShowHide = (obj) => {
  //   setReAssignShowHide(!reAssignShowHide);
  //   setSelectedObj(obj);
  // };

  // const handleReAssignSubmit = () => {
  //   setReAssignShowHide(false);
  //   dispatch(loadCandidateApps(item?.id, "admin", currentUser.id, selectedApp));
  //   const subApp = item?.applications.filter(
  //     (x) => x.applicationId === selectedApp
  //   );
  //   if (subApp.length > 0 && subApp[0].processType === 2) {
  //     dispatch(loadCandidateParentApps(item?.id,"admin",currentUser.id,subApp[0].parentCiid));
  //   }
  // };

  const handlViewRegistration = (item) => {
    setRegShowHide(!regShowHide);
    setSelectedObj(item);
  };

  const handlePaymentModalShowHide = (obj) => {
    if (obj?.ciid) {
      setPaymentShowHide(true);
      setSelectedObj(obj);
    } else {
      setPaymentShowHide(false);
      setSelectedObj("");
    }
  };

  const handleViewAppDetails = (app) => {
    const filterApps = [app, ...applications];
    setApplications(filterApps);
    setMainAppShowHide(true);
  };

  const handleCloseAppDetails = () => {
    // const { type } = this.props.location.state;
    // const { allApplication } = this.state;
    // this.loadApplication(allApplication, type);
  };

  const handleDocShowHide = (obj) => {
    setDocShowHide(!docShowHide);
    setSelectedObj(obj);
  };

  const getLastWorkflowTask = (workflowTasks) => {
    const allTasks = workflowTasks.filter(
      (x) =>
        x.itemType === 1 &&
        x.applicantId !== x.performerId &&
        x.taskDoneStatus > 0
    );
    const tsk = allTasks[allTasks.length - 1];
    return tsk;
  };

  const handleParterWorkingHub = async (id, type) => {
    const frmBody = new FormData();
    frmBody.append("id", id);
    frmBody.append("name", "");
    frmBody.append("email", "");
    if (type === "Subcontractor") frmBody.append("type", "Sub-Contractors");
    else frmBody.append("type", "Recruiter");
    const { data: partners } = await http.post(
      config.GetPartnersByFilter,
      frmBody
    );
    //console.log(partners);
    if (partners.length > 0) {
      this.props.history.push({
        pathname: "/SubcontractorDetails",
        state: {
          item: partners[0],
          type: "application",
          module: type,
        },
      });
    }
  };

  const handleDurationShowHide = (obj, type, key) => {
    setDurationShowHide(!durationShowHide);
    setSelectedObj(obj);
    setEditDurType(type);
  };

  const handleRateChanges = (newObj) => {
    item.rates = newObj;
    setSelectedObj("");
    setEditDurType("");
    setDurationShowHide(false);

  };

  const handleDurationChanges = (newObj) => {
    item.duration = newObj;
    setDurationShowHide(false);
    setEditDurType("");
    setSelectedObj("");
  };

  const handleRemainderMessage = () => {
    setRemainderMsgShowHide(!remainderMsgShowHide);
  };

  const handleLogShowHide = (item, actionType) => {
    if (item === "save") {
      setLogShowHide(false);
      setSelectedObj("");
      setActionType(actionType);

    } else {
      setLogShowHide(!logShowHide);
      setSelectedObj(item);
      setActionType(actionType);
    }
  };

  const handleReviewTask = (app) => {
    navigate("/ReviewTask", {
      state: {
        task: getLastWorkflowTask(
          app.workflowTasks
        ),
        item,
        app,
        type: procType,
        parentApplication,
        appType,
        module,
      }
    })
  }

  const handleViewAppSummary = (app) => {
    navigate("/ApplicationSummary",
      {
        state: {
          task: app,
          viewType: "view",
          appType: appType,
          applicant: item,
          processType: type
        }
      })
  }
  return (
    <>
      <div className="col-md-12">
        <div class="d-flex flex-row-reverse mb-2">
          <button
            className="btn btn-sm btn-outline-default btn-min-width"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left mr-2"></i>Back
          </button>
        </div>

        <div className="row">
          {appType === "partner" && item !== "" && (
            <div className="col-md-12">
              <PartnerInfo partner={item} type={type} appType={appType} module={module} />
            </div>
          )}
          {appType !== "partner" && item !== "" && (
            <div className="col-md-12">
              <CandidateInfo candidate={item} module={module} />
            </div>
          )}
          <div className="col-md-12">
            <div className="table-responsive border border-top-0">
              <table className="table table-striped align-items-center table-flush bg-white">
                {/* <thead className="thead-light-green">
                  <tr>
                    <th> {procType === 2 ? "Subprocess " : "App "}ID</th>
                    <th>Date & Time</th>
                    <th>{procType === 2 ? "Subprocess" : "Application"}</th>

                    <th>
                      {procType === 2 ? "Subprocess" : "Application"} Status
                    </th>
                  </tr>
                </thead> */}
                <tbody>
                  {applications.map((app) => (
                    <>
                      <tr>
                        <td>
                          App ID : {app.ciid}
                          {/* <br />
                          <button
                            className="btn btn-sm btn-outline-primary mt-2"
                            onClick={() =>
                              handleWorkflowModalShowHide(app)
                            }
                          >
                            <span>
                              <img
                                src="/assets/Images/WFAssociationIcons/process.gif"
                                style={{
                                  height: "18px",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              WF ID: {app.workflowId}
                            </span>
                          </button> */}
                        </td>
                        <td>
                          <button
                            onClick={() => handleViewAppSummary(app)}
                            className="btn btn-link btn-sm text-left p-0"
                          >
                            Onboarding Form
                          </button>
                          <br />
                          <Moment format="MMM Do YYYY">
                            {app.addedDateTime}
                          </Moment>
                          <br />
                          <Moment format="h:mm a">{app.addedDateTime}</Moment>
                        </td>

                        <td>
                          {app.workflowTasks.filter(
                            (x) =>
                              x.itemType === 1 &&
                              x.formType === "WF" &&
                              x.taskDoneStatus > 0
                          ).length === 0 ? (
                            <>
                              <button className="btn btn-warning btn-sm">
                                Application Incomplete
                              </button>
                              <br />
                              <button
                                className="btn btn-link btn-sm p-0 m-0 text-underline"
                                onClick={() =>
                                  handleRemainder(item.regId)
                                }
                              >
                                Send Reminder
                              </button>
                              <button
                                className="btn btn-link btn-sm p-0 mt-1 ml-1"
                                title="View message"
                                onClick={handleRemainderMessage}
                              >
                                <span
                                  className="badge badge-warning rounded-0 text-primary"
                                  style={{ width: "55px", fontSize: "12px" }}
                                >
                                  <i
                                    class="fa fa-envelope mr-1"
                                    aria-hidden="true"
                                  ></i>
                                  {!item?.remainderCount ? 0 : item.remainderCount}
                                </span>
                              </button>
                              <button
                                onClick={() => handleReviewTask(app)}
                                className="btn btn-sm btn-secondary bg-yellow border-yellow btn-min-width"
                              >
                                <i
                                  className="fa fa-arrow-right mr-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                Review {app.status && " Completed"}
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleReviewTask(app)}
                              className="btn btn-sm btn-secondary bg-yellow border-yellow btn-min-width"
                            >
                              <i
                                className="fa fa-arrow-right mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Review {app.status && " Completed"}
                            </button>
                          )}
                        </td>
                        <td>
                          {app.status === 1 && (
                            <button
                              className="badge badge-success rounded-0"
                              style={{ minWidth: "55px" }}
                            >
                              Approved
                            </button>
                          )}
                          {app.status === 0 && (
                            <button
                              className="badge badge-danger rounded-0"
                              style={{ minWidth: "55px" }}
                            >
                              Denied
                            </button>
                          )}
                        </td>
                        <td width="50%"></td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ViewRegistration
        regShowHide={regShowHide}
        handleRegShowHide={handlViewRegistration}
        regId={regId}
        selectedObj={selectedObj}
      ></ViewRegistration>

      <PaymentDetails
        paymentShowHide={paymentShowHide}
        handlePaymentModalShowHide={handlePaymentModalShowHide}
        selectedObj={selectedObj}
      ></PaymentDetails>

      <WorkflowMapPopup
        workflowShowHide={workflowShowHide}
        handleWorkflowModalShowHide={handleWorkflowModalShowHide}
        selectedObj={selectedObj}
      ></WorkflowMapPopup>

      {/* <TaskReAssignPopup
          reAssignShowHide={reAssignShowHide}
          handleReAssignModalShowHide={handleReAssignModalShowHide}
          selectedObj={selectedObj}
          handleReAssignSubmit={handleReAssignSubmit}
        ></TaskReAssignPopup> */}

      <AppDocumnets
        docShowHide={docShowHide}
        handleDocShowHide={handleDocShowHide}
        selectedObj={selectedObj}
      ></AppDocumnets>

      {remainderMsgShowHide &&
        <ReminderAlertMessage
          remainderMsgShowHide={remainderMsgShowHide}
          handleRemainderMessage={handleRemainderMessage}
        ></ReminderAlertMessage>}

      {/* {logShowHide &&
        <ActiveDeactiveHistory
          logShowHide={logShowHide}
          handleLogShowHide={handleLogShowHide}
          selectedObj={selectedObj}
          type={appType}
          pageType={module}
          actionType={actionType}
        ></ActiveDeactiveHistory>} */}

      {/* {appType !== "partner" && (
        <AddEditDuration
          durationShowHide={durationShowHide}
          handleDurationShowHide={handleDurationShowHide}
          selectedObj={item}
          editType={editDurType}
          getFieldData={getFieldData}
          handleRateChanges={handleRateChanges}
          handleDurationChanges={handleDurationChanges}
        ></AddEditDuration>
      )} */}
    </>
  );
}

