import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Swal from "sweetalert2";
import { getTotalHours } from "../../services/appService";
import { getAlert } from "../../services/appAlerts";

class InvoiceReview extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
      candidateEntity: [
        { id: 1, name: "C2C Basis" },
        { id: 2, name: "W2 Basis" },
        { id: 3, name: "1099 Basis" },
      ],
      w2Basis: [
        { id: 1, name: "W2 with Benefits" },
        { id: 2, name: "W2 with No-Benefits" },
      ],
      stateList: [],
      engageDuration: [],
      engageMode: [],
    };
  }

  schema = {
    name: Joi.string(),
    description: Joi.string(),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const { data: result } = await http.post(config.UpdateInvoiceReview, data);
    this.props.handleInvRevShowHide("save");
    getAlert("Saved Successfully", "", "success");
  };

  componentWillReceiveProps = (props) => {
    const {
      selectedObj: obj,
      invType,
      timeLogInvoices,
      candidateObj,
      getInvoiceAmount,
      entType
    } = props;
    if (obj !== undefined && obj !== "") {
      const { data } = this.state;
      let candidateRate = 0;
      // console.log(candidateObj, "obj")
      if (invType === "subcontractor") {
        if (entType === "C2C Basis" && candidateObj?.rates?.subcontractorRate) {
          candidateRate = candidateObj.rates.subcontractorRate;
        }
        else {
          if (candidateObj?.rates?.candidateRate)
            candidateRate = candidateObj.rates.candidateRate;
        }
      }

      if (invType === "recruiter" && candidateObj.rates?.recruiterRate)
        candidateRate = candidateObj.rates.recruiterRate;
      if (invType === "agency" && candidateObj.rates?.agencyRate)
        candidateRate = candidateObj.rates.agencyRate;

      let fAmt = 0;
      if (invType === "subcontractor") {
        fAmt = parseFloat(getInvoiceAmount(obj));
      } else {
        fAmt = parseFloat(getTotalHours(obj?.workingHrs) * candidateRate);
      }
      const invItems = timeLogInvoices.filter((x) => x.timeLogId === obj.id);
      // console.log(invItems, "invItems")
      // console.log(fAmt, "fAmt")
      if (invItems.length > 0) {
        const selectedObj = invItems[0];
        if (selectedObj?.adjustmentMinues > 0)
          fAmt -= selectedObj.adjustmentMinues;
        if (selectedObj?.adjustmentPlus > 0)
          fAmt += selectedObj.adjustmentPlus;

        if (selectedObj?.adpDeduction > 0)
          fAmt += selectedObj.adpDeduction;

        data["status"] = selectedObj.status;
        data["reason"] = selectedObj.reason;
        data["adjustmentPlus"] = selectedObj.adjustmentPlus;
        data["adjustmentMinues"] = selectedObj.adjustmentMinues;
      }
      data["id"] = obj.id;
      data["finalAmt"] = parseFloat(fAmt).toFixed(2);
      data["docUploadType"] = invType;
      data["rate"] = candidateRate;
      this.setState({ data });
    } else {
      this.setState({ documents: [], data: {} });
    }
  };

  render() {
    const { data } = this.state;
    const { invRevShowHide, handleInvRevShowHide } = this.props;
    return (
      <Modal show={invRevShowHide} size="xl">
        <Modal.Header closeButton onClick={() => handleInvRevShowHide("", "")}>
          <Modal.Title>Time Log Review</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12 form-inline">
              <label htmlFor="" className="form-control-label mr-3">
                Review :
              </label>
              <div className="custom-control custom-radio">
                <input
                  name="status"
                  className="custom-control-input"
                  id="approved"
                  type="radio"
                  value="true"
                  onChange={this.handleChange}
                  defaultChecked={data["status"] === true}
                />
                <label className="custom-control-label mr-3" htmlFor="approved">
                  Approve
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  name="status"
                  className="custom-control-input"
                  id="notApproved"
                  type="radio"
                  value="false"
                  onChange={this.handleChange}
                  defaultChecked={data["status"] === false}
                />
                <label className="custom-control-label" htmlFor="notApproved">
                  Reject
                </label>
              </div>
            </div>
            <div className="col-md-12">
              {this.renderTextArea("reason", "Reason")}
            </div>
            <div className="col-md-5">
              {this.renderInput("adjustmentPlus", "Adjustment Plus ($)")}
            </div>
            <div className="col-md-2 text-center pt-4 mt-3">
              <label htmlFor="" className="form-control-label">
                OR
              </label>
            </div>
            <div className="col-md-5">
              {this.renderInput("adjustmentMinues", "Adjustment Minus ($)")}
            </div>
            <div className="col-md-12">
              {this.renderInput("finalAmt", "Final Amount to Pay($)")}

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleInvRevShowHide("", "")}>
            <i class="fa fa-times" aria-hidden="true"></i> Close
          </button>
          <button className="btn btn-sm btn-outline-default" onClick={this.handleSubmit}>
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InvoiceReview;
