import React, { Component } from "react";
import Joi, { schema } from "joi-browser";
import Input from "../comman/input";
import Select from "../comman/select";
import TextArea from "./textarea";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
//import PhoneInput from "react-phone-input-2";
//import "react-phone-input-2/lib/style.css";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    documents: [],
  };

  validate = () => {
    const options = { abortEarly: false, allowUnknown: true };
    const result = Joi.validate(this.state.data, this.schema, options);
    //console.log(result);
    if (!result.error) return null;
    const errors = {};
    for (let item of result.error.details) {
      //console.log();
      if (item.type === "any.required") errors[item.path[0]] = item.message;
    }
    if (Object.keys(errors).length > 0) return errors;
    return null;
    // for (let item of result.error.details) errors[item.path[0]] = item.message;
    //return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    if (this.schema[name] === undefined) return null;
    const schema = { [name]: this.schema[name] };
    //console.log(schema);
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  // handleChange = ({ currentTarget: input }) => {
  //   const errors = { ...this.state.errors };
  //   //console.log(input.value);
  //   const errorMessage = this.validateProperty(input);
  //   //console.log(errorMessage);
  //   if (errorMessage) errors[input.name] = errorMessage;
  //   else delete errors[input.name];
  //   const data = { ...this.state.data };
  //   data[input.name] = input.value;
  //   this.setState({ data, errors });
  // };
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    // Existing validation logic
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    // Additional validation for "amountToPay"
    if (input.name === "paidAmount") {
      const invoiceAmount = parseFloat(data.invoiceAmount) || 0;
      const enteredAmount = parseFloat(input.value) || 0;

      if (enteredAmount > invoiceAmount) {
        errors[input.name] = "Amount to Pay cannot be greater than Invoice Amount!";
      } else {
        delete errors[input.name];
      }
    }

    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  handleSavePhoneNumber = (phone, name) => {
    const { data } = this.state;
    data[name] = phone;
    this.setState({ data });
  };

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    if (type === "password") {
      return (
        <PasswordInput
          name={name}
          value={data[name] || ""}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          req={this.schema[name]}
        />
      );
    } else if (type === "phone") {
      return (
        <PhoneInput
          name={name}
          value={data[name] || ""}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          req={this.schema[name]}
        />
      );
    } else {
      return (
        <Input
          type={type}
          name={name}
          value={data[name] || ""}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          onFocus={this.handleFocus}
          req={this.schema[name]}
        ></Input>
      );
    }
  }

  renderEnterInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name] || ""}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        onFocus={this.handleFocus}
        req={this.schema[name]}
        onKeyDown={this.handleEnterKeyPress}
      ></Input>
    );
  }

  renderTextArea(name, label) {
    const { data, errors } = this.state;
    return (
      <TextArea
        name={name}
        value={data[name] || ""}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        req={this.schema[name]}
      ></TextArea>
    );
  }

  renderSelect(name, label, options, optName, optValue) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name] || ""}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        optName={optName}
        optValue={optValue}
        req={this.schema[name]}
      />
    );
  }

  onFileChange = async (event) => {
    //console.log(event.target.files);
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    event.target.value = "";
    this.validateDocuments(docs);
    this.setState({ documents: docs });
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.validateDocuments(documents);
      this.setState({ documents });
    }
  };
}

export default Form;
