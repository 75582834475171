
import countries from "./allCountries.json";
import countriesStates from "./allCountryStates.json";

const countryId = 6252001;
const ALLCOUNTRY = "allCountry";
const ALLUSSTATE = "allUSState";

export function getGeoData(geonameId) {
  const data = countriesStates.filter(x => x.countryGeonameId == geonameId);
  return data;
}

export function getStateList() {
  return getGeoData(countryId);
}

export function getAllCountries() {
  const countryJs = countries;//JSON.parse(countries);
  return countryJs;
}

export default {
  getGeoData,
  getStateList,
  getAllCountries,
};
