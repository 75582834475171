import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "../comman/form";
import Joi from "joi-browser";

class DecryptAppData extends Form {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      data: {},
    };
  }

  schema = {
    securityKey: Joi.string().required().label("Security Key"),
  };

  doSubmit = () => {
    const { data } = this.state;
    this.props.verifySecurityCode(data["securityKey"]);
  };

  render() {
    const { decryptShowHide, handleDecryptShowHide } = this.props;
    return (
      <>
        <Modal show={decryptShowHide} onHide={handleDecryptShowHide}>
          <Modal.Header closeButton>
            <Modal.Title>Decrypt</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-secondary">
            <div className="row">
              <div className="col-md-12">
                {this.renderInput(
                  "securityKey",
                  "Enter Security Key",
                  "password"
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="outline-danger" onClick={handleDecryptShowHide}>
              <i className="fa fa-times mr-2" aria-hidden="true"></i>Close
            </Button>
            <Button
              size="sm"
              variant="outline-default"
              disabled={this.validate()}
              onClick={this.handleSubmit}
            >
              <i className="fa fa-times mr-2" aria-hidden="true"></i>Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default DecryptAppData;
