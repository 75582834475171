import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import geo from "../../services/geoService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Swal from "sweetalert2";

class AddEditClient extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
      candidateEntity: [
        { id: 1, name: "C2C Basis" },
        { id: 2, name: "W2 Basis" },
        { id: 3, name: "1099 Basis" },
      ],
      w2Basis: [
        { id: 1, name: "W2 with Benefits" },
        { id: 2, name: "W2 with No-Benefits" },
      ],
      stateList: [],
      engageDuration: [],
      engageMode: [],
      months: [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sept" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
      ],
    };
  }

  schema = {};

  async componentDidMount() {
    const stateList = await geo.getStateList();
    this.setState({ stateList });
  }

  doSubmit = async () => {
    const {
      data,
      aWorkfinityApp,
      aWorkfinityAppEntity,
      entityName,
      performerId,
    } = this.state;

    const projectengagement = {};
    Object.keys(data).forEach(function (keyValue) {
      projectengagement["@" + keyValue] = data[keyValue];
    });
    const usrDdtype1 = "projectengagement";
    aWorkfinityAppEntity[usrDdtype1] = projectengagement;
    aWorkfinityApp[entityName] = aWorkfinityAppEntity;
    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("id", performerId);
    const { data: result } = await http.post(
      config.UpdatePerformerData,
      formData
    );
    this.props.handleClientShowHide("", result);
    this.getAlert("", "Updated Successfully", "success");
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  componentWillReceiveProps = (props) => {
    const { candidateObj: selectedObj } = props;
    if (selectedObj !== undefined && selectedObj !== "") {
      //console.log(selectedObj);
      const { data } = this.state;
      const aWorkfinityApp = JSON.parse(selectedObj.performerData);
      const appShortName = Object.keys(aWorkfinityApp)[1];
      const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
      Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
        if (
          keyValue !== "@xmlns:xsd" &&
          keyValue !== "@xmlns:xsi" &&
          keyValue !== "EntityId"
        ) {
          Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
            keyValue1
          ) {
            if (keyValue1 !== "EntityId") {
              var item = aWorkfinityAppEntity[keyValue][keyValue1];
              if (!Array.isArray(item)) data[keyValue1.replace("@", "")] = item;
            }
          });
        }
      });

      this.setState({
        data,
        aWorkfinityApp,
        aWorkfinityAppEntity,
        entityName: appShortName,
        performerId: selectedObj.performerid,
      });
    }
  };

  render() {
    const { stateList, data, months } = this.state;
    const { clientShowHide, handleClientShowHide, editType } = this.props;
    //console.log(selectedObj);
    if (
      data["StartDate"] !== "" &&
      data["StartDate"] !== undefined &&
      data["EngagementDuration"] !== "" &&
      data["EngagementDuration"] !== undefined
    ) {
      const dt = new Date(data["StartDate"]);
      const num = parseInt(data["EngagementDuration"]);
      const numDay = parseInt(data["EngagementDay"]);
      if (num < 999) {
        dt.setDate(dt.getDate() + 1);
        dt.setMonth(dt.getMonth() + num);
        dt.setDate(dt.getDate() - 1);
        if (numDay < 30 && numDay > 0) dt.setDate(dt.getDate() + numDay);
        //console.log(dt);
        // const monthName = months.filter((x) => x.id === dt.getMonth() + 1)[0]
        //   .name;
        const dy = dt.getFullYear();
        let dm = dt.getMonth() + 1;
        let dd = dt.getDate();
        if (dm < 10) dm = "0" + dm;
        if (dd < 10) dd = "0" + dd;
        data["EndDate"] = dy + "-" + dm + "-" + dd;
        //  dt.getFullYear() + "-" + dt.getMonth() + "-" + dt.getDate();
        // data["EndDate"] = moment(dt).format("yyyy/mm/dd");
      }
    }
    return (
      <Modal show={clientShowHide} size="lg">
        <Modal.Header closeButton onClick={() => handleClientShowHide("", "")}>
          <Modal.Title>
            {editType === "project" && "Edit Project Engagement Details"}
            {editType === "client" && "Edit Agency / Client Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            {editType === "project" && (
              <React.Fragment>
                <div className="col-md-6">
                  {this.renderInput("PositionTitle", "Position Title")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("PositionID", "Position ID")}
                </div>
                <div className="col-md-12">
                  {this.renderInput("PositionLocation", "Position Location")}
                </div>
                {/* <div className="col-md-3">
                  {this.renderInput("StartDate", "Start Date", "date")}
                </div>
                <div className="col-md-3">
                  {this.renderInput("EngagementDuration", "Month")}
                </div>
                <div className="col-md-2">
                  {this.renderInput("EngagementDay", "Days")}
                </div>
                <div className="col-md-1 d-flex align-items-center justify-content-center">
                  <label htmlFor="" className="form-control-label">
                    OR
                  </label>
                </div>
                <div className="col-md-3">
                  {this.renderInput("EndDate", "End Date", "date")}
                </div> */}

                <div className="col-md-12">
                  {this.renderInput(
                    "RatePayableIpcs",
                    "Rate/Hour-Received By IPCS $"
                  )}
                </div>
                <div className="col-md-12">
                  {this.renderInput(
                    "ProjectDescription",
                    "Project Description"
                  )}
                </div>
              </React.Fragment>
            )}
            {editType === "client" && (
              <React.Fragment>
                <div className="col-md-6">
                  {this.renderInput("AgencyName", "Agency/Client")}
                </div>
                {/* <div className="col-md-6">
                  {this.renderInput("Address1", "Address Line 1")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("Address2", "Address Line 2")}
                </div> */}
                {/* <div className="col-md-4">
                  {this.renderInput("City", "City")}
                </div> */}
                <div className="col-md-6">
                  {this.renderSelect(
                    "State",
                    "State",
                    stateList,
                    "name",
                    "name"
                  )}
                </div>
                {/* <div className="col-md-4">
                  {this.renderInput("Zip", "Zip Code")}
                </div> */}

                <div className="col-md-12">
                  <label
                    htmlFor=""
                    className="form-control-label bg-admin-light border-admin-left p-2 w-100"
                  >
                    Agency Contact
                  </label>
                </div>

                <div className="col-md-6">
                  {this.renderInput("AgencyContact", "Name")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("AgencyTitle", "Title")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("AgencyEmail", "Email")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("AgencyPhone", "Phone", "phone")}
                </div>
                {/* <div className="col-md-12">
                  <label
                    htmlFor=""
                    className="form-control-label bg-admin-light border-admin-left p-2 w-100"
                  >
                    Contact Details 2
                  </label>
                </div> */}
                {/* <div className="col-md-6">
                  {this.renderInput("Contactname2", "Contact Name")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("ContactTitle2", "Contact Title")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("ContactEmail2", "Email")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("ContactPhone2", "Phone", "phone")}
                </div> */}

                {/* <div className="col-md-12">
                  <label
                    htmlFor=""
                    className="form-control-label bg-admin-light border-admin-left p-2 w-100"
                  >
                    Contact Details 3
                  </label>
                </div>
                <div className="col-md-6">
                  {this.renderInput("Contactname3", "Contact Name")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("ContactTitle3", "Contact Title")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("ContactEmail3", "Email")}
                </div>
                <div className="col-md-6">
                  {this.renderInput("ContactPhone3", "Phone", "phone")}
                </div> */}
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleClientShowHide("", "")}>
          <i class="fa fa-check" aria-hidden="true"></i> Close
          </button>
          <button
            className="btn btn-sm btn-outline-primary"
            disabled={this.validate()}
            onClick={this.handleSubmit}
          >
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddEditClient;
