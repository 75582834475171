import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    getAllMonths, getAllYears, getFieldData, getMonthLastDay, getMonthName,
    getTotalDays, getTotalHours
} from "../../../services/appService";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getTimeLogs, loadTimeLogs } from "../../store/timeLog";
import { getTimeLogInvoice, loadTimeLogInvoice } from "../../store/timeLogInvoice";
import { getAlert } from "../../../services/appAlerts";
import InvoiceReview from "../../../components/OMCRegistry/InvoiceReview";
import InvoiceApd from "../../../components/OMCRegistry/InvoiceApd";
import CandidatePay from "../../../components/OMCRegistry/CandidatePay";
import AddEditNotes from "../../../components/OMCRegistry/AddEditNotes";
import AddNewTimeLog from "../../../components/OMCRegistry/AddNewTimeLog";
import useDocDownload from "../../hooks/useDocDownload";

export default function Subcontractor(props) {
    const { candidate, ptype, entType, wfTask } = props;

    const [timeLogs, setTimeLogs] = useState([]);
    const [timeLogInvoices, setTimeLogInvoices] = useState([]);
    const [invType, setInvType] = useState("subcontractor");
    const [invRevShowHide, setInvRevShowHide] = useState(false);
    const [invAdpShowHide, setInvAdpShowHide] = useState(false);
    const [payShowHide, setPayShowHide] = useState(false);
    const [noteShowHide, setNoteShowHide] = useState(false);
    const [timeLogShowHide, setTimeLogShowHide] = useState(false);
    const [timeLogObj, setTimeLogObj] = useState("");
    const [filterYear, setFilterYear] = useState("");
    const [filterMonth, setFilterMonth] = useState("");
    const [notesType, setNotesType] = useState("TimeLog");

    const timeLogsSelector = useSelector(getTimeLogs);
    const timeLogsInvoiceSelector = useSelector(getTimeLogInvoice);

    const [handleDownloadFile] = useDocDownload();

    const dispatch = useDispatch();
    useEffect(() => {
        if (candidate?.id) {
            dispatch(loadTimeLogs(candidate.id));
            dispatch(loadTimeLogInvoice(candidate.id))
        }
    }, [candidate]);

    useEffect(() => {
        setTimeLogs(timeLogsSelector)
    }, [timeLogsSelector]);

    useEffect(() => {
        if (timeLogsInvoiceSelector.length > 0) {
            const subcontractorInvoices = timeLogsInvoiceSelector.filter(x => x.invoiceType === invType);
            setTimeLogInvoices(subcontractorInvoices)
        }
    }, [timeLogsInvoiceSelector]);

    useEffect(() => {
        let filterTimeLogs = [...timeLogsSelector];
        if (filterYear > 0)
            filterTimeLogs = filterTimeLogs.filter(x => x.year === parseInt(filterYear));

        if (filterMonth > 0)
            filterTimeLogs = filterTimeLogs.filter(x => x.month === parseInt(filterMonth));

        setTimeLogs(filterTimeLogs);

    }, [filterMonth, filterYear])

    const years = getAllYears();
    const months = getAllMonths();
    const perfData = candidate?.performerData;
    let candidateRate = candidate?.rates?.candidateRate;
    let candidateDate = candidate?.rates?.candidateEffectiveDate;
    let subcontractorRate = candidate?.rates?.subcontractorRate;
    let subcontractorDate = candidate?.rates?.subcontractorEffectiveDate;

    if (entType === "C2C Basis") {
        candidateRate = subcontractorRate;
        candidateDate = subcontractorDate;
    }

    const w9Type = getFieldData(perfData, "candidateonboardingform", "w9_Type");
    const costBefenifit = getFieldData(perfData, "candidateonboardingform", "Cost_Of_Benefit");

    const getInvoiceAmount = (item) => {
        let invAmt = getTotalHours(item?.workingHrs) * candidateRate;
        if (entType !== "W2 Basis") return parseFloat(invAmt).toFixed(2);
        const taxRate = getFieldData(perfData, "candidateonboardingform", "Tax") / 100;
        invAmt += invAmt * taxRate;
        return parseFloat(invAmt).toFixed(2);
    };

    const getFinalAmout = (obj) => {
        let fAmt = parseFloat(getInvoiceAmount(obj));
        const invItems = timeLogInvoices.filter(
            (x) => x.timeLogId === obj.id
        );
        if (invItems.length > 0) {
            const item = invItems[0];
            if (item?.adjustmentMinues > 0)
                fAmt -= item.adjustmentMinues;
            if (item?.adjustmentPlus > 0)
                fAmt += item.adjustmentPlus;
            if (item?.adpDeduction > 0)
                fAmt -= item.adpDeduction;
            return parseFloat(fAmt).toFixed(2);
        } else {
            return parseFloat(fAmt).toFixed(2);
        }
    };

    const getMonthlyPeriod = (obj) => {
        if (!obj?.workingHrs) return "";
        const wt = JSON.parse(obj.workingHrs);
        const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
        if (sortWt.length > 0) {
            return (
                <div>
                    <Moment format="MMM Do YYYY">{sortWt[0].start}</Moment> -{" "}
                    <Moment format="MMM Do YYYY">
                        {sortWt[sortWt.length - 1].type === "m"
                            ? getMonthLastDay(sortWt[sortWt.length - 1].start)
                            : sortWt[sortWt.length - 1].start}
                    </Moment>
                </div>
            );
        }

        return "";
    };

    const getC2CInvoiceStatus = (item) => {
        const tmLogs = timeLogInvoices.filter(
            (x) => x.timeLogId === item.id
                && x.status !== null
        );
        if (tmLogs.length === 0) {
            return <button
                className="btn btn-sm btn-link p-0"
                onClick={() => handleInvRevShowHide(item)}
            >
                Review
            </button>
        }

        return <>
            {tmLogs.map((inv) => (
                <>
                    <button
                        className="btn btn-sm btn-link p-0"
                        onClick={() => handleInvRevShowHide(item)}
                    >
                        Reviewed
                    </button>
                    <br />
                    {inv.status && (
                        "Approved"
                    )}
                    {inv.status === false && (
                        "Rejected"
                    )}
                    <br />
                    <Moment format="MMM Do YYYY, h:mm a">
                        {inv.reviewDateTime}
                    </Moment>
                </>
            ))}
        </>
    }

    const getInvoiceDeduction = (item) => {
        const tmLogs = timeLogInvoices.filter(
            (x) =>
                x.timeLogId === item.id
                && x.adpDeduction !== null
        );
        if (tmLogs.length === 0) {
            return <button
                className="btn btn-sm btn-link p-0"
                onClick={() => handleAdpShowHide(item)}
            >
                Add/Edit
            </button>
        }

        return <>
            {tmLogs.map((inv) => (
                <React.Fragment>
                    <button
                        className="btn btn-sm btn-link p-0"
                        onClick={() => handleAdpShowHide(item)}
                    >
                        View/Edit
                    </button>
                    <br />
                    {inv.adpDeduction}
                    <br />
                    {inv?.adpDocList?.map(
                        (doc) => (
                            <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleDownloadFile(doc.invDoc)
                                }
                            >
                                {doc.documentName}
                            </button>
                        )
                    )}
                    {inv?.adpDocList?.length > 0 && <br />}
                    <Moment format="MMM Do YYYY, h:mm a">
                        {inv.adpDateTime}
                    </Moment>
                </React.Fragment>
            ))}
        </>
    }

    const getSubcontractorInvoice = (item) => {
        const tmLogs = timeLogInvoices
            .filter(
                (x) =>
                    x.timeLogId === item.id &&
                    x.invDoc !== null
            );

        if (tmLogs.length === 0) return "";

        return <>
            {tmLogs.map((inv) => (
                <React.Fragment>
                    {inv.invoiceNumber}
                    <br />
                    <button
                        className="btn btn-sm btn-link p-0"
                        onClick={() =>
                            handleDownloadFile(
                                inv.invDoc
                            )
                        }
                    >
                        {inv.invDoc.documentName}
                    </button>
                </React.Fragment>
            ))}
        </>
    }

    const getPaymentStatus = (item) => {
        const tmLogs = timeLogInvoices.filter(
            (x) =>
                x.timeLogId === item.id &&
                x.paymentMode !== null
        );

        if (tmLogs.length === 0) {
            return <button
                className="btn btn-link btn-sm p-0"
                onClick={() => handlePayShowHide(item)}
            >
                To Pay
            </button>
        }

        return <>
            {tmLogs.map((pay) => (
                <React.Fragment>
                    <button
                        className="btn btn-link btn-sm p-0"
                        onClick={() => handlePayShowHide(item)}
                    >
                        Paid By IPCS
                    </button>
                    <br />
                    {pay?.docList?.map((doc) => (
                        <button
                            className="btn btn-sm btn-link p-0"
                            onClick={() => handleDownloadFile(doc)}
                        >
                            {doc.documentName}
                        </button>
                    ))}

                    {pay?.docList?.length > 0 && <br />}
                    <Moment format="MMM Do YYYY, h:mm a">
                        {pay.paymentDate}
                    </Moment>
                    <br />
                    Paid : {parseFloat(pay.paidAmount).toFixed(2)}
                </React.Fragment>
            ))}
        </>


    }

    const getDueAmountStatus = (item) => {
        const tmLogs = timeLogInvoices
            .filter(
                (x) =>
                    x.timeLogId === item.id &&
                    x.paidAmount !== null
            );
        if (tmLogs.length === 0) return "";

        return <>
            {tmLogs.map((inv) => parseFloat(getFinalAmout(item) - inv.paidAmount).toFixed(2))}
        </>

    }

    const getPerDiemAmount = (item) => {
        const perDiem = getFieldData(
            perfData,
            "candidateonboardingform",
            "Per_Diem_Rate"
        );
        if (perDiem !== "") {
            return <>
                {perDiem}
                <br /> {parseFloat(perDiem) * getTotalDays(item?.workingHrs)}
            </>
        }

        return "";
    }

    const handleInvRevShowHide = (obj) => {
        if (obj === "save") {
            setInvRevShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogInvoice(candidate?.id))
        }
        else {
            setInvRevShowHide(!invRevShowHide);
            setTimeLogObj(obj);
        }
    };

    const handleAdpShowHide = (obj) => {
        if (obj === "save") {
            setInvAdpShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogInvoice(candidate?.id))
        } else {
            setInvAdpShowHide(!invAdpShowHide);
            setTimeLogObj(obj);
        }
    };

    const handlePayShowHide = async (obj) => {
        if (obj === "save") {
            setPayShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogInvoice(candidate?.id))
        } else {
            if (obj === "close") {
                setPayShowHide(false);
                setTimeLogObj("");
            } else {
                const chkInvoice = timeLogInvoices.filter(
                    (x) =>
                        x.timeLogId === obj.id && x.invDoc !== null
                );
                // if (chkInvoice.length > 0 || entType !== "C2C Basis") {
                if (chkInvoice.length > 0) {
                    setPayShowHide(!payShowHide);
                    setTimeLogObj(obj);
                } else if(entType === "C2C Basis") {
                    getAlert("", "Invoice not generated", "error");
                }else{
                    setPayShowHide(!payShowHide);
                    setTimeLogObj(obj);
                }
            }
        }
    };

    const handleShowHide = async (obj) => {
        if (obj === "save") {
            setTimeLogShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogs(candidate.id));
        } else {
            setTimeLogShowHide(!timeLogShowHide);
            setTimeLogObj(obj);
        }
    };

    const handleNoteShowHide = (obj, type) => {

        if (obj === "save") {
            setNoteShowHide(false);
            setTimeLogObj("");
        } else {
            setNoteShowHide(!noteShowHide);
            setTimeLogObj(obj);
        }

    };

    return (
        <React.Fragment>

            <div className="row">
                <div className="col-md-3 form-group">
                    <select
                        name="filterYear"
                        id="year"
                        className="form-control form-control-sm"
                        value={filterYear}
                        onChange={({ currentTarget }) => setFilterYear(currentTarget.value)}
                    >
                        <option value="">Year</option>
                        {years.map((y) => (
                            <option value={y.name}>{y.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 form-group">
                    <select
                        name="filterMonth"
                        id="month"
                        className="form-control form-control-sm"
                        value={filterMonth}
                        onChange={({ currentTarget }) => setFilterMonth(currentTarget.value)}
                    >
                        <option value="">Month</option>
                        {months.map((m) => (
                            <option value={m.id}>{m.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6">
                    
                    <button type="button" onClick={() => handleShowHide("")} style={{ float: "right" }} class="btn-sm btn-outline-default">
                        <i class="fa fa-plus"></i> Add
                    </button>

                </div>
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush table-striped mb-2 border">
                            <thead className="thead-light">
                                <tr>
                                    <th>Year</th>
                                    <th>Month</th>
                                    <th>Period</th>
                                    <th>Time<br /> Logged</th>
                                    <th>Hrs Worked<br />Days Worked</th>
                                    {entType !== "W2 Basis" && <th>Status</th>}
                                    {ptype !== "timelog" && <th>Rate/Hr ($)</th>}
                                    {ptype !== "timelog" && (
                                        <th>
                                            {entType === "C2C Basis" ? "Invoice" : "Gross Pay"}
                                            <br />
                                            {entType !== "W2 Basis" && "Amount"} ($)
                                        </th>
                                    )}
                                    {w9Type === "W2 Hourly with Benefits" && (
                                        <th>Cost of benefit</th>
                                    )}
                                    {entType === "W2 Basis" && (
                                        <React.Fragment>
                                            <th>Deductions</th>
                                            <th>
                                                Net Pay
                                                <br />
                                                {entType !== "W2 Basis" && "Amount"} ($)
                                            </th>
                                            <th>
                                                Per Diem Rate ($)
                                                <br />
                                                Per Diem Amount ($)
                                            </th>
                                        </React.Fragment>
                                    )}
                                    {entType === "C2C Basis" && (
                                        <th>
                                            Subcontractor<br />Invoice
                                        </th>
                                    )}

                                    <th>Notes</th>

                                    {ptype !== "timelog" && (
                                        <>
                                            {entType !== "W2 Basis" && (
                                                <th>Net Pay<br /> Amount</th>
                                            )}
                                            <th>Payment</th>
                                            {entType !== "W2 Basis" && (
                                                <th>Due Amount</th>
                                            )}
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {timeLogs.map((item) => (
                                    <tr>
                                        <td>{item.year}</td>
                                        <td>{getMonthName(item.month)}</td>
                                        <td>{getMonthlyPeriod(item)}</td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-link p-0"
                                                onClick={() => handleShowHide(item)}
                                            >
                                                View
                                            </button>
                                            <br />
                                            {item.docList.map((doc) => (
                                                <li className="list-group-item p-0 border-0 bg-transparent">
                                                    <button
                                                        className="btn btn-sm btn-link p-0"
                                                        onClick={() => handleDownloadFile(doc)}
                                                    >
                                                        {doc.documentName}
                                                    </button>
                                                </li>
                                            ))}
                                        </td>

                                        <td>
                                            {getTotalHours(item?.workingHrs)}
                                            <br />
                                            {getTotalDays(item?.workingHrs) + " Days"}
                                        </td>
                                        {entType !== "W2 Basis" && (
                                            <td>
                                                {getC2CInvoiceStatus(item)}
                                            </td>
                                        )}
                                        {ptype !== "timelog" && (
                                            <td>
                                                {candidateRate && candidateRate}
                                                <br />
                                                {candidateDate && (
                                                    <Moment format="MMM Do YYYY">
                                                        {candidateDate}
                                                    </Moment>
                                                )}
                                            </td>
                                        )}
                                        {ptype !== "timelog" && (
                                            <td>{getInvoiceAmount(item)}</td>
                                        )}
                                        {w9Type === "W2 Hourly with Benefits" && (
                                            <td>{costBefenifit}</td>
                                        )}
                                        {entType === "W2 Basis" && (
                                            <React.Fragment>
                                                <td>
                                                    {getInvoiceDeduction(item)}
                                                </td>
                                                <td>
                                                    {getFinalAmout(item)}
                                                </td>
                                                <td>
                                                    {getPerDiemAmount(item)}
                                                </td>
                                            </React.Fragment>
                                        )}

                                        {entType === "C2C Basis" && (
                                            <td>
                                                {getSubcontractorInvoice(item)}
                                            </td>
                                        )}

                                        <td>
                                            <button
                                                className="btn btn-sm btn-link p-0"
                                                onClick={() => handleNoteShowHide(item, "TimeLog")}
                                            >
                                                View/Add
                                            </button>
                                        </td>
                                        {ptype !== "timelog" && (
                                            <>
                                                {entType !== "W2 Basis" && (
                                                    <td>
                                                        {getFinalAmout(item)}
                                                    </td>
                                                )}
                                                <td>
                                                    {getPaymentStatus(item)}
                                                </td>
                                                {entType !== "W2 Basis" && (
                                                    <td>
                                                        {getDueAmountStatus(item)}
                                                    </td>
                                                )}
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <InvoiceReview
                invRevShowHide={invRevShowHide}
                handleInvRevShowHide={handleInvRevShowHide}
                candidateObj={candidate}
                selectedObj={timeLogObj}
                invType={invType}
                timeLogInvoices={timeLogInvoices}
                getInvoiceAmount={getInvoiceAmount}
                entType={entType}
            ></InvoiceReview>

            <InvoiceApd
                handleAdpShowHide={handleAdpShowHide}
                invAdpShowHide={invAdpShowHide}
                candidateObj={candidate}
                selectedObj={timeLogObj}
                invType={invType}
                timeLogInvoices={timeLogInvoices}
                getInvoiceAmount={getInvoiceAmount}
                entType={entType}
            ></InvoiceApd>

            <CandidatePay
                payShowHide={payShowHide}
                handlePayShowHide={handlePayShowHide}
                candidateObj={candidate}
                selectedObj={timeLogObj}
                invType={invType}
                timeLogInvoices={timeLogInvoices}
                getInvoiceAmount={getInvoiceAmount}
                entType={entType}
                wfTask={wfTask}
            ></CandidatePay>

            <AddEditNotes
                noteShowHide={noteShowHide}
                handleNoteShowHide={handleNoteShowHide}
                notesType={notesType}
                candidateObj={candidate}
                timeLogObj={timeLogObj}
            ></AddEditNotes>

            <AddNewTimeLog
                showHide={timeLogShowHide}
                handleShowHide={handleShowHide}
                selectedObj={timeLogObj}
                allTimeLogs={timeLogs}
                candidateObj={candidate}
                timeLogInvoices={timeLogInvoices}
            ></AddNewTimeLog>

        </React.Fragment>
    )
}