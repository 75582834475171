import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Swal from "sweetalert2";
import UploadDocument from "../../comman/UploadDocument";
import { getTotalHours } from "../../services/appService";

class CandidatePay extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
    };
  }

  schema = {
    invoiceAmount: Joi.number().required().label("Invoice Amount"),
  };

  doSubmit = async () => {
    const { data, errors, documents } = this.state;
    if (Object.keys(errors).length > 0) {
      this.getAlert("Please fill Appropriate data before Submission.", "", "error");
      return;
    }
    // console.log(errors, "error")
    const user = auth.getCurrentUser();
    const { wfTask: workflowTask } = this.props;
    let docId = 0;
    if (documents.length > 0 && workflowTask) {
      const frmData = new FormData();
      frmData.append("message", data["notes"]);
      frmData.append("workflowTask", JSON.stringify(workflowTask));
      frmData.append("userId", user.id);
      documents.map((file, key) =>
        frmData.append("files[" + key + "]", file.file)
      );
      const { data: result } = await http.post(
        config.ApplicantUploadDocument,
        frmData
      );
      docId = result;
      if (docId > 0) {
        data["payDocId"] = docId;
        const { data: invResult } = await http.post(config.UpdateInvoicePayment, data);
        this.props.handlePayShowHide("save");
        this.getAlert("Sent Successfully", "", "success");
      }

    }
    else {
      data["payDocId"] = docId;
      const { data: result } = await http.post(config.UpdateInvoicePayment, data);
      this.props.handlePayShowHide("save");
      this.getAlert("Sent Successfully", "", "success");
    }


  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  componentWillReceiveProps = (props) => {
    const {
      selectedObj: obj,
      invType,
      timeLogInvoices,
      candidateObj,
      getInvoiceAmount,
      entType
    } = props;
    if (obj !== undefined && obj !== "") {
      const { data } = this.state;
      let candidateRate = 0;
      if (invType === "subcontractor") {
        if (entType === "C2C Basis" && candidateObj?.rates?.subcontractorRate) {
          candidateRate = candidateObj.rates.subcontractorRate;
        }
        else {
          if (candidateObj?.rates?.candidateRate)
            candidateRate = candidateObj.rates.candidateRate;
        }
      }

      if (invType === "recruiter" && candidateObj.rates?.recruiterRate)
        candidateRate = candidateObj.rates.recruiterRate;
      if (invType === "agency" && candidateObj.rates?.agencyRate)
        candidateRate = candidateObj.rates.agencyRate;

      let fAmt = 0;
      if (invType === "subcontractor") {
        fAmt = parseFloat(getInvoiceAmount(obj));
      } else {
        fAmt = parseFloat(getTotalHours(obj?.workingHrs) * candidateRate);
      }
      let invAmt = fAmt;
      const invItems = timeLogInvoices.filter((x) => x.timeLogId === obj.id);

      if (invItems.length > 0) {
        const selectedObj = invItems[0];
        if (selectedObj?.adjustmentMinues > 0)
          fAmt -= selectedObj.adjustmentMinues;
        if (selectedObj?.adjustmentPlus > 0)
          fAmt += selectedObj.adjustmentPlus;
        if (selectedObj?.adpDeduction > 0)
          fAmt -= selectedObj.adpDeduction;

        data["paymentMode"] = selectedObj.paymentMode;
        data["payNotes"] = selectedObj.payNotes;
        data["paidAmount"] = selectedObj.paidAmount;
      }

      data["id"] = obj.id;
      data["invoiceAmount"] = parseFloat(fAmt).toFixed(2);
      data["InvoiceType"] = invType;
      if (entType === "W2 Basis")
        data["paidAmount"] = parseFloat(fAmt).toFixed(2);
      // this.schema["paidAmount"] = Joi.number()
      //   .max(fAmt)
      //   .min(0)
      //   .required()
      //   .label("This Field");
      this.setState({ data });
    } else {
      this.setState({ documents: [], data: {} });
    }
  };

  onFileChange = async (event) => {
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
        size: file.size,
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.setState({ documents });
    }
  };

  getPerdeimAmount = () => {
    const { getTotalDays, selectedObj, getFieldData, candidateObj } =
      this.props;
    if (!getTotalDays) return 0;
    let amt = 0;
    const perfData = candidateObj.performerData;
    amt =
      getTotalDays(selectedObj) *
      getFieldData(perfData, "candidateonboardingform", "Per_Diem_Rate");

    return amt;
  };

  render() {
    const { data, documents } = this.state;
    const {
      payShowHide,
      handlePayShowHide,
      invType,
      entityType,
      selectedObj,
      timeLogInvoices,
    } = this.props;
    if (
      data["paidAmount"] !== undefined &&
      data["paidAmount"] !== "" &&
      data["paidAmount"] !== null
    ) {
      data["dueAmount"] = parseFloat(
        parseFloat(data["invoiceAmount"]) - parseFloat(data["paidAmount"])
      ).toFixed(2);
    } else {
      data["dueAmount"] = parseFloat(data["invoiceAmount"]).toFixed(2);
    }
    if (selectedObj !== "") data["perDiem"] = this.getPerdeimAmount();
    //console.log(selectedObj);

    return (
      <Modal show={payShowHide} size="xl">
        <Modal.Header closeButton onHide={() => handlePayShowHide("close", "")}>
          <Modal.Title>
            {invType === "subcontractor" && "Payment"}
            {invType === "recruiter" && "Payment to Recruiter"}
            {invType === "agency" && "Payment Received Status"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            {entityType === "W2 Basis" && invType === "subcontractor" ? (
              <div className="col-md-6">
                {this.renderInput("invoiceAmount", "Net Pay ($)", "number")}
              </div>
            ) : (
              <div className="col-md-4">
                {this.renderInput(
                  "invoiceAmount",
                  "Invoice Amount ($)",
                  "number"
                )}
              </div>
            )}
            {entityType !== "W2 Basis" || invType !== "subcontractor" ? (
              <>
                <div className="col-md-4">
                  {this.renderInput(
                    "paidAmount",
                    `${invType === "agency"
                      ? "Payment Amount Received"
                      : "Amount to Pay ($)"
                    }`,
                    "number"
                  )}
                </div>
                <div className="col-md-4">
                  {this.renderInput("dueAmount", "Due Amount ($)", "number")}
                </div>
              </>
            ) : (
              <div className="col-md-6">
                {this.renderInput("perDiem", "Per Diem Amount ($)", "number")}
              </div>
            )}
            <div className="col-md-2 form-group">
              <label htmlFor="" className="form-control-label">
                Payment Mode :
              </label>
            </div>
            <div className="col-md-2">
              <div className="custom-control custom-radio mb-3">
                <input
                  name="paymentMode"
                  className="custom-control-input"
                  id="byCheck"
                  type="radio"
                  onChange={this.handleChange}
                  value="Cheque"
                  defaultChecked={data["paymentMode"] === "Cheque"}
                />
                <label className="custom-control-label" htmlFor="byCheck">
                  By Cheque
                </label>
              </div>
            </div>
            <div className="col-md-8">
              <div className="custom-control custom-radio mb-3">
                <input
                  name="paymentMode"
                  className="custom-control-input"
                  id="byAch"
                  type="radio"
                  onChange={this.handleChange}
                  value="ACH"
                  defaultChecked={data["paymentMode"] === "ACH"}
                />
                <label className="custom-control-label" htmlFor="byAch">
                  By ACH
                </label>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label className="form-control">
                {data["paymentMode"] === "ACH" && "Payment made by ACH today"}
                {data["paymentMode"] === "Cheque" &&
                  "Payment made by Cheque today"}
              </label>
            </div>
            <div className="col-md-12">
              {this.renderTextArea("payNotes", "Notes")}
            </div>

            <div className="col-md-12">
              <UploadDocument
                documents={documents}
                onFileChange={this.onFileChange}
                handleFileRemove={this.handleFileRemove}
                btnLable={`${entityType === "W2 Basis" ? "Upload Pay Slip" : "Add"
                  }`}
              ></UploadDocument>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handlePayShowHide("close", "")}
          >
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
          <button
            className="btn btn-sm btn-outline-default"
            onClick={this.handleSubmit}
            disabled={this.validate()}
          >
            <i className="fa fa-paper-plane" aria-hidden="true"></i> Send
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CandidatePay;
