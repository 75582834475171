import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Swal from "sweetalert2";
import UploadDocument from "../../comman/UploadDocument";
import { getTotalHours } from "../../services/appService";
import { getAlert } from "../../services/appAlerts";

class InvoiceApd extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
    };
  }

  schema = {
    adjustmentMinues: Joi.string().required().label("Amount Deducted ($)"),
    notes: Joi.string().required().label("Notes"),
  };

  doSubmit = async () => {
    const { data, documents } = this.state;
    const user = auth.getCurrentUser();
    const { workItem: workflowTask } = this.props;
    let docId = 0;
    if (documents.length > 0 && workflowTask !== undefined) {
      const frmData = new FormData();
      frmData.append("message", data["notes"]);
      frmData.append("workflowTask", JSON.stringify(workflowTask));
      frmData.append("userId", user.id);
      documents.map((file, key) =>
        frmData.append("files[" + key + "]", file.file)
      );
      const { data: result } = await http.post(
        config.ApplicantUploadDocument,
        frmData
      );
      docId = result;
    }
    data["docId"] = docId;
    const { data: result } = await http.post(config.UpdateAdpDeduction, data);
    this.props.handleAdpShowHide("save");
    getAlert("Saved Successfully", "", "success");
  };

  componentWillReceiveProps = (props) => {
    const {
      selectedObj: obj,
      invType,
      entType,
      timeLogInvoices,
      candidateObj,
      getInvoiceAmount,
    } = props;
    if (obj !== undefined && obj !== "") {
      const { data } = this.state;

      let candidateRate = 0;
      if (invType === "subcontractor") {
        if (entType === "C2C Basis" && candidateObj?.rates?.subcontractorRate) {
          candidateRate = candidateObj.rates.subcontractorRate;
        }
        else {
          if (candidateObj?.rates?.candidateRate)
            candidateRate = candidateObj.rates.candidateRate;
        }
      }

      if (invType === "recruiter" && candidateObj.rates?.recruiterRate)
        candidateRate = candidateObj.rates.recruiterRate;
      if (invType === "agency" && candidateObj.rates?.agencyRate)
        candidateRate = candidateObj.rates.agencyRate;

      let fAmt = 0;
      if (invType === "subcontractor") {
        fAmt = parseFloat(getInvoiceAmount(obj));
      } else {
        fAmt = parseFloat(getTotalHours(obj?.workingHrs) * candidateRate);
      }
      let invAmt = fAmt;
      const invItems = timeLogInvoices.filter((x) => x.timeLogId === obj.id);

      if (invItems.length > 0) {
        const selectedObj = invItems[0];
        if (selectedObj?.adjustmentMinues > 0)
          fAmt -= selectedObj.adjustmentMinues;
        if (selectedObj?.adjustmentPlus > 0)
          fAmt += selectedObj.adjustmentPlus;
        if (selectedObj?.adpDeduction > 0)
          fAmt -= selectedObj.adpDeduction;

        data["notes"] = selectedObj.adpNotes;
        data["adjustmentMinues"] = selectedObj.adpDeduction;
      }
      fAmt = parseFloat(fAmt).toFixed(2);
      data["id"] = obj.id;
      data["finalAmt"] = fAmt;
      data["docUploadType"] = invType;
      data["invAmount"] = invAmt;
      this.setState({ data });
    }
  };

  onFileChange = async (event) => {
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
        size: file.size,
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.setState({ documents });
    }
  };

  render() {
    const { documents, data } = this.state;
    const { invAdpShowHide, handleAdpShowHide } = this.props;

    return (
      <Modal show={invAdpShowHide} size="xl">
        <Modal.Header closeButton onClick={() => handleAdpShowHide("", "")}>
          <Modal.Title>ADP Deduction</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12">
              {this.renderInput("adjustmentMinues", "Amount Deducted ($)")}
            </div>
            <div className="col-md-12">
              {this.renderTextArea("notes", "Notes")}
            </div>
            <div className="col-md-12">
              <fieldset disabled={true}>
                {this.renderInput("finalAmt", "Amount After Deduction")}
              </fieldset>
            </div>
            <div className="col-md-12">
              <UploadDocument
                documents={documents}
                onFileChange={this.onFileChange}
                handleFileRemove={this.handleFileRemove}
              ></UploadDocument>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleAdpShowHide("", "")}>
            <i class="fa fa-check" aria-hidden="true"></i> Close
          </button>
          <button className="btn btn-sm btn-outline-primary" onClick={this.handleSubmit}>
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InvoiceApd;
