import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import WorkflowElement from "../../../app/components/WorkflowElement";
import { getCurrentUser } from "../../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAppDefaultData, getStartedAppData, loadAppDefaultData, startNewApplication } from "../../../app/store/application";
import {
  AddNewCandidate, candidateAdded, checkCandidateIpcsId,
  getAddedCandidate, getCandidateLatestIpcsId,
  getCheckIpcsIdStatus,
} from "../../../app/store/candidate";
import { getAlert } from "../../../services/appAlerts";
import { addNewRates, getRateAddedStatus } from "../../../app/store/rates";
import { getCurrentWorkflowTask, loadCurrentWorkflowTask } from "../../../app/store/WorkflowEngine";
import { addFormLibrary } from "../../../app/store/forms";
import { updateBprmForm } from "../../../app/store/bprmForm";
import { checkSubcontractorEmail, checkSubcontractorUsrEmail, checkSubcontractorUsrMobile } from "../../../app/store/subcontractor";
const Candidate = (props) => {

  const { wfTask, wfId, entityType, w2Type,
    candidateObj, handleNextTab, updateSelectedObject,
    actType, wfForm } = props;

  const [appId, setAppId] = useState(1);
  const [appDefaultData, setAppDefaultData] = useState("");
  const [stateData, setStateData] = useState(null);
  const [updatedStateData, setUpdatedStateData] = useState(null);
  const [saveBtnProcessStatus, setSaveBtnProcessStatus] = useState(null);
  const [reqCount, setReqCount] = useState(0);
  const [uuid, setUuid] = useState("");
  const [latestIpcsId, setLatestIpcsId] = useState(0);
  const [newAddedCandidate, setNewAddedCandidate] = useState(false);
  const [saveBtnType, setSaveBtnType] = useState("");

  const appDefaultDataSelector = useSelector(getAppDefaultData);
  const addedCandidateSelector = useSelector(getAddedCandidate);
  const appStartedDataSelector = useSelector(getStartedAppData);
  const currentWorkflowTaskSelector = useSelector(getCurrentWorkflowTask);
  const rateAddedStatus = useSelector(getRateAddedStatus);
  const candidateLatestIpcsIdSelector = useSelector(getCandidateLatestIpcsId);
  const checkIpcsIdStatusSelector = useSelector(getCheckIpcsIdStatus);

  const currentUser = getCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAppDefaultData(1));
    //dispatch(loadCandidateLatesIpcsId());
  }, []);

  useEffect(() => {
    if (candidateObj) {
      setUpdatedStateData(candidateObj.performerData)
    } else {
      setUpdatedStateData(null);
    }
  }, [candidateObj])

  useEffect(() => {
    if (appDefaultDataSelector !== "") {
      setAppDefaultData(appDefaultDataSelector);
    }
  }, [appDefaultDataSelector]);


  const handleSubmit = (stData) => {

    const aWorkfinityApp = { ...appDefaultData };
    const appShortName = Object.keys(aWorkfinityApp)[1];
    let aWorkfinityAppEntity = { ...aWorkfinityApp[appShortName] };

    let candidateonboardingform = {};
    const data = {};
    Object.keys(stData).forEach(function (keyValue) {
      const dd = keyValue.split(".");
      if (dd.length > 1) {
        if (dd[1] !== "undefined" && dd[0] === "candidateonboardingform")
          candidateonboardingform["@" + dd[1]] = stData[keyValue];
        else if (dd[1] !== "undefined") {
          const newObj = { ...aWorkfinityAppEntity[dd[0]] };
          newObj["@" + dd[1]] = stData[keyValue];
          aWorkfinityAppEntity[dd[0]] = newObj;
        }

        data[dd[1]] = stData[keyValue];
      }
    });

    data["CandidateEntity"] = entityType;
    data["w9_Type"] = w2Type;

    candidateonboardingform["@CandidateEntity"] = entityType;
    candidateonboardingform["@w9_Type"] = w2Type;
    aWorkfinityAppEntity["candidateonboardingform"] = candidateonboardingform;
    aWorkfinityApp[appShortName] = aWorkfinityAppEntity;
    const guid = uuidv4();
    if (candidateObj?.id > 0) {
      candidateonboardingform["EntityId"] = candidateObj.performerGuid;
    } else {
      candidateonboardingform["EntityId"] = guid;
      data["EntityId"] = guid;
    }
    setStateData(data);
    setUuid(guid);
    setAppDefaultData(aWorkfinityApp);
    //saveCandidate(data, aWorkfinityApp);
    const ipcsId = data["IPCS_Existing_ID"];
    // if (!candidateObj || candidateObj === "") {
    //   const keyEmail = data["Emergency_Contact1_Email"];
    //   const partEmail = data["Email"];
    //   const keyMobile = data["Mobile_No"];
    //   if (partEmail && partEmail !== "") {
    //     dispatch(checkSubcontractorEmail(partEmail, "candidate"));
    //   } else if (keyEmail && keyEmail !== "") {
    //     dispatch(checkSubcontractorUsrEmail(keyEmail, "candidate"));
    //   } else if (keyMobile && keyMobile !== "") {
    //     dispatch(checkSubcontractorUsrMobile(keyMobile, "candidate"));
    //   } 
    // }
    // else 
    if (candidateObj?.id > 0 && parseInt(ipcsId) === parseInt(candidateObj.maxloans)) {
        saveCandidate(data, aWorkfinityApp);
      }
      else if (ipcsId > 0) {
        dispatch(checkCandidateIpcsId(ipcsId));
      }
  };

  useEffect(() => {
    setLatestIpcsId(candidateLatestIpcsIdSelector);
  }, [candidateLatestIpcsIdSelector]);

  useEffect(() => {
    if (checkIpcsIdStatusSelector > 0) {
      getAlert("Warning", "Candidate IPCS ID Already exist.", "error");
      setSaveBtnProcessStatus(false);
    }
    else if (checkIpcsIdStatusSelector === 0) {
      saveCandidate(stateData, appDefaultData);
    }
  }, [checkIpcsIdStatusSelector])

  const saveCandidate = (data, aWorkfinityApp) => {
    let candidateId = 0;
    if (candidateObj?.id)
      candidateId = candidateObj.id;

    const reg = {
      id: candidateId,
      first_Name: data["First_Name"],
      middle_Name: data["Middle_Name"],
      last_Name: data["Last_Name"],
      email: data["Email"],
      mobile: data["Mobile_No"],
      workflowId: wfId,
      entityId: data["EntityId"],
      type: "candidate",
      existingId: data["IPCS_Existing_ID"],
      Address_Line1: data["Current_Address_Line1"],
      Address_Line2: data["Current_Address_Line2"],
      us_County: data["Current_Country"],
      US_State: data["Current_State"],
      US_City: data["Current_City"],
      Zip_Code: data["Current_Zip_Code"]
    };

    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("registration", JSON.stringify(reg));
    dispatch(AddNewCandidate(formData));
    setSaveBtnProcessStatus(true);
    if (candidateId > 0) {
      updateBprmFormData(aWorkfinityApp);
    }
    // if (candidateObj?.id > 0) {
    //   if (candidateObj?.maxloans !== parseInt(reg.existingId)) {
    //     getAlert("", `IPCS ID cannot be changed`, "warning");
    //     setSaveBtnProcessStatus(false);
    //   }
    //   else {
    //     dispatch(AddNewCandidate(formData));
    //     setSaveBtnProcessStatus(true);
    //   }
    // }
    // else if (parseInt(reg.existingId) <= latestIpcsId) {
    //   getAlert("", `Enter IPCS ID greater than ${latestIpcsId}`, "warning");
    //   setSaveBtnProcessStatus(false);
    // }
    // else {
    //   dispatch(AddNewCandidate(formData));
    //   setSaveBtnProcessStatus(true);
    // }
  }

  const updateBprmFormData = async (aWorkfinityApp) => {
    const disValus = _.uniq(["candidateonboardingform"]);
    const frmData = new FormData();
    frmData.append("appEntity", JSON.stringify(aWorkfinityApp));
    frmData.append("ciid", wfTask.ciid);
    frmData.append("ddTypes", disValus);
    frmData.append("formId", wfTask.formId);
    frmData.append("performerId", currentUser.id);
    frmData.append("userName", currentUser.name);
    dispatch(updateBprmForm(frmData));
  };

  useEffect(() => {
    if (addedCandidateSelector) {
      const userObj = addedCandidateSelector;
      setSaveBtnProcessStatus(false);
      if (userObj?.performerid === -1) {
        getAlert("Warning", userObj.performername, "error");
      } else {
        if (candidateObj?.id > 0) {
          getAlert("", "Updated Successfully", "success");
          updateSelectedObject(candidateObj.id);
          dispatch(candidateAdded(null))
          if (saveBtnType === "saveAndContinue")
            handleNextTab("add");
          else if (saveBtnType === "saveAndExit")
            handleNextTab("exit");
          else
            handleNextTab("save");

        } else {
          getAlert("", "Saved Successfully", "success");
          handleSaveRates(userObj);
          updateSelectedObject(userObj.performerid);
          dispatch(candidateAdded(null))
          if (saveBtnType === "saveAndExit")
            handleNextTab("exit");
          else
            handleNextTab("add");

          handleRunApplication(userObj);
        }
      }
    }
  }, [addedCandidateSelector]);

  const handleSaveRates = async (userObj) => {
    const rateObj = {
      performerId: userObj.performerid,
      addedBy: currentUser.id,
      notes: "Default Rate",
      candidateRate: stateData["Rate_hour_Paid_to_Candidate"] ? stateData["Rate_hour_Paid_to_Candidate"] : 0,

    };
    dispatch(addNewRates(rateObj));
  };

  const handleRunApplication = async (userObj) => {
    const newApplication = {
      workflowId: userObj.workflowId,
      performerId: parseInt(userObj.performerid),
      applicationId: userObj.applicationId,
    };
    dispatch(startNewApplication(newApplication));
    setNewAddedCandidate(true);
  };

  useEffect(() => {
    if (appStartedDataSelector?.ciid > 0 && newAddedCandidate) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        setReqCount(1);
      }, 3000);
    }
  }, [appStartedDataSelector]);

  useEffect(() => {
    if (currentWorkflowTaskSelector?.formId === null && reqCount <= 2 && newAddedCandidate) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        const rqCount = reqCount + 1;
        setReqCount(rqCount)
      }, 3000);
    }
    // else if (currentWorkflowTaskSelector?.wiid > 0) {
    //   const formsObj = [
    //     {
    //       formId: 47,
    //       formName: "ACH Payment Information",
    //       remarks:
    //         "This information is captured for making Payments to your company by ACH",
    //       formType: "ACH Information",
    //     },
    //   ];
    //   if (entityType !== "C2C Basis" && newAddedCandidate) {
    //     dispatch(currentWfTaskReceived(""));
    //     setNewAddedCandidate(false);
    //     formsObj.map((item) => addReqFormLibrary(currentWorkflowTaskSelector, item));
    //   }
    // }
  }, [currentWorkflowTaskSelector]);

  const addReqFormLibrary = async (currentTask, frmObj) => {
    let workflowTask = { ...currentTask };
    workflowTask["ApplicantId"] = workflowTask.performerId;
    workflowTask["performerId"] = currentUser.id;
    const frmData = new FormData();
    frmData.append("workflowTask", JSON.stringify(workflowTask));
    frmData.append("formId", frmObj.formId);
    frmData.append("formName", frmObj.formName);
    frmData.append("remarks", frmObj.remarks);
    frmData.append("id", "");
    frmData.append("formType", frmObj.formType);
    frmData.append("status", "1");
    dispatch(addFormLibrary(frmData));
  };

  const handleNextTask = (data, btn) => {
    handleSubmit(data);
    if (btn) setSaveBtnType(btn)
  }

  return (
    <div className="col-md-12">
      {wfForm && <WorkflowElement
        wfTask={wfTask}
        wfForm={wfForm}
        handleNextTask={handleNextTask}
        viewType={false}
        appSummary={false}
        codeVerified={true}
        wfTaskType={"form"}
        saveBtnProcessStatus={saveBtnProcessStatus}
        setSaveBtnProcessStatus={setSaveBtnProcessStatus}
        updatedStateData={updatedStateData}
      />
      }
    </div>
  );
}

export default Candidate;
