import React, { Component } from "react";
import WorkflowDesign from "./WorkflowDesign";

class WorkflowMapPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: {},
      selectedObj: {},
    };
    this.myRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedObj !== prevProps.selectedObj) {
      this.setState({ selectedObj: this.props.selectedObj });
    }
  }

  render() {
    const { selectedObj } = this.state;
    const { workflowShowHide, handleWorkflowModalShowHide } = this.props;

    if (!workflowShowHide) return null;

    return (
      <div>
        <div className="row">
          <div className="col">
            <h4>
              Process ID - <span>{selectedObj.ciid}</span>, Task Name -{" "}
              <span>{selectedObj.currentTask}</span>
            </h4>
          </div>
          <div className="col-auto">
            
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => handleWorkflowModalShowHide("")}
            >
              <i className="fa fa-times mr-2" aria-hidden="true"></i>Close
            </button>
          </div>
        </div>
        <div
          className="row ml-1"
        // style={{ height: "400px", overflow: "scroll" }}
        >
          <WorkflowDesign selectedObj={selectedObj}></WorkflowDesign>
          <div ref={this.myRef} />
        </div>
      </div>
    );
  }
}

export default WorkflowMapPopup;
