import React, { Component, useEffect, useState } from "react";
import { Tab, Col, Nav, Row, Card } from "react-bootstrap";
import InfoHeader from "../comman/infoHeader";
import Pagination from "../comman/pagination";
import http from "../services/HttpService";
import config from "../config.json";
import PageHeader from "./PageHeader";
import { paginate, calculateIndex } from "../utlis/paginate";
import DowloadExcel from "./DowloadExcel";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pdf from "react-to-pdf";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PieChart from "../Charts/pie";
import BarChart from "../Charts/bar";
import { getAllYears, getMonthName } from "../services/appService";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";
import { getDataTypeApplicationReports, loadDataTypeApplicationReports } from "../app/store/reports";
import { getAllCandidateList, loadAllCandidateList } from "../app/store/candidate";
import { getPartnerListByType, loadPartnerListByType } from "../app/store/partner";

const ApplicationReport = () => {

  const route = [{ name: "Analytics", url: "" }];

  const years = getAllYears();
  const months = getMonthName();

  const [reportData, setReportData] = useState([]);
  const [allReportData, setAllReportData] = useState([]);
  const [allCandidateList, setAllCandidateList] = useState([]);
  const [partnerCandidateList, setPartnerCandidateList] = useState([]);
  const [partnerListByType, setPartnerListByType] = useState([]);
  const [partnerType, setPartnerType] = useState("");
  const [selectedJsonData, setSelectedJsonData] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [subcontractorDdName, setSubcontractorDdName] = useState("SubcontractorEnrollmentForm");
  const [recruiterDdName, setRecruiterDdName] = useState("RecruiterEnrollmentForm");
  const [candidateDdName, setCandidateDdName] = useState("CandidateOnboardingForm");

  const dataTypeApplicationReportsSelector = useSelector(getDataTypeApplicationReports);
  const allCandidateListSelector = useSelector(getAllCandidateList);
  const partnerListByTypeSelector = useSelector(getPartnerListByType);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Entity Data"));
    dispatch(setCurrentRoute(route))
  }, []);

  useEffect(() => {
    dispatch(loadDataTypeApplicationReports());
    dispatch(loadAllCandidateList());
  }, []);

  useEffect(() => {
    if (dataTypeApplicationReportsSelector.length > 0) {
      const rptDt = dataTypeApplicationReportsSelector;
      console.log(rptDt);
      setAllReportData(rptDt);
    }
  }, [dataTypeApplicationReportsSelector]);

  useEffect(() => {
    setAllCandidateList(allCandidateListSelector);
  }, [allCandidateListSelector]);

  useEffect(() => {
    setPartnerListByType(partnerListByTypeSelector);
  }, [partnerListByTypeSelector]);

  const getAttribute = (t) => {
    if (t?.data && t?.data !== "") {
      const items = JSON.parse(t.data);
      return items;
    }
    return [];
  };

  const getAttributeValue = (d, t, att) => {
    if (d?.appData && d.appData === "") return "";
    const itemValues = JSON.parse(d.appData);
    const appShortName = Object.keys(itemValues)[1];
    const items = itemValues[appShortName][t.name.toLowerCase()];
    return items && items["@" + att];
  };

  const handleOnSelect = () => {
    //this.setState({ currentPage: 1 });
  };

  const handleCategoryOnChange = ({ currentTarget }) => {
    const partType = currentTarget.value;
    setPartnerType(partType);
    let rptData = [];
    if (partType === "Sub-Contractors" || partType === "Recruiter") {
      dispatch(loadPartnerListByType(partType));
      if (partType === "Sub-Contractors")
        rptData = allReportData.filter((x) => x.name === subcontractorDdName);
      if (partType === "Recruiter")
        rptData = allReportData.filter((x) => x.name === recruiterDdName);
      setReportData(rptData);
    } else {
      const filterCandidates = allCandidateList.filter((x) => x.registrationType === partType);
      setPartnerCandidateList(filterCandidates);
      rptData = allReportData.filter((x) => x.name === candidateDdName);
      setReportData(rptData);
    }
    setSelectedPartnerId("");
    setSelectedJsonData("");
  };

  const handleEntityTypeOnChange = ({ currentTarget }) => {
    const id = currentTarget.value;
    let jsonData = "";
    if (partnerType === "Sub-Contractors" || partnerType === "Recruiter") {
      const partner = partnerListByType.filter((x) => x.fuid === parseInt(id));
      if (partner.length > 0)
        if (partner[0].contact1)
          jsonData = partner[0].contact1.performerData
    } else {
      const perf = allCandidateList.filter((x) => x.performerid === parseInt(id));

      if (perf.length > 0)
        jsonData = perf[0].performerData
    }
    setSelectedJsonData(jsonData);
    setSelectedPartnerId(parseInt(id));
  };

  return (
    <React.Fragment>
      <div className="col-md-12">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="1"
          onSelect={handleOnSelect}
        >
          <Row>
            <Col sm={12}>
              <div className="card-body border p-3 shadow-none">
                <label className="form-control-label">
                  Select Entity
                </label>
                <select
                  name="category"
                  id="category"
                  className="form-control"
                  onChange={handleCategoryOnChange}
                  disabled={allReportData.length === 0}
                  value={partnerType}
                >
                  <option value="">
                    {allReportData.length === 0 ? "Loading..." : "Select Entity"}
                  </option>
                  <option value="Sub-Contractors">Subcontractors</option>
                  <option value="Recruiter">Recruiters</option>
                  <option value="C2C Basis">C2C Candidates</option>
                  <option value="1099 Basis">1099 Candidates</option>
                  <option value="W2 Basis">W2 Hourly Candidates</option>
                </select>

                <br />
                {partnerType === "Sub-Contractors" ||
                  partnerType === "Recruiter" ? (
                  <>
                    <label className="form-control-label">
                      List of {partnerType === "Sub-Contractors" ? "Subcontractors" : partnerType}
                    </label>
                    <select
                      name="entity"
                      id="entity"
                      className="form-control form-group"
                      onChange={handleEntityTypeOnChange}
                      value={selectedPartnerId}
                    >
                      <option value="">Select</option>
                      {partnerListByType.map((item) => (
                        <option value={item.fuid}>{item.label}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  partnerType !== "" && (
                    <>
                      <label className="form-control-label">List of Candidates</label>
                      <select
                        name="entity"
                        id="entity"
                        className="form-control form-group"
                        onChange={handleEntityTypeOnChange}
                        value={selectedPartnerId}
                      >
                        <option value="">Select</option>
                        {partnerCandidateList.map((item) => (
                          <option value={item.performerid}>
                            {item.performername}
                          </option>
                        ))}
                      </select>
                    </>
                  )
                )}
                {selectedJsonData && reportData.map((t, key) => (
                  <table className="table border align-items-center table-flush">
                    <thead className="thead-light">
                      {getAttribute(t).length > 0 &&
                        getAttribute(t).map((m) => (
                          <tr>
                            <td style={{ width: "20%", backgroundColor: "#f6f6f6" }} className="p-2">{m.Label}</td>
                            <td className="p-2">
                              {getAttributeValue({ appData: selectedJsonData }, t, m.Name)}
                            </td>
                          </tr>
                        ))}
                    </thead>
                  </table>
                ))}
              </div>
            </Col>

          </Row>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
}

export default ApplicationReport;
