import React, { Component, useEffect, useState } from "react";
import Form from "../../../comman/form";
import http from "../../../services/HttpService";
import geo, { getStateList } from "../../../services/geoService";
import auth from "../../../services/authService";
import config from "../../../config.json";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import moment from "moment";
import Select from "../../../comman/select";
import useForm from "../../../app/hooks/useForm";
import useControls from "../../../app/hooks/useControls";
import { getApplicationData } from "../../../app/store/application";
import { updateNewCandidate } from "../../../app/store/candidate";
import { updateRate } from "../../../app/store/rates";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../app/store/auth";
import { addNewDuration, getDurationAddedStatus, getStateAdminData, stateadmindataUpdated } from "../../../app/store/duration";
import { getAlert } from "../../../services/appAlerts";

const Agency = (props) => {

  const { candidateObj, actType, handleNextTab, updateSelectedObject } = props;

  const schema = {
    State: Joi.string().required().label("State"),
    AgencyName: Joi.string().required().label("Agency Name"),
    PositionTitle: Joi.string().required().label("Position Title"),
    StartDate: Joi.string().required().label("Start Date"),
    EngagementDuration: Joi.number()
      //.required()
      .min(1)
      .max(120)
      .options({
        language: {
          string: {
            min: `should be minimum ${1} and maximum ${120} numeric digits only.`,
            max: `should be minimum ${1} and maximum ${120} numeric digits only.`,
          },
        },
      })
      .label("Engagement Duration (Month)"),
    EngagementDay: Joi.number()
      //.required()
      .min(1)
      .max(29)
      .options({
        language: {
          string: {
            min: `should be minimum ${1} and maximum ${29} numeric digits only.`,
            max: `should be minimum ${1} and maximum ${29} numeric digits only.`,
          },
        },
      })
      .label("Engagement Duration (Day)"),
    RatePayableIpcs: Joi.string()
      .required()
      .label("Rate/Hour-Received By IPCS $"),

    PositionID: Joi.string().required().label("Position ID"),
    PositionLocation: Joi.string().required().label("Position Location"),
  };

  const [stateList, setStateList] = useState([]);
  const [aworkfinityApp, setAworkfinityApp] = useState(null);
  const [updatedStateData, setUpdatedStateData] = useState({});
  const [saveBtnType, setSaveBtnType] = useState("");

  const durationAddedStatusSelector = useSelector(getDurationAddedStatus)

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    getUsStates();
  }, []);

  const getUsStates = async () => {
    const dt = await getStateList();
    setStateList(dt);
    // console.log(dt, "api state")
  }

  useEffect(() => {
    if (candidateObj?.performerData) {
      const obj = getApplicationData(candidateObj.performerData);
      if (obj?.aWorkfinityApp) {
        setAworkfinityApp(obj.aWorkfinityApp);
        const appName = Object.keys(obj.aWorkfinityApp)[1];
        const aWorkfinityAppEntity = obj.aWorkfinityApp[appName];
        const agencyData = aWorkfinityAppEntity["projectengagement"];
        const data = {};
        if (agencyData) {
          Object.keys(agencyData).forEach(function (keyValue1) {
            if (keyValue1 !== "EntityId") {
              var item = agencyData[keyValue1];
              if (!Array.isArray(item)) data[keyValue1.replace("@", "")] = item;
            }
          });
          setStateData(data);
          setUpdatedStateData(data);
        }
      }
    }
    else {
      setStateData({});
      setAworkfinityApp(null);
    }

  }, [candidateObj]);

  const doSubmit = (btnType) => {
    setSaveBtnType(btnType);
    const NewAWorkfinityApp = { ...aworkfinityApp };
    const appName = Object.keys(NewAWorkfinityApp)[1];
    const aWorkfinityAppEntity = NewAWorkfinityApp[appName];

    const projectengagement = {};
    Object.keys(stateData).forEach(function (keyValue) {
      projectengagement["@" + keyValue] = stateData[keyValue];
    });

    aWorkfinityAppEntity["projectengagement"] = projectengagement;
    NewAWorkfinityApp[appName] = aWorkfinityAppEntity;

    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(NewAWorkfinityApp));
    formData.append("recruiter", "");
    formData.append("subcontractor", "");
    formData.append("perfId", candidateObj.performerid);
    console.log(stateData, "statedat")
    dispatch(stateadmindataUpdated({
      RatePayableIpcs: stateData["RatePayableIpcs"],
      State_Admin_Fee: stateData["State_Admin_Fee"],
      State_Admin_Fee_Amount: stateData["State_Admin_Fee_Amount"],
    }))
    dispatch(updateNewCandidate(formData));

    handleSaveRates();
    handleSaveDuration();

    // if (actType === "edit")
    //   getAlert("", "Updated Successfully", "success");
    // else
    //   getAlert("", "Saved Successfully", "success");

    updateSelectedObject(candidateObj.performerid);
    handleNextTab(saveBtnType);
  }

  const handleSaveRates = () => {
    const frmData = new FormData();
    frmData.append("id", candidateObj.performerid);
    frmData.append("type", "agency");
    frmData.append("date", stateData["StartDate"]);
    frmData.append("agencyRate", stateData["RatePayableIpcs"]);
    // console.log(stateData, "stated")
    dispatch(updateRate(frmData));
  };

  const handleSaveDuration = () => {
    const frmObj = {
      performerId: candidateObj.performerid,
      addedBy: currentUser.id,
      duration: stateData["EngagementDuration"],
      durationState: true,
      endDate: new Date(stateData["EndDate"]),
      notes: "Default",
      startDate: stateData["StartDate"],
      fixedHours: stateData["Fixed_Hours"],
      adminFeeAmount: stateData["State_Admin_Fee_Amount"],
      adminFeePercentage: stateData["State_Admin_Fee"],
    };
    dispatch(addNewDuration(frmObj));
  };

  // useEffect(()=>{
  //   if(durationAddedStatusSelector){
  //     if (actType === "edit")
  //       getAlert("", "Updated Successfully", "success");
  //     else
  //       getAlert("", "Saved Successfully", "success");

  //     updateSelectedObject(candidateObj.performerid);
  //     handleNextTab(saveBtnType);
  //   }
  // },[durationAddedStatusSelector])


  const { validate, handleChange, stateData, setStateData,
    stateErrors, setStateErrors, handleSubmit } = useForm(schema, doSubmit);

  const { renderSelect, renderInput, renderTextArea } = useControls(stateData, stateErrors, handleChange, schema);

  const handleAgencyRateChange = ({ target }) => {
    const updData = { ...stateData };
    updData[target.name] = target.value;
    setStateData(updData);
  }

  const handleAdminFeePerChange = ({ target }) => {
    let rateAmt = parseFloat(stateData["RatePayableIpcs"]);
    const techFee = parseFloat(stateData["Tech_Fee"]);
    if (techFee > 0)
      rateAmt -= techFee;

    if (parseInt(target.value) >= 100) {
      getAlert("Warning", "Should be minimum 1 and maximum 99 numeric digits only.", "warning");
      return;
    }
    const updData = { ...stateData };
    updData[target.name] = target.value;
    updData["State_Admin_Fee_Amount"] = "0";
    setStateData(updData);
  }

  const handleAdminFeeChange = ({ target }) => {
    let rateAmt = parseFloat(stateData["RatePayableIpcs"]);
    const techFee = parseFloat(stateData["Tech_Fee"]);
    if (techFee > 0)
      rateAmt -= techFee;

    if (parseFloat(target.value) >= rateAmt) {
      getAlert("Warning", `Should be less then ${rateAmt} received by agency.`, "warning");
      return;
    }
    const updData = { ...stateData };
    updData[target.name] = target.value;
    updData["State_Admin_Fee"] = "0";
    setStateData(updData);
  }

  const handleTechFeeChange = ({ target }) => {
    let rateAmt = parseFloat(stateData["RatePayableIpcs"]);
    const adminFee = parseFloat(stateData["State_Admin_Fee_Amount"]);
    const adminFeePrc = parseInt(stateData["State_Admin_Fee"]);

    if (adminFee > 0)
      rateAmt -= adminFee;

    if (adminFeePrc > 0)
      rateAmt -= (rateAmt * adminFeePrc / 100);

    if (parseFloat(target.value) >= rateAmt) {
      getAlert("Warning", `Should be less then ${rateAmt} received by agency.`, "warning");
      return;
    }
    const updData = { ...stateData };
    updData[target.name] = target.value;
    setStateData(updData);
  }

  if (
    stateData["StartDate"] !== "" && stateData["StartDate"] &&
    stateData["EngagementDuration"] !== "" && stateData["EngagementDuration"]
  ) {
    const dt = new Date(stateData["StartDate"]);
    const num = parseInt(stateData["EngagementDuration"]);
    const numDay = parseInt(stateData["EngagementDay"]);
    if (num < 999) {
      dt.setDate(dt.getDate() + 1);
      dt.setMonth(dt.getMonth() + num);
      dt.setDate(dt.getDate() - 1);
      if (numDay < 30 && numDay > 0) dt.setDate(dt.getDate() + numDay);
      const dy = dt.getFullYear();
      let dm = dt.getMonth() + 1;
      let dd = dt.getDate();
      if (dm < 10) dm = "0" + dm;
      if (dd < 10) dd = "0" + dd;
      stateData["EndDate"] = dy + "-" + dm + "-" + dd;
    }
  }

  // let min = 0;
  // let max = parseInt(stateData["RatePayableIpcs"]);

  // if (parseInt(stateData["State_Admin_Fee"]) > 0) {
  //   stateData["State_Admin_Fee_Amount"] = "0";
  //   const feeValue = parseInt((max * parseInt(stateData["State_Admin_Fee"])) / 100);
  //   max = max - feeValue;
  // }
  // else if (parseInt(stateData["State_Admin_Fee_Amount"]) > 0) {
  //   stateData["State_Admin_Fee"] = "0";
  //   max = max - parseInt(stateData["State_Admin_Fee_Amount"]);
  // }

  // console.log(max)

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-6">
          {renderSelect("State", "State", stateList, "name", "name")}
        </div>
        <div className="col-md-6">
          {renderInput("AgencyName", "Agency Name")}
        </div>
        <div className="col-md-6">
          {renderInput("PositionTitle", "Position Title")}
        </div>
        <div className="col-md-6">
          {renderInput("PositionID", "Position ID")}
        </div>
        <div className="col-md-12">
          {renderInput("PositionLocation", "Position Location")}
        </div>

        <div className="col-md-3">
          {renderInput("StartDate", "Start Date", "date")}
        </div>
        <div className="col-md-3">
          {renderInput(
            "EngagementDuration",
            "Engagement Duration (Months)",
            "number"
          )}
        </div>
        <div className="col-md-2">
          {renderInput("EngagementDay", "Days", "number")}
        </div>
        <div className="col-md-1 d-flex align-items-center justify-content-center">
          <label htmlFor="" className="form-control-label">
            OR
          </label>
        </div>
        <div className="col-md-3">
          <fieldset disabled={true}>
            {renderInput("EndDate", "End Date", "date")}
          </fieldset>
        </div>

        <div className="col-md-5">
          <label htmlFor="RatePayableIpcs" className="form-control-label">Rate/Hour-Received By IPCS $</label>
          <input
            type="number"
            name="RatePayableIpcs"
            id="RatePayableIpcs"
            className="form-control"
            placeholder="Rate/Hour-Received By IPCS $"
            onChange={handleAgencyRateChange}
            value={stateData["RatePayableIpcs"]}
            min={0} />
          {/* {renderInput(
            "RatePayableIpcs",
            "Rate/Hour-Received By IPCS $",
            "number"
          )} */}
        </div>

        <div className="col-md-3 form-group">
          <label htmlFor="State_Admin_Fee" className="form-control-label">State Admin Fee %</label>
          <input
            type="number"
            name="State_Admin_Fee"
            id="State_Admin_Fee"
            className="form-control"
            placeholder="State Admin Fee %"
            onChange={handleAdminFeePerChange}
            value={stateData["State_Admin_Fee"]}
            disabled={!(parseInt(stateData["RatePayableIpcs"]) > 0)}
            min={0} />
          {/* {renderInput(
            "State_Admin_Fee",
            "State/MSP Admin Fee %",
            "number", "", 0, 99
          )} */}
        </div>
        <div className="col-md-1 d-flex align-items-center justify-content-center">
          <label htmlFor="" className="form-control-label">
            OR
          </label>
        </div>
        <div className="col-md-3">
          <label htmlFor="State_Admin_Fee_Amount" className="form-control-label">State Admin Fee $/Hr</label>
          <input
            type="number"
            name="State_Admin_Fee_Amount"
            id="State_Admin_Fee_Amount"
            className="form-control"
            placeholder="State Admin Fee $/Hr"
            onChange={handleAdminFeeChange}
            value={stateData["State_Admin_Fee_Amount"]}
            disabled={!(parseInt(stateData["RatePayableIpcs"]) > 0)}
            min={0} />
          {/* {renderInput(
            "State_Admin_Fee_Amount",
            "State/MSP Admin Fee $/Hr",
            "number", "", min, max
          )} */}
        </div>
        <div className="col-md-6">
          {renderInput("Fixed_Hours", "Fixed Hours", "number")}
        </div>
        {/* <div className="col-md-6">
          <label htmlFor="Tech_Fee" className="form-control-label">Tech Fee (Azure) $/hr</label>
          <input
            type="number"
            name="Tech_Fee"
            id="Tech_Fee"
            className="form-control"
            placeholder="Tech Fee (Azure) $/hr"
            onChange={handleTechFeeChange}
            value={stateData["Tech_Fee"]}
            disabled={!(parseInt(stateData["RatePayableIpcs"]) > 0)}
            min={0} />
          {renderInput("Tech_Fee", "Tech Fee (Azure) $/hr", "number", "", min, max)}
        </div> */}

        <div className="col-md-12">
          {renderTextArea("ProjectDescription", "Project Description")}
        </div>

        <div className="col-md-12">
          <label
            htmlFor=""
            className="form-control-label bg-admin-light border-admin-left p-2 w-100"
          >
            Agency Contact
          </label>
        </div>

        <div className="col-md-6">
          {renderInput("AgencyContact", "Name")}
        </div>
        <div className="col-md-6">
          {renderInput("AgencyTitle", "Title")}
        </div>
        <div className="col-md-6">
          {renderInput("AgencyEmail", "Email")}
        </div>
        <div className="col-md-6">
          {renderInput("AgencyPhone", "Phone No.", "phone")}
        </div>
        <div className="col-md-12 text-center form-group">

          <button
            className="btn btn-sm btn-outline-default"
            disabled={validate()}
            onClick={() => handleSubmit("save")}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save
          </button>

          <button
            className="btn btn-sm btn-outline-default"
            disabled={validate()}
            onClick={() => handleSubmit("saveAndContinue")}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save and Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default Agency;
