import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getAllMonths, getAllYears, getFieldData, getMonthLastDay, getMonthName, getTotalDays, getTotalHours } from "../../../services/appService";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getTimeLogs, loadTimeLogs } from "../../store/timeLog";
import { getTimeLogInvoice, loadTimeLogInvoice } from "../../store/timeLogInvoice";
import { getAlert } from "../../../services/appAlerts";
import AddNewTimeLog from "../../../components/OMCRegistry/AddNewTimeLog";
import InvoiceReview from "../../../components/OMCRegistry/InvoiceReview";
import AddEditNotes from "../../../components/OMCRegistry/AddEditNotes";
import CandidatePay from "../../../components/OMCRegistry/CandidatePay";
import useDocDownload from "../../hooks/useDocDownload";

export default function Recruiter(props) {
    const { candidate, ptype, entType } = props;

    const [timeLogs, setTimeLogs] = useState([]);
    const [timeLogInvoices, setTimeLogInvoices] = useState([]);
    const [invRevShowHide, setInvRevShowHide] = useState(false);
    const [timeLogShowHide, setTimeLogShowHide] = useState(false);
    const [noteShowHide, setNoteShowHide] = useState(false);
    const [payShowHide, setPayShowHide] = useState(false);
    const [invType, setInvType] = useState("recruiter");
    const [timeLogObj, setTimeLogObj] = useState("");
    const [filterYear, setFilterYear] = useState("");
    const [filterMonth, setFilterMonth] = useState("");
    const [notesType, setNotesType] = useState("TimeLog");

    const timeLogsSelector = useSelector(getTimeLogs);
    const timeLogsInvoiceSelector = useSelector(getTimeLogInvoice);

    const [handleDownloadFile] = useDocDownload();

    const dispatch = useDispatch();
    useEffect(() => {
        if (candidate?.id) {
            dispatch(loadTimeLogs(candidate.id));
            dispatch(loadTimeLogInvoice(candidate.id))
        }
    }, [candidate]);

    useEffect(() => {
        setTimeLogs(timeLogsSelector)
    }, [timeLogsSelector]);

    useEffect(() => {
        if (timeLogsInvoiceSelector.length > 0) {
            const subcontractorInvoices = timeLogsInvoiceSelector.filter(x => x.invoiceType === invType);
            setTimeLogInvoices(subcontractorInvoices)
        }
    }, [timeLogsInvoiceSelector]);

    useEffect(() => {

        let filterTimeLogs = [...timeLogsSelector];
        if (filterYear > 0)
            filterTimeLogs = filterTimeLogs.filter(x => x.year === parseInt(filterYear));

        if (filterMonth > 0)
            filterTimeLogs = filterTimeLogs.filter(x => x.month === parseInt(filterMonth));

        setTimeLogs(filterTimeLogs);

    }, [filterMonth, filterYear]);

    const years = getAllYears();
    const months = getAllMonths();
    const perfData = candidate?.performerData;
    const recruiterRate = candidate.rates?.recruiterRate;
    const recruiterDate = candidate.rates?.recruiterEffectiveDate;

    const getMonthlyPeriod = (obj) => {
        if (!obj?.workingHrs) return "";
        const wt = JSON.parse(obj.workingHrs);
        const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
        if (sortWt.length > 0) {
            return (
                <div>
                    <Moment format="MMM Do YYYY">{sortWt[0].start}</Moment> -{" "}
                    <Moment format="MMM Do YYYY">
                        {sortWt[sortWt.length - 1].type === "m"
                            ? getMonthLastDay(sortWt[sortWt.length - 1].start)
                            : sortWt[sortWt.length - 1].start}
                    </Moment>
                </div>
            );
        }

        return "";
    };

    const getInvoiceStatus = (item) => {
        const tmLogs = timeLogInvoices.filter(
            (x) => x.timeLogId === item.id
                && x.status !== null
        );
        if (tmLogs.length === 0) {
            return <button
                className="btn btn-sm btn-link p-0"
                onClick={() => handleInvRevShowHide(item)}
            >
                Review
            </button>
        }

        return <>
            {tmLogs.map((inv) => (
                <>
                    <button
                        className="btn btn-sm btn-link p-0"
                        onClick={() => handleInvRevShowHide(item)}
                    >
                        Reviewed
                    </button>
                    <br />
                    {inv.status && (
                        "Approved"
                    )}
                    {inv.status === false && (
                        "Rejected"
                    )}
                    <br />
                    <Moment format="MMM Do YYYY, h:mm a">
                        {inv.reviewDateTime}
                    </Moment>
                </>
            ))}
        </>
    }

    const getRecruiterInvoice = (item) => {
        const tmLogs = timeLogInvoices
            .filter(
                (x) =>
                    x.timeLogId === item.id &&
                    x.invDoc !== null
            );

        if (tmLogs.length === 0) return "";

        return <>
            {tmLogs.map((inv) => (
                <React.Fragment>
                    {inv.invoiceNumber}
                    <br />
                    <button
                        className="btn btn-sm btn-link p-0"
                        onClick={() =>
                            handleDownloadFile(
                                inv.invDoc
                            )
                        }
                    >
                        {inv.invDoc.documentName}
                    </button>
                </React.Fragment>
            ))}
        </>
    }

    const getInvoiceAmount = (item) => {
        let invAmt = getTotalHours(item?.workingHrs) * recruiterRate;
        if (entType !== "W2 Basis") return parseFloat(invAmt).toFixed(2);
        const taxRate = getFieldData(perfData, "candidateonboardingform", "Tax") / 100;
        invAmt += invAmt * taxRate;
        return parseFloat(invAmt).toFixed(2);
    };

    const getFinalAmout = (obj) => {
        let fAmt = parseFloat(getInvoiceAmount(obj));
        const invItems = timeLogInvoices.filter(
            (x) => x.timeLogId === obj.id
        );
        if (invItems.length > 0) {
            const item = invItems[0];
            if (item?.adjustmentMinues > 0)
                fAmt -= item.adjustmentMinues;
            if (item?.adjustmentPlus > 0)
                fAmt += item.adjustmentPlus;
            if (item?.adpDeduction > 0)
                fAmt -= item.adpDeduction;
            return parseFloat(fAmt).toFixed(2);
        } else {
            return parseFloat(fAmt).toFixed(2);
        }
    };

    const getPaymentStatus = (item) => {
        const tmLogs = timeLogInvoices.filter(
            (x) =>
                x.timeLogId === item.id &&
                x.paymentMode !== null
        );

        if (tmLogs.length === 0) {
            return <button
                className="btn btn-link btn-sm p-0"
                onClick={() => handlePayShowHide(item)}
            >
                To Pay
            </button>
        }

        return <>
            {tmLogs.map((pay) => (
                <React.Fragment>
                    <button
                        className="btn btn-link btn-sm p-0"
                        onClick={() => handlePayShowHide(item)}
                    >
                        Paid By IPCS
                    </button>
                    <br />
                    {pay?.docList?.map((doc) => (
                        <button
                            className="btn btn-sm btn-link p-0"
                            onClick={() =>
                                handleDownloadFile(
                                    doc
                                )
                            }
                        >
                            {doc.documentName}
                        </button>
                    ))}

                    {pay?.docList?.length > 0 && <br />}
                    <Moment format="MMM Do YYYY, h:mm a">
                        {pay.paymentDate}
                    </Moment>
                    <br />
                    Paid : {parseFloat(pay.paidAmount).toFixed(2)}
                </React.Fragment>
            ))}
        </>


    }

    const getDueAmountStatus = (item) => {
        const tmLogs = timeLogInvoices
            .filter(
                (x) =>
                    x.timeLogId === item.id &&
                    x.paidAmount !== null
            );
        if (tmLogs.length === 0) return "";

        return <>
            {tmLogs.map((inv) => parseFloat(getFinalAmout(item) - inv.paidAmount).toFixed(2))}
        </>

    }

    const handleInvRevShowHide = (obj) => {
        if (obj === "save") {
            setInvRevShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogInvoice(candidate?.id))
        }
        else {
            setInvRevShowHide(!invRevShowHide);
            setTimeLogObj(obj);
        }
    };

    const handleShowHide = async (obj) => {
        if (obj === "save") {
            setTimeLogShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogs(candidate.id));
        } else {
            setTimeLogShowHide(!timeLogShowHide);
            setTimeLogObj(obj);
        }
    };



    const handleNoteShowHide = (obj) => {
        setNoteShowHide(!noteShowHide);
        setTimeLogObj(obj);
    };

    const handlePayShowHide = async (obj) => {
        if (obj === "save") {
            setPayShowHide(false);
            setTimeLogObj("");
            if (candidate?.id)
                dispatch(loadTimeLogInvoice(candidate?.id))
        } else {
            if (obj === "close") {
                setPayShowHide(false);
                setTimeLogObj("");
            } else {
                const chkInvoice = timeLogInvoices.filter(
                    (x) =>
                        x.timeLogId === obj.id && x.invDoc !== null
                );
                // console.log(chkInvoice, entType, "chkInvoice")
                // if (chkInvoice.length > 0 || entType !== "C2C Basis") {
                if (chkInvoice.length > 0) {
                    setPayShowHide(!payShowHide);
                    setTimeLogObj(obj);
                } else {
                    getAlert("", "Invoice not generated", "error");
                }
            }
        }
    };

    return <React.Fragment>

        <div className="row">
            <div className="col-md-3 form-group">
                <select
                    name="filterYear"
                    id="filterYear"
                    className="form-control form-control-sm"
                    value={filterYear}
                    onChange={({ currentTarget }) => setFilterYear(currentTarget.value)}
                >
                    <option value="">Year</option>
                    {years.map((y) => (
                        <option value={y.name}>{y.name}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-3 form-group">
                <select
                    name="filterMonth"
                    id="filterMonth"
                    className="form-control form-control-sm"
                    value={filterMonth}
                    onChange={({ currentTarget }) => setFilterMonth(currentTarget.value)}
                >
                    <option value="">Month</option>
                    {months.map((m) => (
                        <option value={m.id}>{m.name}</option>
                    ))}
                </select>
            </div>

            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table align-items-center table-flush table-striped mb-2 border">
                        <thead className="thead-light">
                            <tr>
                                <th>Year</th>
                                <th>Month</th>
                                <th>Period</th>
                                <th>Time<br />Logged</th>
                                <th>Hrs Worked<br />Days Worked</th>
                                <th>Status</th>
                                <th>Rate/Hr ($)</th>
                                <th>Invoice<br />Amount ($)</th>
                                <th>Recruiter<br />Invoice</th>
                                <th>Notes</th>
                                <th>Final Pay<br />Amount</th>
                                <th>Payment</th>
                                <th>Due Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {timeLogs.map((item) => (
                                <tr>
                                    <td>{item.year}</td>
                                    <td>
                                        {getMonthName(item.month)}
                                    </td>
                                    <td>{getMonthlyPeriod(item)}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-link p-0"
                                            onClick={() => handleShowHide(item)}
                                        >
                                            View
                                        </button>
                                        <br />
                                        {item.docList.map((doc) => (
                                            <li className="list-group-item p-0 border-0 bg-transparent">
                                                <button
                                                    className="btn btn-sm btn-link p-0"
                                                    onClick={() => handleDownloadFile(doc)}
                                                >
                                                    {doc.documentName}
                                                </button>
                                            </li>
                                        ))}
                                    </td>

                                    <td>
                                        {getTotalHours(item?.workingHrs)}
                                        <br />
                                        {getTotalDays(item?.workingHrs) + " Days"}
                                    </td>
                                    <td>
                                        {getInvoiceStatus(item)}
                                    </td>

                                    <td>
                                        {parseFloat(recruiterRate).toFixed(2)}
                                        <br />
                                        {recruiterDate && (
                                            <Moment format="MMM Do YYYY, h:mm a">
                                                {recruiterDate}
                                            </Moment>
                                        )}
                                    </td>
                                    <td>
                                        {parseFloat(getTotalHours(item?.workingHrs) * recruiterRate).toFixed(2)}
                                    </td>

                                    <td>
                                        {getRecruiterInvoice(item)}
                                    </td>

                                    <td>
                                        <button
                                            className="btn btn-sm btn-link p-0"
                                            onClick={() => handleNoteShowHide(item)}
                                        >
                                            View/Add
                                        </button>
                                    </td>
                                    <td>
                                        {getFinalAmout(item)}
                                    </td>
                                    <td>
                                        {getPaymentStatus(item)}
                                    </td>
                                    <td>
                                        {getDueAmountStatus(item)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>



        <InvoiceReview
            invRevShowHide={invRevShowHide}
            handleInvRevShowHide={handleInvRevShowHide}
            candidateObj={candidate}
            selectedObj={timeLogObj}
            invType={invType}
            timeLogInvoices={timeLogInvoices}
            getInvoiceAmount={getInvoiceAmount}
            entType={entType}
        ></InvoiceReview>

        <AddEditNotes
            noteShowHide={noteShowHide}
            handleNoteShowHide={handleNoteShowHide}
            notesType={notesType}
            candidateObj={candidate}
            timeLogObj={timeLogObj}
        ></AddEditNotes>

        <CandidatePay
            payShowHide={payShowHide}
            handlePayShowHide={handlePayShowHide}
            candidateObj={candidate}
            selectedObj={timeLogObj}
            invType={invType}
            timeLogInvoices={timeLogInvoices}
            getInvoiceAmount={getInvoiceAmount}
            entType={entType}
        ></CandidatePay>

        <AddNewTimeLog
            showHide={timeLogShowHide}
            handleShowHide={handleShowHide}
            selectedObj={timeLogObj}
            allTimeLogs={timeLogs}
            candidateObj={candidate}
            timeLogInvoices={timeLogInvoices}
        ></AddNewTimeLog>


    </React.Fragment>
}