import React, { Component, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Form from "../../comman/form";
import AddWorkingHrs from "./AddWorkingHrs";
import { getAllMonths, getAllYears } from "../../services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";
import { getCurrentUser } from "../../app/store/auth";
import { addNewTmLog, getTmLogAddedStatus } from "../../app/store/timeLog";

const AddNewTimeLog = (props) => {

  const { showHide: tmShowHide,
    handleShowHide, viewType, selectedObj,
    allTimeLogs, candidateObj, timeLogInvoices } = props;

  const localizer = momentLocalizer(moment);

  const years = getAllYears();
  const months = getAllMonths();

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [docType, setDocType] = useState("monthly");
  const [monthlyHrs, setMonthlyHrs] = useState("");
  const [chkMonthlyHrs, setChkMonthlyHrs] = useState("");
  const [monthlyDays, setMonthlyDays] = useState("");
  const [documents, setDocuments] = useState([]);
  const [wrkHrShowHide, setWrkHrShowHide] = useState(false);
  const [dtObj, setDtObj] = useState(null);
  const [currentWfTask, setCurrentWfTask] = useState(null);
  const [saveTmWithDocs, setSaveTmWithDocs] = useState(false);
  const [saveBtnProccess, setSaveBtnProccess] = useState(false);

  const tmLogAddedStatusSelector = useSelector(getTmLogAddedStatus);

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (allTimeLogs.length > 0) {
      const events = [];
      allTimeLogs.map((t) => {
        if (t.workingHrs && t.workingHrs !== "") {
          const wt = JSON.parse(t.workingHrs);
          wt.map((w) => {
            const ev = { start: w.start, end: w.start, title: w.title };
            events.push(ev);
          });
        }
      });
      setAllEvents(events);
    }
  }, [allTimeLogs])

  useEffect(() => {
    if (selectedObj) {
      setSelectedYear(selectedObj.year);
      setSelectedMonth(selectedObj.month);
      setDocType(selectedObj.docUploadType);
      setDocuments([]);
      const dt = new Date(selectedObj.year, selectedObj.month - 1, 1);
      setSelectedDate(dt);
    }
    else {
      const dt = new Date();
      const year = dt.getFullYear();
      const month = dt.getMonth();
      setSelectedYear(year);
      setSelectedMonth(month + 1);
      setSelectedDate(dt);
      setChkMonthlyHrs("");
      setDocType("monthly");
      setMonthlyHrs("");
      setMonthlyDays("");
      setDocuments([]);
    }
  }, [selectedObj]);

  const handleOnChange = ({ currentTarget }) => {
    const id = currentTarget.id.split("_")[1];
    const name = currentTarget.id.split("_")[0];
    const { timeLogs } = this.state;
    let obj = timeLogs.filter((x) => x.id === parseInt(id))[0];
    const index = timeLogs.findIndex((x) => x.id === parseInt(id));
    obj[name] = currentTarget.value;
    timeLogs[index] = obj;
    //this.setState({ timeLogs });
  };

  const doSubmit = (type) => {
    setSaveBtnProccess(true);
    if (type === "reset") setSelectedYear(null);
    const selectedTimeLog = allTimeLogs.filter((x) =>
      x.year === parseInt(selectedYear) && x.month === parseInt(selectedMonth)
    );
    if (selectedTimeLog.length > 0) {
      checkInvoiceStatus(selectedTimeLog);
    }
    else {
      saveTmLogs();
    }

  };

  const checkInvoiceStatus = (selectedTimeLog) => {
    const tmLog = selectedTimeLog[0];
    const invType = ["recruiter", "subcontractor"];
    const checkApproved = timeLogInvoices.filter((x) =>
      x.timeLogId === tmLog.id &&
      // x.invoiceType === invType &&
      invType.includes(x.invoiceType) &&
      x.status === true
    );
    if (checkApproved.length > 0){
      getAlert("", "Invoice already approved", "error");
    }else{
      saveTmLogs();
    }
  
  }

  const saveTmLogs = async () => {
    const result = await getConfirmAlert("Warning", "Are you sure you have entered the correct hours for the Week/Month?", "warning");
    if (result.isConfirmed) {
      if (documents.length > 0) saveTmDocs();
      else saveTimeLogs(0);
    }
  }

  const saveTmDocs = () => {
    const frmData = new FormData();
    frmData.append("message", "Time Sheet");
    frmData.append("workflowTask", JSON.stringify(currentWfTask));
    frmData.append("userId", currentUser.id);
    frmData.append("docType", docType + "_time_sheet");
    documents.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    //dispatch(uploadAppDocuments(frmData));
    setSaveTmWithDocs(true);
  }

  const saveTimeLogs = (docId) => {
    const filterEvents = allEvents.filter((x) => {
      const dt = new Date(x.start);
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      if (parseInt(selectedYear) == year && parseInt(selectedMonth) == month) {
        return x;
      }
    });
    const tmObj = {};
    if (chkMonthlyHrs === "monthly" && parseFloat(monthlyHrs) > 0) {
      let selMonth = selectedMonth;
      let selDays = monthlyDays;
      if (selectedMonth < 10) selMonth = "0" + selMonth;
      if (selDays === "" || !selDays) selDays = 0;
      const newEvents = [{
        start: `${selectedYear}-${selMonth}-01T05:00:00.000Z`,
        end: `${selectedYear}-${selMonth}-02T05:00:00.000Z`,
        title: monthlyHrs,
        type: "m",
        days: selDays,
      }];
      tmObj["workingHrs"] = JSON.stringify(newEvents);
    } else {
      tmObj["workingHrs"] = JSON.stringify(filterEvents);
      if (filterEvents.length === 0) tmObj["workingHrs"] = null;
    }
    tmObj["candidateId"] = candidateObj.performerid;
    tmObj["subcontractorId"] = candidateObj.validId;
    tmObj["docId"] = docId;
    tmObj["docUploadType"] = docType;
    tmObj["year"] = selectedYear;
    tmObj["month"] = selectedMonth;

    if (selectedObj?.id > 0)
      tmObj["id"] = selectedObj.id;

    dispatch(addNewTmLog(tmObj));
  };

  useEffect(() => {
    if (tmLogAddedStatusSelector) {
      getAlert("", "Updated Successfully", "success");
      setSaveBtnProccess(false);
      handleShowHide("save");
    }
  }, [tmLogAddedStatusSelector])


  const handleSelect = (wrkHr) => {
    if (dtObj) {
      const { start, end } = dtObj;
      const dt = new Date(start);
      const chk = allEvents.filter((x) => {
        const evDt = new Date(x.start);
        if (dt.getFullYear() === evDt.getFullYear() &&
          dt.getMonth() === evDt.getMonth() &&
          dt.getDate() === evDt.getDate()) {
          return x;
        }
      });
      if (chk.length > 0) {
        allEvents.filter((x) => {
          const evDt = new Date(x.start);
          if (dt.getFullYear() === evDt.getFullYear() &&
            dt.getMonth() === evDt.getMonth() &&
            dt.getDate() !== evDt.getDate()) {
            return x;
          }
        });

        const obj = chk[0];
        obj.title = wrkHr;
        const events = [...allEvents];
        setAllEvents(events);
        setWrkHrShowHide(false);
        setDtObj(null);
      } else {
        const obj = { start, end, title: wrkHr }
        const events = [...allEvents, obj];
        setAllEvents(events);
        setWrkHrShowHide(false);
        setDtObj(null);
      }
    }
  };

  const handleeEditSelect = (wrkHr) => {
    if (dtObj) {
      const events = [...allEvents];
      const { start, end, title } = dtObj;
      const index = events.findIndex(
        (x) => x.start === start && x.end === end && x.title === title
      );
      const obj = events[index];
      obj.title = wrkHr;
      events[index] = obj;
      setAllEvents(events);
    }
  };

  const handleYearChange = ({ currentTarget }) => {
    setSelectedYear(currentTarget.value);
    const newDt = new Date(currentTarget.value, selectedMonth - 1, 1);
    setSelectedDate(newDt);
  };

  const handleMonthChange = ({ currentTarget }) => {
    setSelectedMonth(currentTarget.value);
    const newDt = new Date(selectedYear, currentTarget.value - 1, 1);
    setSelectedDate(newDt);
  };

  const handleDateChanged = (date) => {
    const dt = new Date(date);
    const year = dt.getFullYear();
    const month = dt.getMonth();
    const cyear = new Date().getFullYear();
    const cmonth = new Date().getMonth();

    const findYear = years.filter(x => x.name === year);

    if (year === cyear && month <= cmonth && findYear.length > 0) {
      setSelectedYear(year);
      setSelectedMonth(month + 1);
      setSelectedDate(dt);
    }
    else if (year < cyear && findYear.length > 0) {
      setSelectedYear(year);
      setSelectedMonth(month + 1);
      setSelectedDate(dt);
    }
  };


  const onFileChange = async (event) => {
    const docs = [...documents];
    for (let file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  const handleWrkHrShowHide = (obj) => {
    const dt = new Date(obj.start);
    const cdt = new Date();
    if (dt <= cdt && selectedMonth === dt.getMonth() + 1) {
      setWrkHrShowHide(!wrkHrShowHide);
      setDtObj(obj);
    } else if (obj === "") {
      setWrkHrShowHide(false);
      setDtObj(null);
    }
  };

  const handleChkMonthly = ({ target }) => {
    if (target.checked) {
      setChkMonthlyHrs("monthly");
    } else {
      setChkMonthlyHrs("");
    }
  };

  const handleReset = async () => {
    const result = await getConfirmAlert("Warning", "Are you sure you want to Reset the entered hours for the week(s)/month?", "warning");
    if (result.isConfirmed) {
      if (selectedObj?.id > 0) {
        setSelectedYear(selectedObj.year);
        setSelectedMonth(selectedObj.month);
        setDocType(selectedObj.docUploadType);
        setDocuments([]);
        const dt = new Date(selectedObj.year, selectedObj.month - 1, 1);
        setSelectedDate(dt);
      }
      else {
        const dt = new Date();
        const year = dt.getFullYear();
        const month = dt.getMonth();
        setSelectedYear(year);
        setSelectedMonth(month + 1);
        setSelectedDate(dt);
        setChkMonthlyHrs("");
        setDocType("monthly");
        setMonthlyHrs("");
        setMonthlyDays("");
        setDocuments([]);
      }
      const events = [];
      allTimeLogs.map((t) => {
        if (t.workingHrs !== null && t.workingHrs !== "") {
          const wt = JSON.parse(t.workingHrs);
          wt.map((w) => {
            const ev = { start: w.start, end: w.start, title: w.title };
            events.push(ev);
          });
        }
      });
      setAllEvents(events);
      getAlert("", "Reset Successfully", "success");
    }
  };

  const handleclosepopup =()=>{
    handleShowHide("")
  }

  let filterMonths = months.filter(x => x.id <= (new Date().getMonth() + 1));
  if (selectedYear < (new Date().getFullYear()))
    filterMonths = months;

  return (
    <>
      <Modal show={tmShowHide} size="xl">
        <Modal.Header closeButton onHide={() => handleShowHide("")}>
          <Modal.Title>Add Time Log</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-6 form-group">

              <select
                name="year"
                id="year"
                className="form-control"
                onChange={handleYearChange}
                value={selectedYear}
              >
                <option value="">Select Year</option>
                {years.filter(x => x.name <= (new Date).getFullYear()).map((y) => (
                  <option value={y.name}>{y.name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6 form-group">

              <select
                name="month"
                id="month"
                className="form-control"
                onChange={handleMonthChange}
                value={selectedMonth}
              >
                <option value="">Select Month</option>
                {filterMonths.map((m) => (
                  <option value={m.id}>{m.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md-12">
              <div className="custom-control custom-checkbox mb-3">
                <input
                  name="chkMonthlyHrs"
                  className="custom-control-input"
                  id="chkMonthlyHrs"
                  type="checkbox"
                  onChange={handleChkMonthly}
                  value="monHrs"
                />
                <label
                  className="custom-control-label mr-2"
                  htmlFor="chkMonthlyHrs"
                >
                  Check Monthly Hours
                </label>
              </div>
            </div>
            {chkMonthlyHrs === "monthly" && <>
              <div className="col-md-6 form-group">
                <label htmlFor="monthlyHrs" className="form-control-label">
                  Enter Monthly Hours
                </label>
                <input type="number"
                  className="form-control"
                  id="monthlyHrs"
                  name="monthlyHrs"
                  value={monthlyHrs}
                  placeholder="Enter Monthly Hours"
                  onChange={({ currentTarget }) => setMonthlyHrs(currentTarget.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="monthlyDays" className="form-control-label">
                  Enter Total Days
                </label>
                <input type="number"
                  className="form-control"
                  id="monthlyDays"
                  name="monthlyDays"
                  value={monthlyDays}
                  placeholder="Enter Total Days"
                  onChange={({ currentTarget }) => setMonthlyHrs(currentTarget.value)}
                />

              </div> </>}
            <div className="col-md-12" style={{ height: "350px" }}>
              <Calendar
                selectable
                localizer={localizer}
                events={allEvents}
                date={selectedDate}
                views={["month"]} // "week", "work_week"
                onSelectEvent={handleWrkHrShowHide}
                onSelectSlot={handleWrkHrShowHide}
                onNavigate={handleDateChanged}

              //onView={(view) => setState({ selectedView: view })}
              />
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleclosepopup()}>
            <i class="fa fa-times" aria-hidden="true"></i> Close
          </button>
          {viewType !== "invoice" && (
            <>
              <button className="btn btn-sm btn-outline-default"
                onClick={handleReset}>
                <i class="fa fa-refresh" aria-hidden="true"></i> Reset
              </button>
              <button
                className="btn btn-sm btn-outline-default"
                onClick={() => doSubmit("save")}
              >
                <i class="fa fa-check" aria-hidden="true"></i> Save
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <AddWorkingHrs
        handleShowHide={handleWrkHrShowHide}
        showHide={wrkHrShowHide}
        selectedObj={dtObj}
        handleSelect={handleSelect}
        handleeEditSelect={handleeEditSelect}
        allEvents={allEvents}
      ></AddWorkingHrs>
    </>
  );
}

export default AddNewTimeLog;
