import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "complianceReports",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        subcontractors: [],
        recruiters: [],
        w2Candidates: [],
        tenNintyCandidates: [],
        complianceAppStartedData: null,
        currentComplianceWfTask: null,
        complianceBprmForm: null,
    },
    reducers: {
        subcontractorsComplicanceReceived: (complianceReports, action) => {
            complianceReports.subcontractors = action.payload;
        },
        recruitersComplicanceReceived: (complianceReports, action) => {
            complianceReports.recruiters = action.payload;
        },
        w2CandidatesComplicanceReceived: (complianceReports, action) => {
            complianceReports.w2Candidates = action.payload;
        },
        tenNintyCandidatesComplicanceReceived: (complianceReports, action) => {
            complianceReports.tenNintyCandidates = action.payload;
        },
        complianceAppStartedDataReceived: (complianceReports, action) => {
            complianceReports.complianceAppStartedData = action.payload;
        },
        currentComplianceWfTaskReceived: (complianceReports, action) => {
            complianceReports.currentComplianceWfTask = action.payload;
        },
        complianceBprmFormReceived: (complianceReports, action) => {
            complianceReports.complianceBprmForm = action.payload;
        },
    }
});

export const { subcontractorsComplicanceReceived, recruitersComplicanceReceived,
    w2CandidatesComplicanceReceived, tenNintyCandidatesComplicanceReceived,
    complianceAppStartedDataReceived, currentComplianceWfTaskReceived, complianceBprmFormReceived } = slice.actions;
export default slice.reducer;

export const loadSubcontractorsComplianceReports = (year) => apiCallBegan({
    url: config.GetSubcontractorCompliance + year,
    onSuccess: subcontractorsComplicanceReceived.type
});

export const loadRecruitersComplianceReports = (year) => apiCallBegan({
    url: config.GetRecruiterCompliance + year,
    onSuccess: recruitersComplicanceReceived.type
});

export const loadW2CandidatesComplianceReports = (year) => apiCallBegan({
    url: config.GetCandidateCompliance + year + '&entityType=W2 Basis',
    onSuccess: w2CandidatesComplicanceReceived.type
});

export const loadTenNintyComplianceReports = (year) => apiCallBegan({
    url: config.GetCandidateCompliance + year + '&entityType=1099 Basis',
    onSuccess: tenNintyCandidatesComplicanceReceived.type
});

export const startComplianceApplication = (data) => apiCallBegan({
    url: config.StartNewApplication,
    method: "post",
    data: data,
    onSuccess: complianceAppStartedDataReceived.type
});

export const loadComplianceCurrentWorkflowTask = (data) => apiCallBegan({
    url: config.GetCurrentWorkflowTask,
    method: "post",
    data: data,
    onSuccess: currentComplianceWfTaskReceived.type
});

export const loadComplianceBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: complianceBprmFormReceived.type
});

export const getSubcontractorsComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.subcontractors
);

export const getRecruitersComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.recruiters
);

export const getW2CandidateComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.w2Candidates
);

export const getTenNintyComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.tenNintyCandidates
);

export const getStartedComplianceAppData = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.complianceAppStartedData
);

export const getCurrentComplianceWorkflowTask = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.currentComplianceWfTask
);

export const getComplianceBprmForm = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.complianceBprmForm
);



