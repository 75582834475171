import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import http from "../services/HttpService";
import config from "../config.json";
import ViewForm from "../components/PortalForms/ViewForm";
import AddEditNotes from "../components/OMCRegistry/AddEditNotes";
import { useDispatch, useSelector } from "react-redux";
import { getAppFormLibrary, loadAppLibraryForms } from "../app/store/forms";
import { getDocuments, loadDocuments } from "../app/store/documents";
import { getFieldData } from "../services/appService";
import useDocDownload from "../app/hooks/useDocDownload";
import ViewFormModal from "./ViewFormModal";

const AchInformation = (props) => {
  const { workflowTask, item, app, setViewBprmFormShowHide } = props;

  const [formsLib, setFormsLib] = useState([]);
  const [appDocuments, setAppDocuments] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [achFormShowHide, setAchFormShowHide] = useState(false);
  const [noteShowHide, setNoteShowHide] = useState(false);

  const appFormLibrarySelector = useSelector(getAppFormLibrary);
  const documentsSelector = useSelector(getDocuments);

  const [handleDownloadFile] = useDocDownload()

  const dispatch = useDispatch();

  useEffect(() => {
    if (workflowTask?.ciid) {
      dispatch(loadAppLibraryForms(workflowTask.ciid));
      dispatch(loadDocuments(workflowTask.ciid));
    }
  }, []);

  useEffect(() => {
    if (appFormLibrarySelector.length > 0) {
      const achForms = appFormLibrarySelector.filter(x => x.formType === "ACH Information");
      setFormsLib(achForms);
    }
  }, [appFormLibrarySelector]);

  useEffect(() => {
    setAppDocuments(documentsSelector);
  }, [documentsSelector]);

  const handleFormShowHide = (f) => {
    setSelectedForm(f);
    setAchFormShowHide(!achFormShowHide);
    setViewBprmFormShowHide(!achFormShowHide);
  };

  const handleNoteShowHide = (type, obj) => {
    if (type === "save") {
      setSelectedForm(null)
      setNoteShowHide(false);
      if (workflowTask?.ciid > 0)
        dispatch(loadAppLibraryForms(workflowTask.ciid));
    } else {
      setSelectedForm(obj);
      setNoteShowHide(!noteShowHide);
    }
  };

  const handleVoidCheck = (item) => {
    const docs = appDocuments.filter((x) => x.uploadType === `ach_${item.id}`);
    if (docs.length === 0)
      return <span> Pending </span>;

    return <>
      <button
        className="btn btn-link btn-sm p-0 text-underline"
        title="Download Document"
        onClick={() => handleDownloadFile(docs[0])}
      >
        {docs[0].docName}
      </button>
      <br />
      {docs[0].remarks}
    </>

  }

  let candidateObj = "";
  if (item) {
    candidateObj = {
      performerid: item?.id,
    };
  }
  return (
    <>
      <table className="table align-items-center table-flush table-hover table-striped border">
        <thead className="thead-light">
          <tr>
            <th>S.No.</th>
            <th>ACH Information</th>
            <th>Last Updated</th>
            <th>View Form</th>
            <th>Void Check</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {formsLib.map((item, key) => (
            <tr>
              <td>{key + 1}</td>
              <td>
                Bank Name :{" "}
                {getFieldData(
                  app?.applicationData,
                  "achinfo",
                  "Bank_Name"
                )}
                <br />
                Routing Number :{" "}
                {getFieldData(
                  app?.applicationData,
                  "achinfo",
                  "Routing"
                )}
                <br />
                Acccount Number :{" "}
                {getFieldData(
                  app?.applicationData,
                  "achinfo",
                  "Account_No"
                )}
              </td>
              <td>
                {item.lastUpdate && <>
                  <Moment format="MMM Do YYYY">{item.lastUpdate}</Moment>
                  <br />
                  <Moment format="h:mm a">{item.lastUpdate}</Moment> </>}
              </td>
              <td>
                <button
                  className="btn btn-link btn-sm p-0 text-underline"
                  onClick={() => handleFormShowHide(item)}
                >
                  View
                </button>
                <br />
                {item.allowEdit === 0 && (
                  <span>
                    Edit Locked
                  </span>
                )}
                {item.allowEdit === 2 && (
                  <>
                    <span>Edit Request Received</span>
                    <br />
                    {item.editReqDateTime && <Moment format="MMM Do YYYY, h:mm a">
                      {item.editReqDateTime}
                    </Moment>}

                    <br />
                    {item.editRemarks}
                  </>
                )}
              </td>
              <td>
                {handleVoidCheck(item)}
              </td>
              <td>
                {(item.allowEdit === 0 || item.allowEdit === 2) && (
                  <button
                    className="btn btn-link btn-sm p-0 text-underline"
                    onClick={() => handleNoteShowHide("", item)}
                  >
                    Edit Allow
                  </button>
                )}
                {item.allowEdit === 1 && (
                  <>
                    <span>
                      Edit Allowed
                    </span>
                    <br />
                    {item.allowEditDateTime && <Moment format="MMM Do YYYY, h:mm a">
                      {item.allowEditDateTime}
                    </Moment>}
                    <br />
                    {item.editRemarks}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ViewFormModal
        viewFormShowHide={achFormShowHide}
        handleViewForm={handleFormShowHide}
        workflowTask={workflowTask}
        selectedForm={selectedForm}
      ></ViewFormModal>

      <AddEditNotes
        noteShowHide={noteShowHide}
        handleNoteShowHide={handleNoteShowHide}
        notesType="formEditReq"
        candidateObj={candidateObj}
        selectedObj={selectedForm}
      ></AddEditNotes>
    </>
  );
}

export default AchInformation;
