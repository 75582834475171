import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "recruiters",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        filterList: [],
        performers: [],
        reminder: "",
        addNewId: 0,
        addedUser: null,
        recruiterBprmForm: null
    },
    reducers: {
        recruiterAdded: (recruiters, action) => {
            recruiters.addNewId = action.payload;
        },
        recruiterUserAdded: (recruiters, action) => {
            recruiters.addedUser = action.payload;
        },
        recruitersReceived: (recruiters, action) => {
            recruiters.list = action.payload;
            recruiters.reminder = "";
            recruiters.loading = false;
            recruiters.addNewId = 0;
            recruiters.addedUser = null;
        },
        filterRecruitersReceived: (recruiters, action) => {
            recruiters.filterList = action.payload;
            recruiters.reminder = "";
            recruiters.loading = false;
            recruiters.addNewId = 0;
            recruiters.addedUser = null;
        },
        reminderSent: (recruiters, action) => {
            recruiters.reminder = action.payload;
        },
        recruiterPerformersReceived: (recruiters, action) => {
            recruiters.performers = action.payload;
            recruiters.loading = false;
        },
        recruiterPerformerAdded: (recruiters, action) => {
            recruiters.loading = true;
        },
        recruiterBprmFormReceived: (recruiters, action) => {
            recruiters.recruiterBprmForm = action.payload;
        }
    }
});

export const { recruiterAdded, recruitersReceived,
    filterRecruitersReceived, reminderSent, recruiterUserAdded,
    recruiterPerformersReceived, recruiterPerformerAdded, recruiterBprmFormReceived } = slice.actions;

export default slice.reducer;

export const loadRecruiters = (pageSize, currentPage, type) => apiCallBegan({
    url: config.SetupUsers.GetPartners + pageSize + "&pageNumber=" + currentPage + "&type=" + type,
    onSuccess: recruitersReceived.type
});

export const loadRecruitersByFilter = (data) => apiCallBegan({
    url: config.GetPartnersByFilter,
    method: "post",
    data: data,
    onSuccess: filterRecruitersReceived.type
});

export const addNewRecruiter = (data) => apiCallBegan({
    url: config.AddNewPartner,
    method: "post",
    data: data,
    onSuccess: recruiterAdded.type
})

export const SendReminder = (id) => apiCallBegan({
    url: config.SendRemainderToApplicant + id,
    onSuccess: reminderSent.type
});

export const loadRecruiterPerformers = (id) => apiCallBegan({
    url: `${config.SetupUsers.GetPartnerPerformers}${id}`,
    onSuccess: recruiterPerformersReceived.type
});

export const AddRecruiterPerformer = (data) => apiCallBegan({
    url: config.SetupUsers.AddPartnerPerformer,
    method: "post",
    data: data,
    onSuccess: recruiterPerformerAdded.type
});

export const addNewRecruiterUser = (data) => apiCallBegan({
    url: config.Registration,
    method: "post",
    data: data,
    onSuccess: recruiterUserAdded.type
})

export const loadRecruiterBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: recruiterBprmFormReceived.type
});

export const getAllRecruiters = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.list
);

export const getFilterAllRecruiters = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.filterList
);

export const getReminderStatus = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.reminder
)

export const getAddedRecPerfromerStatus = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.loading
)

export const getRecruiterPerformers = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.performers
);

export const getNewRecruiterId = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.addNewId
);

export const getNewRecruiterUser = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.addedUser
);

export const getRecruiterBprmForm = createSelector(
    state => state.entities.recruiters,
    recruiters => recruiters.recruiterBprmForm
);
