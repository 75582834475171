import React, { useEffect, useState } from "react";
import { getConfirmAlert } from "../../../services/appAlerts";

export default function Button(props) {
    const { comp,
        btnProcessStatus,
        isDisabled,
        setIsDisabled,
        isEdit,
        validate,
        doSubmit,
        handleClose,
        size = "btn-sm"
    } = props;
    const [buttonName, setButtonName] = useState("")
    if (!comp) return;

    const closeBtn = ["close", "exit", "back"];
    const saveBtn = ["submit", "saveAndContinue", "saveAndExit", "save", "taskCompleted", "checkStatus", "home"];
    const updBtn = ["update", "updateAndContinue", "updateAndExit"];

    const handleSubformModalShowHide = (name) => {
        const modelId = name.split("-");
        if (modelId.length > 1)
            this.setState({
                [modelId[0]]: !this.state[modelId[0]],
                subformItemIndex: modelId[1],
            });
        else this.setState({ [name]: !this.state[name], subformItemIndex: -1 });
    };

    const handleEditForm = async () => {
        const result = await getConfirmAlert("Are you sure?", "You want to Edit this Form", "warning");
        if (result.isConfirmed)
            setIsDisabled(false)
    };

    const handleSubmit = async (e) => {
        const targetval = e.currentTarget
        e.preventDefault();
        setButtonName(targetval.name);
        const result = await getConfirmAlert("", "Are you sure you have completed this task? Clicking on this Button will move the task to next task in workflow.", "warning");
        if (result.isConfirmed) {
            doSubmit(targetval);
        }
    };


    if (comp.buttonType?.type === "subform") {
        return (
            <button
                type="button"
                onClick={() => handleSubformModalShowHide("showHide" + comp.subform)}
                className={`${comp?.cssclass ? comp.style + " " + comp.cssclass : comp.style} ${size}`}
                disabled={isDisabled}
            >
                {comp?.formIcons && <i className={`${comp.formIcons} mr-1`} aria-hidden="true"></i>}
                {comp.label}
            </button>
        );
    }

    if (comp.buttonType.type === "editForm") {
        return (
            <button
                type="button"
                name={comp.buttonType.type}
                data-process={comp?.formProcess ? 0 : comp.formProcess}
                className={`form-group ${comp?.cssclass ? comp.style + " " + comp.cssclass : comp.style} ${size}`}
                onClick={handleEditForm}
                aria-label={comp.label}
            >
                {comp?.formIcons && <i className={`${comp.formIcons} mr-2`} aria-hidden="true"></i>}
                {comp.label}
            </button>
        );
    }

    if (comp.buttonType.type === "help") {
        return (
            <button
                type="button"
                name={comp.buttonType.type}
                data-process={comp?.formProcess ? 0 : comp.formProcess}
                className={`${comp?.cssclass ? comp.style + " " + comp.cssclass : comp.style} ${size}`}
                //disabled={this.validate()}
                //onClick={this.handleSubmit}
                aria-lable={comp.label}
            >
                {comp?.formIcons && <i className={`${comp.formIcons} mr-2`} aria-hidden="true"></i>}
                {comp.label}
            </button>
        );
    }

    if (closeBtn.indexOf(comp.buttonType.type) > -1) {
        return (
            <button
                type="button"
                name={comp.buttonType.type}
                data-process={comp?.formProcess ? 0 : comp.formProcess}
                className={`${comp?.cssclass ? comp.style + " " + comp.cssclass : comp.style} ${size}`}
                onClick={handleClose}
                aria-label={comp.label}
            >
                {comp?.formIcons && <i className={`${comp.formIcons} mr-2`} aria-hidden="true"></i>}
                {comp.label}
            </button>
        );
    }

    if (saveBtn.indexOf(comp.buttonType.type) > -1) {
        return (
            (!isEdit || isEdit === true || isDisabled) && (
                <button
                    type="button"
                    name={comp.buttonType.type}
                    data-process={comp?.formProcess ? 0 : comp.formProcess}
                    className={`${comp?.cssclass ? comp.style + " " + comp.cssclass : comp.style} ${size}`}
                    disabled={btnProcessStatus || isDisabled}
                    onClick={handleSubmit}
                    aria-label={comp.label}
                >
                    {btnProcessStatus && buttonName === comp.buttonType.type && <i className="fa fa-spinner fa-spin mr-2"></i>}
                    {!btnProcessStatus && comp?.formIcons && <i className={`${comp.formIcons} mr-2`} aria-hidden="true"></i>}
                    {comp.label}
                </button>
            )
        );
    }

    if (updBtn.indexOf(comp.buttonType.type) > -1) {
        return (!isEdit && isEdit === false && !isDisabled && (
            <button
                type="button"
                name={comp.buttonType.type}
                data-process={comp?.formProcess ? 0 : comp.formProcess}
                className={`${comp?.cssclass ? comp.style + " " + comp.cssclass : comp.style} ${size}`}
                disabled={btnProcessStatus || validate()}
                onClick={handleSubmit}
                aria-lable={comp.label}
            >
                {btnProcessStatus && <i className="fa fa-spinner fa-spin mr-2"></i>}
                {!btnProcessStatus && comp?.formIcons && <i className={`${comp.formIcons} mr-2`} aria-hidden="true"></i>}
                {comp.label}
            </button>
        )
        );
    }
}