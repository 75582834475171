import React, { Component, useEffect, useState } from "react";
import Form from "../comman/form";
import { Modal, Button } from "react-bootstrap";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import UploadDocument from "../comman/UploadDocument";
import http from "../services/HttpService";
import auth, { getCurrentUser } from "../services/authService";
import config from "../config.json";
import BprmForm from "../comman/BprmForm";
import Progress from "../comman/Progress";
import WorkflowElement from "../app/components/WorkflowElement";
//import { getStartedAppData, startNewApplication } from "../app/store/application";
//import { getCurrentWorkflowTask, loadCurrentWorkflowTask } from "../app/store/WorkflowEngine";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplianceBprmForm, getCurrentComplianceWorkflowTask,
  getStartedComplianceAppData, loadComplianceBprmForm,
  loadComplianceCurrentWorkflowTask, startComplianceApplication
} from "../app/store/complianceReports";

const AddComplaince = (props) => {
  const { showHideCompliance, handleShowHideCompliance, type, selectedObj } = props;

  const [workflowTask, setWorkflowTask] = useState(null);
  const [btnProcess, setBtnProcess] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [workflowFormData, setWorkflowFormData] = useState(null);
  const [documentInfo, setDocumentInfo] = useState({ docType: "compliance", updType: "compliance", message: "Compliance Document" })
  const [wfForm, setWfForm] = useState(null);

  const compliacneAppStartedDataSelector = useSelector(getStartedComplianceAppData);
  const complianceCurrentWorkflowTaskSelector = useSelector(getCurrentComplianceWorkflowTask);
  const complianceBprmFormSelector = useSelector(getComplianceBprmForm);

  const currentUser = getCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedObj) {
      const data = {};
      data["compliance.Id"] = selectedObj.id;
      data["compliance.Name"] = selectedObj.name;
      data["compliance.Year"] = selectedObj.year;
      data["compliance.Total_Invoice_Amt"] = selectedObj.totalInvoiceAmount;
      data["compliance.Total_Paid_Amt"] = selectedObj.totalPaidAmount;
      data["compliance.Total_Balance_Amt"] = selectedObj.totalDueAmount;
      if (type === "w2")
        data["compliance.Notes"] =
          "Attached is your previous year's W2 Wage and Tax statement.";
      else
        data["compliance.Notes"] =
          "Attached is your previous year's 1099-NEC statement.";
      setWorkflowFormData(data);
    }
  }, [selectedObj])

  const handleRunApplication = () => {
    setBtnProcess(true);
    const newApplication = {
      workflowId: config.workflowsIds.compliance.subcontractor,
      performerId: parseInt(currentUser.id),
      applicationId: 1,
    };
    dispatch(startComplianceApplication(newApplication));
  };

  useEffect(() => {
    if (compliacneAppStartedDataSelector?.ciid > 0 && reqCount === 0) {
      setTimeout(() => {
        dispatch(loadComplianceCurrentWorkflowTask(compliacneAppStartedDataSelector));
        setReqCount(1);
      }, 3000);
    }
  }, [compliacneAppStartedDataSelector]);

  useEffect(() => {
    if (complianceCurrentWorkflowTaskSelector?.formId === null && reqCount <= 3) {
      setTimeout(async () => {
        dispatch(loadComplianceCurrentWorkflowTask(compliacneAppStartedDataSelector));
        const rqCount = reqCount + 1;
        setReqCount(rqCount)
      }, 3000);
    }
    else if (complianceCurrentWorkflowTaskSelector?.wiid > 0) {
      setWorkflowTask(complianceCurrentWorkflowTaskSelector);
      setBtnProcess(false);
      dispatch(loadComplianceBprmForm(complianceCurrentWorkflowTaskSelector.formId));
    }
    else if (reqCount >= 3) {
      handleShowHideCompliance("", "save");
    }
  }, [complianceCurrentWorkflowTaskSelector]);

  const handleNextTask = () => {
    setTimeout(() => {
      dispatch(loadComplianceCurrentWorkflowTask(workflowTask));
      setReqCount(1);
    }, 3000);
  };

  useEffect(() => {
    setWfForm(complianceBprmFormSelector);
  }, [complianceBprmFormSelector])

  let notes = ""
  if (type === "w2")
    notes =
      "Attached is your previous year's W2 Wage and Tax statement.";
  else
    notes =
      "Attached is your previous year's 1099-NEC statement.";

  return (
    <React.Fragment>
      <Modal show={showHideCompliance} size="lg">
        <Modal.Header>
          <Modal.Title>
            {type === "w2" ? "Upload W2 Statement" : "Upload 1099-NEC"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {!wfForm && <div className="row">

            <div className="col-md-12 form-group">
              {notes}
              <button className="btn btn-sm btn-primary ml-2" disabled={btnProcess} onClick={() => handleRunApplication()}>
                {btnProcess && <i className="fa fa-spinner fa-spin mr-2"></i>}
                Upload Document
              </button>
            </div>

          </div>}

          {wfForm &&
            <div className="col-md-12">
              <WorkflowElement
                wfTask={workflowTask}
                wfForm={wfForm}
                handleNextTask={handleNextTask}
                workflowFormData={workflowFormData}
                documentInfo={documentInfo} />
            </div>
          }


        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => handleShowHideCompliance("", "")}
          >
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default AddComplaince;
