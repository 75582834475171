import React, { Component, useEffect, useState } from "react";
import Form from "../comman/form";
import { Modal, Button } from "react-bootstrap";
import Joi from "joi-browser";
import http from "../services/HttpService";
import config from "../config.json";
import Moment from "react-moment";
import { getAlert } from "../services/appAlerts";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerPerformers, getPartnerUsers, getRegisteredPartners, loadPartnerPerformers, loadPartnerPerformersById, loadRegisteredPartners } from "../app/store/partner";
import { getAllRolesPerformers, getAllWorkfllowRoles, getRolePerformers, loadAllWorkflowRoles, loadAllWorkflowRolesPerformers, loadRolePerformers } from "../app/store/workflowRole";
import { getTaskAssignHistory, getTaskAssignStatus, loadTaskReAssignHistory, taskReAssign } from "../app/store/WorkflowEngine";

const TaskReAssignPopup = (props) => {
  const { selectedObj, reAssignShowHide, 
    handleReAssignModalShowHide,handleReAssignSubmit } = props;

  const performerTypes = [
    {
      id: "WP",
      name: "Main User",
    },
    {
      id: "PP",
      name: "Partner",
    },
  ];

  const state = {
    data: {},
    errors: {},
  };

  const [partners, setPartners] = useState([]);
  const [wfRoles, setWfRoles] = useState([]);
  const [partnerUsers, setPartnerUsers] = useState([]);
  const [wfUsers, setWfUsers] = useState([]);
  const [reAssignHistory, setReAssignHistory] = useState([]);
  const [performerType, setPerformerType] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [notes, setNotes] = useState("");

  const registeredPartnersSelector = useSelector(getRegisteredPartners);
  const taskAssignHistorySelector = useSelector(getTaskAssignHistory);
  const allWorkfllowRolesSelector = useSelector(getAllWorkfllowRoles);
  const rolePerformersSelector = useSelector(getRolePerformers);
  const partnerUsersSelector = useSelector(getPartnerUsers);
  const taskAssignStatusSelector = useSelector(getTaskAssignStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAllWorkflowRoles());
    dispatch(loadRegisteredPartners());
  }, []);

  useEffect(() => {
    if (selectedObj?.wiid) dispatch(loadTaskReAssignHistory(selectedObj.wiid));
  }, [selectedObj])

  useEffect(() => {
    if (taskAssignHistorySelector) setReAssignHistory(taskAssignHistorySelector);
  }, [taskAssignHistorySelector]);

  useEffect(() => {
    if (allWorkfllowRolesSelector) setWfRoles(allWorkfllowRolesSelector)
  }, [allWorkfllowRolesSelector])

  useEffect(() => {
    if (registeredPartnersSelector) setPartners(registeredPartnersSelector);
  }, [registeredPartnersSelector]);

  useEffect(() => {
    if (rolePerformersSelector){
      setWfUsers(rolePerformersSelector.filter((e)=> e.status === true));
    }
  }, [rolePerformersSelector]);

  useEffect(() => {
    if (partnerUsersSelector) setWfUsers(partnerUsersSelector);
  }, [partnerUsersSelector]);

  useEffect(() => {
    if (taskAssignStatusSelector) {
      setSelectedPartner("");
      setSelectedRole("");
      setSelectedUser("");
      setPerformerType("");
      setNotes("");
      getAlert("", "Re-Assigned Successfully", "success");
      if (selectedObj?.wiid) dispatch(loadTaskReAssignHistory(selectedObj.wiid));
      handleReAssignSubmit();
    }
  }, [taskAssignStatusSelector])

  const handleRoleChange = ({ currentTarget }) => {
    setSelectedRole(currentTarget.value);
    dispatch(loadRolePerformers(currentTarget.value));
  }

  const handePartnerChange = ({ currentTarget }) => {
    setSelectedPartner(currentTarget.value);
    dispatch(loadPartnerPerformersById(currentTarget.value));
  }

  const handleSubmit = async () => {
    const frmData = new FormData();
    frmData.append("performerType", performerType);
    frmData.append("selectedUser", selectedUser);
    frmData.append("notes", notes);
    frmData.append("workflowTask", JSON.stringify(selectedObj));
    dispatch(taskReAssign(frmData));
  };

  const btnDisabled = !selectedUser || !performerType;

  return reAssignShowHide && (
    <div className="col-md-12 border form-group">

      <div className="row">
        <div className="col-md-12 text-right mt-3">
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => handleReAssignModalShowHide("")}
          >
            <i className="fa fa-times mr-2" aria-hidden="true"></i>Close
          </button>
        </div>
        <div className="col-md-12 form-group">
          <label htmlFor="performerType" className="form-control-label">
            Select User Type
          </label>
          <select
            name="performerType"
            id="performerType"
            className="form-control"
            value={performerType}
            onChange={({ currentTarget }) => setPerformerType(currentTarget.value)}
          >
            <option value="">Select User Type</option>
            {performerTypes.map((perf) => (
              <option value={perf.id}>{perf.name}</option>
            ))}
          </select>

        </div>
        <div className="col-md-12 form-group">
          {performerType === "WP" && (
            <React.Fragment>
              <label
                htmlFor="selectedRole"
                className="form-control-label"
              >
                Select Role
              </label>
              <select
                className="form-control"
                id="selectedRole"
                name="selectedRole"
                value={selectedRole}
                onChange={handleRoleChange}
              >
                <option value="">Select Role</option>
                {wfRoles.map((p) => (
                  <option value={p.roleid}>{p.rolename}</option>
                ))}
              </select>
            </React.Fragment>
          )}

          {performerType === "PP" && (
            <React.Fragment>
              <label htmlFor="selectedPartner" className="form-control-label">
                Select Partner
              </label>
              <select
                name="selectedPartner"
                id="selectedPartner"
                className="form-control"
                value={selectedPartner}
                onChange={handePartnerChange}
              >
                <option value="">Select Partner</option>
                {partners.map((p) => (
                  <option value={p.fuid}>
                    {p.label +
                      ", Partner ID :" +
                      p.fuid +
                      ", Type : " +
                      p.partnerType}
                  </option>
                ))}
              </select>
            </React.Fragment>
          )}

        </div>
        {performerType &&
        <div className="col-md-12 form-group">
          <label
            htmlFor="selectedUser"
            className="form-control-label"
          >
            Select User
          </label>
          <select
            className="form-control"
            id="selectedUser"
            name="selectedUser"
            value={selectedUser}
            onChange={({ currentTarget }) => setSelectedUser(currentTarget.value)}
          >
            <option value="">Select User</option>
            {wfUsers.map((p) => (
              <option value={p.id}>
                {p.name}
              </option>
            ))}
          </select>
        </div> }
        <div className="col-md-12 form-group">
          <label htmlFor="notes" className="form-control-label">
            Notes
          </label>
          <textarea cols="3" rows="3" id="notes"
            className="form-control"
            placeholder="Notes"
            value={notes}
            onChange={({ currentTarget }) => setNotes(currentTarget.value)} />
        </div>
        <div className="col-md-12">
          <div className="table-responsive">
            <label htmlFor="" className="form-control-label">
              Task Routing History
            </label>
            <table className="table table-striped align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th>Assign Date & Time</th>
                  <th>From Performer</th>
                  <th>To Performer</th>
                  <th>Assigned By</th>
                  <th>Assign By Role</th>
                </tr>
              </thead>
              <tbody>
                {reAssignHistory.map((item) => (
                  <tr>
                    <td>
                      <Moment format="MMM Do YYYY, h:mm a">
                        {item.assignDateTime}
                      </Moment>
                    </td>
                    <td>{item.fromPerformerName}</td>
                    <td>{item.toPerformerName}</td>
                    <td>{item.createdBy}</td>
                    <td>{item.createdUserType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-12 text-center">
        <button
          className="btn btn-sm btn-outline-default mb-3"
          disabled={btnDisabled}
          onClick={handleSubmit}
        >
          <i class="fa fa-check" aria-hidden="true"></i> Save
        </button>
      </div>
    </div>
  );
}

export default TaskReAssignPopup;
