import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "subcontractors",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        filterList: [],
        performers: [],
        reminder: "",
        addNewId: 0,
        addedUser: null,
        validateDuplicateData: false,
        checkSubEmail: null,
        checkSubUsrEmail: null,
        checkSubUsrMobile: null,
        subcontractorBprmForm: null
    },
    reducers: {
        subcontractorAdded: (subcontractors, action) => {
            subcontractors.addNewId = action.payload;
            subcontractors.checkSubEmail = null;
            subcontractors.checkSubUsrEmail = null;
            subcontractors.checkSubUsrMobile = null;
        },
        subcontractorUserAdded: (subcontractors, action) => {
            subcontractors.addedUser = action.payload;
            subcontractors.checkSubEmail = null;
            subcontractors.checkSubUsrEmail = null;
            subcontractors.checkSubUsrMobile = null;
        },
        subcontractorsReceived: (subcontractors, action) => {
            subcontractors.list = action.payload;
            subcontractors.reminder = "";
            subcontractors.loading = false;
            subcontractors.addedUser = null;
            subcontractors.addNewId = 0;
        },
        filterSubcontractorsReceived: (subcontractors, action) => {
            subcontractors.filterList = action.payload;
            subcontractors.reminder = "";
            subcontractors.loading = false;
            subcontractors.addedUser = null;
            subcontractors.addNewId = 0;
        },
        reminderSent: (subcontractors, action) => {
            subcontractors.reminder = action.payload;
        },
        subcontractorPerformersReceived: (subcontractors, action) => {
            subcontractors.performers = action.payload;
            subcontractors.loading = false;
        },
        subcontractorPerformerAdded: (subcontractors, action) => {
            subcontractors.loading = true;
        },
        validatedDuplicateData: (subcontractors, action) => {
            subcontractors.validateDuplicateData = action.payload;
        },
        checkedSubEmail: (subcontractors, action) => {
            subcontractors.checkSubEmail = action.payload;
        },
        checkedSubUsrEmail: (subcontractors, action) => {
            subcontractors.checkSubUsrEmail = action.payload;
        },
        checkedSubUsrMobile: (subcontractors, action) => {
            subcontractors.checkSubUsrMobile = action.payload;
        },
        subcontractorBprmFormReceived: (subcontractors, action) => {
            subcontractors.subcontractorBprmForm = action.payload;
        },
    }
});

export const { subcontractorAdded, subcontractorsReceived,
    filterSubcontractorsReceived, reminderSent,
    subcontractorPerformersReceived, subcontractorPerformerAdded,
    subcontractorUserAdded, checkedSubEmail, checkedSubUsrEmail,
    checkedSubUsrMobile, validatedDuplicateData, subcontractorBprmFormReceived } = slice.actions;
export default slice.reducer;

export const loadSubcontractors = (pageSize, currentPage, type) => apiCallBegan({
    url: config.SetupUsers.GetPartners + pageSize + "&pageNumber=" + currentPage + "&type=" + type,
    onSuccess: subcontractorsReceived.type
});

export const loadSubcontractorsByFilter = (data) => apiCallBegan({
    url: config.GetPartnersByFilter,
    method: "post",
    data: data,
    onSuccess: filterSubcontractorsReceived.type
});

export const SendReminder = (id) => apiCallBegan({
    url: config.SendRemainderToApplicant + id,
    onSuccess: reminderSent.type
});

export const loadSubcontractorPerformers = (id) => apiCallBegan({
    url: `${config.SetupUsers.GetPartnerPerformers}${id}`,
    onSuccess: subcontractorPerformersReceived.type
});

export const addNewSubcontractor = (data) => apiCallBegan({
    url: config.AddNewPartner,
    method: "post",
    data: data,
    onSuccess: subcontractorAdded.type
})

export const addNewSubcontractorUser = (data) => apiCallBegan({
    url: config.Registration,
    method: "post",
    data: data,
    onSuccess: subcontractorUserAdded.type
});

export const AddSubcontractorPerformer = (data) => apiCallBegan({
    url: config.SetupUsers.AddPartnerPerformer,
    method: "post",
    data: data,
    onSuccess: subcontractorPerformerAdded.type
});

export const checkSubcontractorEmail = (email, datatype) => apiCallBegan({
    url: config.CheckExistingPartnerByEmail + email + "&type=" + datatype,
    onSuccess: checkedSubEmail.type
});

export const checkSubcontractorUsrEmail = (email, datatype) => apiCallBegan({
    url: config.CheckExistingPerformerByEmail + email + "&type=" + datatype,
    onSuccess: checkedSubUsrEmail.type
});

export const checkSubcontractorUsrMobile = (mobile, datatype) => apiCallBegan({
    url: config.CheckExistingPerformerByPhone + mobile + "&type=" + datatype,
    onSuccess: checkedSubUsrMobile.type
});

export const loadSubcontractorBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: subcontractorBprmFormReceived.type
});

export const getAllSubcontractors = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.list
);

export const getFilterAllSubcontractors = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.filterList
);

export const getReminderStatus = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.reminder
)

export const getAddedSubPerformerStatus = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.loading
)

export const getSubcontractorPerformers = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.performers
);

export const getNewSubcontractorId = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.addNewId
);

export const getNewSubcontractorUser = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.addedUser
);

export const getValidateDuplicateDataStatus = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.validateDuplicateData
);

export const getCheckSubEmailStatus = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.checkSubEmail
);

export const getCheckSubUsrEmailStatus = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.checkSubUsrEmail
);

export const getCheckSubUsrMobileStatus = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.checkSubUsrMobile
);

export const getSubcontractorBprmForm = createSelector(
    state => state.entities.subcontractors,
    subcontractors => subcontractors.subcontractorBprmForm
);