import React, { Component, useEffect, useState } from "react";
import PageHeader from "./PageHeader";
import http from "../services/HttpService";
import config from "../config.json";
import LocationDetails from "./LocationDetails";
import BrowserDetails from "./BrowserDetails";
import Pagination from "../comman/pagination";
import { paginate, calculateIndex } from "../utlis/paginate";
import LogsTable from "./LogsTable";
import PageSizeOptions from "./PageSizeOptions";
import InfoHeader from "../comman/infoHeader";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";
import { getLoginTrails, loadLoginTrails, loadLoginTrailsByFilter } from "../app/store/reports";

const MonitoringLogs = () => {

  const route = [{ url: "", name: "Security" }];

  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [rowsCount, setRowsCount] = useState(0);
  const [id, setId] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [country, setCountry] = useState("");
  const [dataType, setDataType] = useState("");

  const loginTrailsSelector = useSelector(getLoginTrails)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPageTitle("Monitoring Logs"));
    dispatch(setCurrentRoute(route));
  }, []);

  useEffect(() => {
    dispatch(loadLoginTrails(pageSize, currentPage));
  }, [])

  useEffect(() => {
    if (loginTrailsSelector?.isSuccess) {
      setLogs(loginTrailsSelector.data);
      setRowsCount(loginTrailsSelector.totalRecords);
    }
  }, [loginTrailsSelector])

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    if (dataType !== "filter")
      dispatch(loadLoginTrails(pageSize, page));
    else
      handleFilter(pageSize, page);
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    if (dataType !== "filter")
      dispatch(loadLoginTrails(currentTarget.value, currentPage));
    else
      handleFilter(currentTarget.value, currentPage);
  };

  const handleFilterCancel = () => {
    setPageSize(5);
    setCurrentPage(1);
    setStartIndex(0);
    setUserId("");
    setUserName("");
    setIpAddress("");
    setCountry("");
    setDataType("")
    dispatch(loadLoginTrails(5, 1));
  };

  const handleFilter = (pSize, pIndex) => {
    setPageSize(pSize);
    setCurrentPage(pIndex);
    setDataType("filter");

    const frmBody = new FormData();
    frmBody.append("userId", userId);
    frmBody.append("userName", userName);
    frmBody.append("ip", ipAddress);
    frmBody.append("country", country);
    frmBody.append("pageSize", pSize);
    frmBody.append("pageIndex", pIndex);
    dispatch(loadLoginTrailsByFilter(frmBody));

  };

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card mb-4 shadow-none">
          <div className="card-header border-0 p-2">
            <div className="row">
              <div className="col-md-10">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-2 text-right">
                <button
                  className="btn btn-sm btn-outline-default"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  style={{ minWidth: "9rem" }}
                  onClick={handleFilterCancel}
                >
                  <i className="fa fa-search" aria-hidden="true"></i> Search
                </button>
              </div>
              <div className="col-md-12">
                <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
                  <div className="row">

                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="userId" className="form-control-label">
                          User Id
                        </label>
                        <input
                          type="text"
                          name="userId"
                          id="userId"
                          className="form-control"
                          placeholder="User Id"
                          value={userId}
                          onChange={({ currentTarget }) => setUserId(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="userName"
                          className="form-control-label"
                        >
                          User Name
                        </label>
                        <input
                          type="text"
                          name="userName"
                          id="userName"
                          className="form-control"
                          placeholder="User Name"
                          value={userName}
                          onChange={({ currentTarget }) => setUserName(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="ipAddress" className="form-control-label">
                          IP Address
                        </label>
                        <input
                          type="text"
                          name="ipAddress"
                          id="ipAddress"
                          className="form-control"
                          placeholder="IP Address"
                          value={ipAddress}
                          onChange={({ currentTarget }) => setIpAddress(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="country" className="form-control-label">
                          Country
                        </label>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          className="form-control"
                          placeholder="Country"
                          value={country}
                          onChange={({ currentTarget }) => setCountry(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-center ">
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={() => handleFilter(5, 1)}
                      >
                        <i className="fa fa-search mr-2" aria-hidden="true"></i>Search
                      </button>
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
                      </button>
                      <button
                        className="btn-sm btn-outline-danger mr-2"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <div className="table-responsive border">
              <LogsTable data={logs}></LogsTable>
              <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                itemsCount={rowsCount}
                onPageChange={handlePageChange}
                startIndex={startIndex}
                endIndex={endIndex}
              ></Pagination>
            </div>

          </div>
        </div>
      </div>
      <LocationDetails></LocationDetails>
      <BrowserDetails></BrowserDetails>
    </React.Fragment>
  );
}

export default MonitoringLogs;
