import React, { Component, useEffect, useState } from "react";
import PageSizeOptions from "../components/PageSizeOptions";
import Pagination from "../comman/pagination";
import { calculateIndex, paginate } from "../utlis/paginate";
import http from "../services/HttpService";
import auth from "../services/authService";
import Moment from "react-moment";
import config from "../config.json";
import { Link, useNavigate } from "react-router-dom";
import PurchaseOrderPopup from "./PurchaseOrderPopup";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerPerformers, loadPartnerPerformers } from "../app/store/partner";
import { getFieldData, getFormatedNumber } from "../services/appService";
import { getComponentUserStatus, getToolsCount } from "../app/store/tools";

export default function SubRecCandidates(props) {

  const { item: mainItem, workflowTask, item, module, app, appType,
    procType, parentApplication, currentTask } = props;

  const [candidateList, setCandidateList] = useState([]);
  const [purchaseShowHide, setPurchaseShowHide] = useState(false);
  const [candidateObj, setCandidateObj] = useState("");
  const [type, setType] = useState("partner");
  const [poOrderType, setPoOrderType] = useState("");
  const [poCount, setPoCount] = useState(0);

  const partnerPerformerSelector = useSelector(getPartnerPerformers);
  const toolsCountSelector = useSelector(getToolsCount);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    if (item?.partnerId)
      dispatch(loadPartnerPerformers(item.partnerId));

  }, []);

  useEffect(() => {
    setCandidateList(partnerPerformerSelector);
  }, [partnerPerformerSelector])

  const handlePurchaseShowHide = (item, type) => {
    setPurchaseShowHide(!purchaseShowHide);
    setCandidateObj(item);
    setPoOrderType(type)
  };

  const handleViewInvoice = (obj) => {
    navigate("/CandidateInvoice", { state: { item: obj, mainItem, type: procType, module, appType, app, parentApplication, task: workflowTask } });
  }

  const getPoCount = (obj) => {
    let poType = "recruiter";
    if (item?.partnerType === "Sub-Contractors") {
      poType = "subcontractor";
      //return getReadUnreadBgColor(50)
    }
    else {
      //return getReadUnreadBgColor(51)
    }

    let pcount = obj.poTypes.filter(x => x.type === poType && x.partnerSign !== true).length;
    if (pcount === 0) {
      pcount = obj.poTypes.filter(x => x.type === poType).length;
      return <span className="badge badge-pill badge-success position-absolute mt--2">
        {pcount}
      </span>
    } else {
      return <span className="badge badge-pill badge-danger position-absolute mt--2">
        {pcount}
      </span>
    }

  }

  const getCount = (id) => {
    const toolObj = toolsCountSelector.filter(x => x.key == id);
    if (toolObj.length > 0) return toolObj[0].value;
    return 0;
  }

  const getReadUnreadBgColor = (id) => {
    // const totalPo=getPoCount(obj);

    // if (poCount === 0) {
    //   return <span className="badge badge-pill badge-success position-absolute mt--2">
    //     {totalPo}
    //   </span>
    // }
    // else {
    //   return <span className="badge badge-pill badge-danger position-absolute mt--2">
    //     {poCount}
    //   </span>
    // }

    const compObj = componentUserStatusSelector.filter(x => x.compId === id);

    if (compObj.length > 0) {
      const unreadComps = compObj[0].unreadCompItems.split(',').sort();
      const readComps = compObj[0].compItems.split(',').sort();
      let unReadCount = 0;
      unreadComps.filter(x => x !== "").map(x => {
        const rs = readComps.filter(y => y === x);
        if (rs.length === 0) {
          unReadCount += 1;
        }
      });

      if (unReadCount === 0) {
        return <span className="badge badge-pill badge-success position-absolute mt--2">
          {getCount(id)}
        </span>
      }
      else {
        return <span className="badge badge-pill badge-danger position-absolute mt--2">
          {unReadCount}
        </span>
      }
    } else {
      return <span className="badge badge-pill badge-danger position-absolute mt--2">
        {getCount(id)}
      </span>
    }
  }
  const handleSignUnsignPo = (unSignPo) => {
    setPoCount(unSignPo);
  }

  const handleRefreshPoOrders = () => {
    if (item?.partnerId)
      dispatch(loadPartnerPerformers(item.partnerId));
  }

  return (
    <>
      <div className="">

        <div className="table-responsive">
          <table className="table align-items-center table-flush table-hover table-striped mb-2 border">
            <thead className="thead-light">
              <tr>
                <th>CANDIDATE #<br />IPCS ID</th>
                <th>Candidate Details</th>
                <th>ENTITY TYPE</th>
                <th>Agency/Client</th>
                <th>Rate/Hr</th>
                <th>Duration<br />Start-End</th>
                <th>Onboarding<br />Status</th>
                <th>Purchase<br />Orders</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {candidateList.map((item) => (
                <tr>
                  <td>
                    {item.performerid}
                    <br />
                    {item.maxloans}
                  </td>
                  <td>
                    <span className="text-uppercase font-weight-bold">
                      {item.performername} {item.lastName}
                    </span>
                    <br />
                    {item.performeremail}
                    <br />
                    {item.contactNo && getFormatedNumber(item.contactNo)}
                    <br />
                    {getFieldData(
                      item.performerData,
                      "projectengagement",
                      "PositionTitle"
                    )}
                  </td>
                  <td>
                    {getFieldData(
                      item.performerData,
                      "candidateonboardingform",
                      "CandidateEntity"
                    )}
                  </td>
                  <td>
                    {getFieldData(
                      item.performerData,
                      "projectengagement",
                      "AgencyName"
                    )}
                  </td>
                  <td>
                    <b>
                      ${item.rates && module === "Recruiter"
                        ? item.rates.recruiterRate
                        : item.rates.subcontractorRate}/Hr
                    </b>
                  </td>
                  <td>

                    {item.duration !== null && (
                      <React.Fragment>
                        {item.duration.duration} Months
                        <br />
                        Start :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.startDate}
                        </Moment>
                        <br />
                        End :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.endDate}
                        </Moment>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {item.appSubmit && (
                      "Complete"
                    )}
                    {!item.appSubmit && (
                      "Pending"
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-link p-0 text-left"
                      onClick={() => handlePurchaseShowHide(item, "view")}
                    >
                      View

                      {getPoCount(item)}
                    </button>
                    <br />
                    <button
                      className="btn btn-sm btn-link p-0 text-left"
                      onClick={() => handlePurchaseShowHide(item, "add")}
                    >
                      Generate/Upload
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleViewInvoice(item)}
                      className="btn btn-sm btn-link p-0"
                    >
                      Go To Invoices
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
      <PurchaseOrderPopup
        purchaseShowHide={purchaseShowHide}
        handlePurchaseShowHide={handlePurchaseShowHide}
        candidateObj={candidateObj}
        workflowTask={workflowTask}
        poOrderType={poOrderType}
        item={item}
        handleSignUnsignPo={handleSignUnsignPo}
        handleRefreshPoOrders={handleRefreshPoOrders}
      ></PurchaseOrderPopup>
    </>
  );
}
