import React, { useEffect, useState } from "react";
import { getCurrentUser, getLoggedUserDetails, getUserProfilePic, loadUserDetails, loadUserProfilePic } from "../../store/auth";
import ChangePassword from "../../../components/ChangePassword";
import Help from "../../../components/Anlytics/Help";
import SupportPupup from "../../../components/Anlytics/SupportPupup";
import { useDispatch, useSelector } from "react-redux";
import { getPageTitle } from "../../store/layoutUI";
import AddSecurityQuestion from "../../../components/AddSecurityQuestion";
import PartnerProfile from "../../../components/PartnerManage/PartnerProfile";

const TopMenu = () => {

    const [showHide, setShowHide] = useState(false);
    const [helpShowHide, setHelpShowHide] = useState(false);
    const [issueShowHide, setIssueShowHide] = useState(false);
    const [securityShowHide, setSecurityShowHide] = useState(false);
    const [profileShowHide, setProfileShowHide] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [profilePicData, setProfilePicData] = useState(null);

    const pageTitleSelector = useSelector(getPageTitle);
    const loggedUserDetailsSelector = useSelector(getLoggedUserDetails);
    const userProfilePicSelector = useSelector(getUserProfilePic);

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (currentUser?.id) {
            dispatch(loadUserDetails(currentUser.id));
            dispatch(loadUserProfilePic(currentUser.id));
        }
    }, []);

    useEffect(() => {
        if (loggedUserDetailsSelector)
            setUserDetails(loggedUserDetailsSelector)
    }, [loggedUserDetailsSelector]);


    useEffect(() => {
        if (userProfilePicSelector) {
            //let objectURL = 'data:image/jpeg;base64,' + userProfilePicSelector;
            setProfilePicData(userProfilePicSelector);
        }

    }, [userProfilePicSelector])

    let usrType = localStorage.getItem("userType");

    const handleModalShowHide = () => {
        setShowHide(!showHide);
    };

    const handleHelpShowHide = () => {
        setHelpShowHide(!helpShowHide);
    };

    const handleIssueShowHide = () => {
        setIssueShowHide(!issueShowHide);
    };

    const handleSecurityShowHide = () => {
        setSecurityShowHide(!securityShowHide);
    };

    const handleCurrentTask = async (wiid) => {
        // const { data: workflowTask } = await http.get(
        //   config.GetWorkflowTask + wiid
        // );
        // if (workflowTask.formId > 0) {
        //   if (workflowTask.formType === "CF") {
        //     this.props.history.push({
        //       pathname: "/" + workflowTask.formName,
        //       state: { workflowTask },
        //     });
        //   } else {
        //     this.props.history.push({
        //       pathname: "/DynamicHtmlForm",
        //       state: { workflowTask },
        //     });
        //   }
        // }
    };

    const handleWorkflowTask = (data) => {
        // this.props.history.push({
        //   pathname: "/DynamicHtmlForm",
        //   state: { workflowTask: data },
        // });
    };

    const handleProfileShowHide = () => {
        setProfileShowHide(!profileShowHide);
    };

    let $imagePreview = null;
    $imagePreview = <img
        alt="profile" className="img-thumbnail rounded-circle" style={{ height: "60px", width: "60px" }}
        src={`${!profilePicData ? "../../assets/img/theme/team-4.png" : process.env.REACT_APP_API_BASEURL + "/ProfilePics/" + profilePicData}`}
    />

    return <>
        <div className="row">
            <div
                className="col-md-8 p-2"
            >
                <img
                    src={`/assets/Img/logo.png`}
                    alt=""
                    style={{
                        height: "80px",
                    }}
                />
            </div>
            <div className="col-md-4 p-2 d-flex justify-content-end">
                <nav className="navbar navbar-top navbar-expand navbar-dark p-0">
                    <div className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >

                        <ul className="navbar-nav align-items-center ml-auto ml-md-0">
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link pr-0"
                                    href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <div className="media align-items-center">
                                        {$imagePreview}
                                        {currentUser && <div className="media-body ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold text-dark text-uppercase">
                                                <b>{currentUser.name}</b>
                                            </span>
                                            <h5 className="text-dark">
                                                Reg ID :{" "}
                                                {currentUser.type === "partner"
                                                    ? currentUser.linkUser
                                                    : currentUser.id}

                                                {currentUser.type === "partner" &&
                                                    `"Partner ID :" ${currentUser.id} `}
                                                <br />
                                                Role :{" "}
                                                <span className="text-capitalize">
                                                    
                                                    {usrType === "WP" ? "Workfloe Performer" : usrType}
                                                </span>
                                                <br />
                                                Last Login : {(new Date()).toDateString()}
                                            </h5>
                                        </div>}
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item"
                                        onClick={handleProfileShowHide}
                                    >
                                        <i className="ni ni-single-02" />
                                        <span>My Profile</span>
                                    </button>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleModalShowHide}
                                    >
                                        <i className="ni ni-settings-gear-65" />
                                        <span>Change Password</span>
                                    </button>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleSecurityShowHide}
                                    >
                                        <i className="fa fa-shield" />
                                        <span>Change Security Questions</span>
                                    </button>
                                    {/* <button
                                        className="dropdown-item"
                                        onClick={handleHelpShowHide}
                                    >
                                        <i className="ni ni-support-16" />
                                        <span>Help</span>
                                    </button> */}

                                    <div className="dropdown-divider" />
                                    <a href="/logout" className="dropdown-item">
                                        <i className="ni ni-user-run" />
                                        <span>Logout</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        <div className="row border pt-2 pb-2">
            <div className="col-md-3">
                <img
                    src="/assets/img/wflogoblack.png"
                    className="navbar-brand-img"
                    style={{ height: "28px" }}
                />
                <button className="btn btn-sm btn-outline-default text-capitalize">
                    Workbench Portal
                </button>
            </div>
            <div className="col-md-6 text-center">
                <span className="h3 text-dark">
                    HUMAN RESOURCES ONBOARDING, CONTRACT AND INVOICING SYSTEM
                </span>
            </div>
            <div className="col-md-3 text-right">
                <button
                    onClick={handleIssueShowHide}
                    className="btn btn-sm btn-outline-default mr-2"

                    title="Any defects/issues found in the Portal can be registered in the Customer Support Portal."
                >
                    <i className="fa fa-support"></i> Customer Support
                </button>
                <button
                    className="btn btn-sm btn-outline-default m-0"
                    style={{ minWidth: "8.56rem" }}
                    onClick={handleHelpShowHide}
                >
                    <i className="fa fa-question-circle"></i> Help
                </button>
            </div>
        </div>
        {showHide &&
            <ChangePassword
                handleModalShowHide={handleModalShowHide}
                showHide={showHide}
            ></ChangePassword>}
        {profileShowHide &&
            <PartnerProfile
                handleProfileShowHide={handleProfileShowHide}
                profileShowHide={profileShowHide}
            ></PartnerProfile>}
        {helpShowHide &&
            <Help
                helpShowHide={helpShowHide}
                handleHelpShowHide={handleHelpShowHide}
                module={pageTitleSelector}
            ></Help>}
        {issueShowHide &&
            <SupportPupup
                handleIssueShowHide={handleIssueShowHide}
                issueShowHide={issueShowHide}
                handleCurrentTask={handleCurrentTask}
                handleWorkflowTask={handleWorkflowTask}
            ></SupportPupup>}

        {securityShowHide && <AddSecurityQuestion
            securityShowHide={securityShowHide}
            handleSecurityShowHide={handleSecurityShowHide}
            userDetails={userDetails}
        ></AddSecurityQuestion>}
    </>
}

export default TopMenu;