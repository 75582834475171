import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../../comman/form";
import auth from "../../services/authService";
import http from "../../services/HttpService";
import config from "../../config.json";
import Swal from "sweetalert2";

class UploadInvoice extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
    };
  }

  schema = {};

  componentDidMount() {}

  doSubmit = async () => {
    const { data, documents } = this.state;
    const user = auth.getCurrentUser();
    const { wfTask: workflowTask } = this.props;
    let docId = 0;
    if (documents.length > 0) {
      const frmData = new FormData();
      frmData.append("message", data["notes"]);
      frmData.append("workflowTask", JSON.stringify(workflowTask));
      frmData.append("userId", user.id);
      documents.map((file, key) =>
        frmData.append("files[" + key + "]", file.file)
      );
      const { data: result } = await http.post(
        config.ApplicantUploadDocument,
        frmData
      );
      docId = result;
    }
    const { selectedObj } = this.props;
    const tmObj = {
      id: selectedObj.id,
      invoiceDocId: docId,
      notes: data["notes"],
      remarks: data["invNumber"],
      docUploadType: "agency",
    };
    const { data: result } = await http.post(
      config.UpdateTimeLogInvoice,
      tmObj
    );
    this.props.handleInvShowHide("save");
    this.getAlert("Added Successfully", "", "success");
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  onFileChange = async (event) => {
    const { documents: docs } = this.state;
    for (let file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  render() {
    const { invShowHide, handleInvShowHide } = this.props;
    return (
      <Modal show={invShowHide} size="xl">
        <Modal.Header closeButton onClick={() => handleInvShowHide("")}>
          <Modal.Title>Upload Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12">
              {this.renderInput("invNumber", "Invoice Number")}
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="invfile" className="form-control-label">
                Upload Invoice File
              </label>
              <input
                type="file"
                name="invfile"
                id="invfile"
                className="form-control"
                onChange={this.onFileChange}
              />
            </div>
            <div className="col-md-12">
              {this.renderTextArea("notes", "Notes")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleInvShowHide("")}>
          <i className="fa fa-times"></i> Close
          </button>
          <button className="btn btn-sm btn-outline-default" onClick={this.handleSubmit}>
          <i className="fa fa-check"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UploadInvoice;
