import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "candidates",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        filterList: [],
        allFilterList: [],
        reminder: "",
        apps: [],
        parentApps: [],
        subProccessApps: [],
        addedUser: null,
        updatedStatus: null,
        bcDeleteStatus: null,
        latestIpcsId: 0,
        allCandidateList: [],
        backgroundChecks: [],
        checkIpcsId: null,
        candidateBprmForm: null,
        selectedCandidateObj: []
    },
    reducers: {
        candidateAdded: (candidates, action) => {
            candidates.addedUser = action.payload;
            candidates.checkIpcsId = null;
        },
        candidateUpdated: (candidates, action) => {
            candidates.updatedStatus = action.payload;
            candidates.checkIpcsId = null;
        },
        candidatesReceived: (candidates, action) => {
            candidates.list = action.payload;
            candidates.reminder = "";
            candidates.addedUser = null;
        },
        filterCandidatesReceived: (candidates, action) => {
            candidates.filterList = action.payload;
            candidates.reminder = "";
            candidates.addedUser = null;
        },
        filterAllCandidatesReceived: (candidates, action) => {
            candidates.allFilterList = action.payload;
            candidates.reminder = "";
            candidates.addedUser = null;
        },
        reminderSent: (candidates, action) => {
            candidates.reminder = action.payload;
        },
        candidateAppsReceiving: (candidates, action) => {
            candidates.apps = [];
        },
        candidateAppsReceived: (candidates, action) => {
            candidates.apps = action.payload;
        },
        candidateParentAppsReceived: (candidates, action) => {
            candidates.parentApps = action.payload;
        },
        candidateSubProccessAppsReceived: (candidates, action) => {
            candidates.subProccessApps = action.payload;
        },
        candidateLatestIpcsIdReceived: (candidates, action) => {
            candidates.latestIpcsId = action.payload;
        },
        allCandidateListReceived: (candidates, action) => {
            candidates.allCandidateList = action.payload;
        },
        backgroundChecksReceived: (candidates, action) => {
            candidates.backgroundChecks = action.payload;
            candidates.bcDeleteStatus = false;
        },
        backgroundChecksDeleted: (candidates, action) => {
            candidates.bcDeleteStatus = action.payload;
        },
        checkedCandidateIpcsId: (candidates, action) => {
            candidates.checkIpcsId = action.payload;
        },
        candidateBprmFormReceived: (candidates, action) => {
            candidates.candidateBprmForm = action.payload;
        },
        selectedCandidateObjReceived: (candidates, action) => {
            candidates.selectedCandidateObj = action.payload;
        },
    }
});

export const { candidateAdded, candidatesReceived,
    filterCandidatesReceived,
    filterAllCandidatesReceived,
    reminderSent,
    candidateAppsReceiving,
    candidateAppsReceived,
    candidateParentAppsReceived,
    candidateUpdated,
    candidateLatestIpcsIdReceived,
    allCandidateListReceived,
    candidateSubProccessAppsReceived,
    backgroundChecksReceived,
    backgroundChecksDeleted,
    checkedCandidateIpcsId,
    candidateBprmFormReceived,
    selectedCandidateObjReceived
} = slice.actions;
export default slice.reducer;

export const loadCandidates = (pageSize, currentPage, type) => apiCallBegan({
    url: config.GetRegisteredApplicants + pageSize + "&pageNumber=" + currentPage + "&type=" + type,
    onSuccess: candidatesReceived.type
});

export const loadCandidatesByFilter = (data) => apiCallBegan({
    url: config.GetApplicantsByFilter,
    method: "post",
    data: data,
    onSuccess: filterCandidatesReceived.type
});

export const loadAllCandidatesByFilter = (data) => apiCallBegan({
    url: config.GetApplicantsByFilter,
    method: "post",
    data: data,
    onSuccess: filterAllCandidatesReceived.type
});

export const loadSelectedCandidatesByFilter = (data) => apiCallBegan({
    url: config.GetApplicantsByFilter,
    method: "post",
    data: data,
    onSuccess: selectedCandidateObjReceived.type
});

export const loadCandidateApps = (id, type, userId, appId) => apiCallBegan({
    url: config.GetApplicantApps + id + "&type=" + type + "&userId=" + userId + "&appId=" + appId,
    onStart: candidateAppsReceiving.type,
    onSuccess: candidateAppsReceived.type
});

export const loadCandidateParentApps = (id, type, userId, appId) => apiCallBegan({
    url: config.GetApplicantApps + id + "&type=" + type + "&userId=" + userId + "&appId=" + appId,
    onSuccess: candidateParentAppsReceived.type
});

export const loadCandidateSubProccessApps = (id, type, userId, appId) => apiCallBegan({
    url: config.GetApplicantApps + id + "&type=" + type + "&userId=" + userId + "&appId=" + appId,
    onSuccess: candidateSubProccessAppsReceived.type
});

export const loadAllCandidateList = () => apiCallBegan({
    url: config.GetAllCandidates,
    onSuccess: allCandidateListReceived.type
});

export const SendReminder = (id) => apiCallBegan({
    url: config.SendRemainderToApplicant + id,
    onSuccess: reminderSent.type
});

export const AddNewCandidate = (data) => apiCallBegan({
    url: config.Registration,
    method: "post",
    data: data,
    onSuccess: candidateAdded.type
})

export const updateNewCandidate = (data) => apiCallBegan({
    url: config.UpdateRegistration,
    method: "post",
    data: data,
    onSuccess: candidateUpdated.type
});

export const loadCandidateLatesIpcsId = () => apiCallBegan({
    url: config.GetLatestIpcsId,
    onSuccess: candidateLatestIpcsIdReceived.type
});

export const loadBackgroundChecks = (userId) => apiCallBegan({
    url: config.GetBackgroundChecks + userId,
    onSuccess: backgroundChecksReceived.type
});

export const deleteBackgroundChecks = (id) => apiCallBegan({
    url: config.DeleteBackgroundChecks + id,
    onSuccess: backgroundChecksDeleted.type
});

export const checkCandidateIpcsId = (id) => apiCallBegan({
    url: config.ValidateDuplicateIpcsId + id,
    onSuccess: checkedCandidateIpcsId.type
});

export const loadCandidateBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: candidateBprmFormReceived.type
});

export const getAllCandidates = createSelector(
    state => state.entities.candidates,
    candidates => candidates.list
);

export const getFilterAllCandidates = createSelector(
    state => state.entities.candidates,
    candidates => candidates.allFilterList
);

export const getFilterCandidates = createSelector(
    state => state.entities.candidates,
    candidates => candidates.filterList
);

export const getSelectedCandidateObj = createSelector(
    state => state.entities.candidates,
    candidates => candidates.selectedCandidateObj
);

export const getReminderStatus = createSelector(
    state => state.entities.candidates,
    candidates => candidates.reminder
)

export const getCandidateApps = createSelector(
    state => state.entities.candidates,
    candidates => candidates.apps
)

export const getCandidateParentApps = createSelector(
    state => state.entities.candidates,
    candidates => candidates.parentApps
)

export const getCandidateSubProccessApps = createSelector(
    state => state.entities.candidates,
    candidates => candidates.subProccessApps
)

export const getAddedCandidate = createSelector(
    state => state.entities.candidates,
    candidates => candidates.addedUser
);

export const getCandidateUpatedStatus = createSelector(
    state => state.entities.candidates,
    candidates => candidates.updatedStatus
);

export const getCandidateLatestIpcsId = createSelector(
    state => state.entities.candidates,
    candidates => candidates.latestIpcsId
);

export const getAllCandidateList = createSelector(
    state => state.entities.candidates,
    candidates => candidates.allCandidateList
);

export const getBackgroundChecks = createSelector(
    state => state.entities.candidates,
    candidates => candidates.backgroundChecks
)

export const getBcDeleteStatus = createSelector(
    state => state.entities.candidates,
    candidates => candidates.bcDeleteStatus
)

export const getCheckIpcsIdStatus = createSelector(
    state => state.entities.candidates,
    candidates => candidates.checkIpcsId
);

export const getCandidateBprmForm = createSelector(
    state => state.entities.candidates,
    candidates => candidates.candidateBprmForm
);