import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "application",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        appSummary: "",
        appUpdates: [],
        appDefaultData: "",
        appStartedData: "",
        subProcessApp: 0,
        subPrcBprmForm: null,
        processBprmForm: null,
        summaryBprmForm: null
    },
    reducers: {
        applicationReceived: (application, action) => {
            application.list = action.payload;
        },
        appReceived: (application, action) => {
            application.list.push(action.payload);
        },
        appSummaryReceived: (application, action) => {
            application.appSummary = action.payload;
        },
        appUpdatesReceived: (application, action) => {
            application.appUpdates = action.payload;
        },
        appDefaultDataReceived: (application, action) => {
            application.appDefaultData = action.payload;
        },
        appStartedDataReceived: (application, action) => {
            application.appStartedData = action.payload;
        },
        subProcessAppReceived: (application, action) => {
            application.subProcessApp = action.payload;
        },
        subProcessBprmFormReceived: (application, action) => {
            application.subPrcBprmForm = action.payload;
        },
        processBprmFormReceived: (application, action) => {
            application.processBprmForm = action.payload;
        },
        summaryBprmFormReceived: (application, action) => {
            application.summaryBprmForm = action.payload;
        }
    }
});

export const { applicationReceived, appReceived,
    appSummaryReceived, appUpdatesReceived, appDefaultDataReceived,
    appStartedDataReceived, subProcessAppReceived,
    subProcessBprmFormReceived, processBprmFormReceived, summaryBprmFormReceived } = slice.actions;
export default slice.reducer;

export const loadApplication = (id) => apiCallBegan({
    url: config.GetApplication + id,
    onSuccess: appReceived.type
});

export const loadAppSummary = (id) => apiCallBegan({
    url: config.GetApplicationSummary + id,
    onSuccess: appSummaryReceived.type
});

export const loadAppUpdates = (id) => apiCallBegan({
    url: config.GetAppUpdateDataHistory + id,
    onSuccess: appUpdatesReceived.type
});

export const loadAppDefaultData = (id) => apiCallBegan({
    url: config.GetWorkfinityAppEntity + id,
    onSuccess: appDefaultDataReceived.type
});

export const startNewApplication = (data) => apiCallBegan({
    url: config.StartNewApplication,
    method: "post",
    data: data,
    onSuccess: appStartedDataReceived.type
});

export const loadSubProccessApp = (data) => apiCallBegan({
    url: config.GetSubprocessApp,
    method: "post",
    data: data,
    onSuccess: subProcessAppReceived.type
});

export const loadSubPrcBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: subProcessBprmFormReceived.type
});
export const loadProcessBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: processBprmFormReceived.type
});
export const loadSummaryBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: summaryBprmFormReceived.type
});

export const getApplication = createSelector(
    state => state.entities.application,
    application => application.list
);

export const getAppSummary = createSelector(
    state => state.entities.application,
    application => application.appSummary
);

export const getAppUpdates = createSelector(
    state => state.entities.application,
    application => application.appUpdates
);

export const getAppDefaultData = createSelector(
    state => state.entities.application,
    application => application.appDefaultData
);

export const getStartedAppData = createSelector(
    state => state.entities.application,
    application => application.appStartedData
);

export const getSubProccessApp = createSelector(
    state => state.entities.application,
    application => application.subProcessApp
);

export const getSubProccessBprmForm = createSelector(
    state => state.entities.application,
    application => application.subPrcBprmForm
);

export const getProccessBprmForm = createSelector(
    state => state.entities.application,
    application => application.processBprmForm
);

export const getSummaryBprmForm = createSelector(
    state => state.entities.application,
    application => application.summaryBprmForm
);

export const getApplicationData = (appData) => {
    const data = {};
    const aWorkfinityApp = JSON.parse(appData);
    const appShortName = Object.keys(aWorkfinityApp)[1];
    const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
    Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
        if (
            keyValue !== "@xmlns:xsd" &&
            keyValue !== "@xmlns:xsi" &&
            keyValue !== "EntityId"
        ) {
            Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
                keyValue1
            ) {
                if (keyValue1 !== "EntityId") {
                    var item = aWorkfinityAppEntity[keyValue][keyValue1];
                    if (typeof item === "object") {
                        if (Array.isArray(item))
                            data[keyValue + "." + keyValue1.replace("@", "")] = item;
                        else
                            data[keyValue + "." + keyValue1.replace("@", "")] = [item];
                    } else {
                        data[keyValue + "." + keyValue1.replace("@", "")] = item;
                    }
                }
            });
        }
    });
    const obj = { data, aWorkfinityApp, appShortName, aWorkfinityAppEntity }
    return obj;
}

