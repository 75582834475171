import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import geo from "../../services/geoService";
import config from "../../config.json";
import Joi from "joi-browser";
import Form from "../../comman/form";
import AddSechduleTime from "./AddSechduleTime";

class ScheduleMeetingPopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      scheduleList: [],
      data: {},
      errors: {},
      selectedStateId: "",
      selectedState: "",
      selectedCountyId: "",
      selectedCounty: "",
      stateList: [],
      countyList: [],
      secShowHide: false,
      meetingJson: "",
      meetingProviders: [],
    };
  }

  schema = {
    remarks: Joi.string().required().label("Comment"),
    //schedule: Joi.string().required().label("Schedule Meeting"),
  };

  async componentDidMount() {
    const stateList = await geo.getStateList();
    this.setState({ stateList });
  }

  addSchedule = () => {
    const scheduleList = [...this.state.scheduleList];

    var curr = new Date();
    var date = curr.toISOString().substr(0, 10);

    let hours = curr.getHours();
    if (hours < 10) hours = `0${hours}`;
    let minutes = curr.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;
    //console.log(hours);
    //console.log(minutes);
    const newSchedule = {
      meetingDate: date,
      meetingTime: `${hours}:${minutes}`,
      optionId: scheduleList.length + 1,
    };
    scheduleList.push(newSchedule);
    this.validateScheduleMeeting(scheduleList);
    this.setState({ scheduleList });
  };

  handleScheduleChange = ({ currentTarget }) => {
    //console.log(currentTarget.id);
    const scheduleList = [...this.state.scheduleList];
    const objId = currentTarget.id.split("_")[1];
    const currentObj = scheduleList.filter(
      (x) => x.optionId === parseInt(objId)
    );
    if (currentObj.length > 0)
      currentObj[0][currentTarget.name] = currentTarget.value;

    const updateSchedule = scheduleList.filter(
      (x) => x.optionId !== parseInt(objId)
    );
    updateSchedule.push(currentObj[0]);
    this.setState({ scheduleList: updateSchedule });
  };

  handleScheduleDelete = (id) => {
    const scheduleList = [...this.state.scheduleList];
    const removedScheduleList = scheduleList.filter((x) => x.optionId !== id);
    // this.validateScheduleMeeting(removedScheduleList);
    this.setState({ scheduleList: removedScheduleList });
  };

  validateScheduleMeeting = (scheduleList) => {
    const { data, errors } = this.state;
    if (scheduleList.length > 0) {
      data["schedule"] = scheduleList.length.toString();
      delete errors["schedule"];
    } else {
      const obj = { schedule: "" };
      const schema = { schedule: this.schema["schedule"] };
      const { error } = Joi.validate(obj, schema);
      errors["schedule"] = error.details[0].message;
      data["schedule"] = "";
    }
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    const { data: stateData, scheduleList, meetingJson, meetingProviders } = this.state;
    let providers = "";
    let comma = "";
    let data = { ...stateData };
    meetingProviders.map((m) => {
      providers += comma + m;
      comma = ",";
    });
    if (data["meetingId"] !== null && data["meetingId"] !== undefined) {
      data["meetingOptions"] = scheduleList;
      data["meetingProvider"] = providers;
      this.props.handleEditAppointment(data);
    } else {
      this.props.handleSubmit(data, scheduleList, meetingJson, providers);
    }
  };

  handleStateChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    const geoId = selectedOption.dataset.id;
    this.setState({
      selectedState: selectedOption.text,
      selectedStateId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
    this.validateStateCounty(input.name, selectedOption.text);
    await this.bindCountyDropdown(geoId);
  };

  validateStateCounty = (name, value) => {
    const { errors } = this.state;
    if (value == "Select") {
      const obj = { [name]: "" };
      const schema = { schedule: this.schema[name] };
      const { error } = Joi.validate(obj, schema);
      errors[name] = error.details[0].message;
    } else {
      delete errors[name];
    }
    this.setState({ errors });
  };

  handleCountyChanged = ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedCounty: selectedOption.text,
      selectedCountyId: selectedOption.value,
    });
    this.validateStateCounty(input.name, selectedOption.text);
    this.updateState(input.name, selectedOption.text);
  };

  updateState(name, value) {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  }

  async bindCountyDropdown(geonameId) {
    const countyList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ countyList });
  }

  componentWillReceiveProps = (nextProps) => {
    const { selectedItem } = nextProps;
    if (selectedItem) {
      if (selectedItem?.meetingId) {
        const schdList = [];
        selectedItem.meetingOptions.map((o) => {
          const obj = { ...o };
          const date = obj.meetingDate.split("T")[0];
          obj.meetingDate = date;
          schdList.push(obj);
        });
        let providers = selectedItem?.meetingProvider?.split(",");
        if (!providers) providers = [];
        this.setState({
          data: selectedItem,
          scheduleList: schdList,
          meetingProviders: providers,
        });
      }
    } else {
      this.setState({ data: { remarks: "" }, scheduleList: [], meetingProviders: [] });
    }
  };

  handleSecShowHide = () => {
    this.setState({ secShowHide: !this.state.secShowHide });
  };

  handleSubmitSchedule = (events) => {
    const scheduleList = [];
    events.map((item) => {
      const dt = new Date(item.start);
      if (item.slots.length > 0) {
        const strTime = new Date(item.slots[0])
          .toLocaleTimeString()
          .replace(/(.*)\D\d+/, "$1");
        const endTime = new Date(item.slots[item.slots.length - 1])
          .toLocaleTimeString()
          .replace(/(.*)\D\d+/, "$1");
        scheduleList.push({
          optionId: item.optionId,
          meetingDate: dt.toDateString(),
          startTime: strTime,
          endTime: endTime,
          notes: item.title,
          slotsJson: JSON.stringify(item.slots),
        });
      }
    });
    this.setState({
      secShowHide: false,
      meetingJson: JSON.stringify(events),
      scheduleList,
    });
  };

  handleMeetingProvider = ({ currentTarget }) => {
    const { meetingProviders } = this.state;
    let newMeetings = meetingProviders;
    if (currentTarget.checked) {
      newMeetings.push(currentTarget.value);
    } else {
      newMeetings = newMeetings.filter((x) => x !== currentTarget.value);
    }
    //console.log(newMeetings);
    this.setState({ meetingProviders: newMeetings });
  };

  render() {
    const {
      countyList,
      scheduleList,
      stateList,
      data,
      errors,
      selectedStateId,
      selectedCountyId,
      secShowHide,
      meetingProviders,
    } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-md-9">
            <label htmlFor="" className="form-control-label">
              To schedule appointment, create Date & Time options
            </label>
          </div>
          <div className="col-md-3 text-right">
            <button
              className="btn btn-outline-default btn-sm btn-min-width"
              onClick={this.handleSecShowHide}
            >
              <i className="fa fa-plus"></i> Add New
            </button>
          </div>
          <div className="col-md-12 form-group">
            <table className="table table-striped align-items-center table-flush mt-2 border">
              <thead className="thead-light">
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {scheduleList.map((item) => (
                  <tr>
                    <td>
                      {/* <input
                            type="date"
                            className="form-control"
                            name="meetingDate"
                            id={`date_${item.optionId}`}
                            defaultValue={item.meetingDate}
                            onChange={this.handleScheduleChange}
                          ></input> */}
                      {item.meetingDate}
                    </td>
                    <td>
                      {/* <input
                            type="time"
                            className="form-control"
                            name="meetingTime"
                            id={`time_${item.optionId}`}
                            defaultValue={item.meetingTime}
                            onChange={this.handleScheduleChange}
                          ></input> */}
                      {item.startTime} - {item.endTime}
                    </td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          this.handleScheduleDelete(item.optionId)
                        }
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {errors["schedule"] && (
              <div className="alert alert-danger">{errors["schedule"]}</div>
            )}
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="microsoftTeams"
                  type="checkbox"
                  name="microsoftTeams"
                  value="team"
                  onChange={this.handleMeetingProvider}
                  checked={
                    meetingProviders.filter((x) => x === "team").length > 0
                  }
                />

                <label
                  className="custom-control-label form-control-label"
                  htmlFor="microsoftTeams"
                >
                  Microsoft Teams {`(${this.props.partitem?.contact1?.performeremail})`}
                </label>
              </div>
              {/* <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="microsoftCalender"
                      type="checkbox"
                      name="microsoftCalender"
                      value="calender"
                      onChange={this.handleMeetingProvider}
                      defaultChecked={
                        meetingProviders.filter((x) => x === "calender")
                          .length > 0
                      }
                    />

                    <label
                      className="custom-control-label form-control-label"
                      htmlFor="microsoftCalender"
                    >
                      Microsoft Calender
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="googleCalender"
                      type="checkbox"
                      name="googleCalender"
                      value="google"
                      onChange={this.handleMeetingProvider}
                      defaultChecked={
                        meetingProviders.filter((x) => x === "google").length >
                        0
                      }
                    />

                    <label
                      className="custom-control-label form-control-label"
                      htmlFor="googleCalender"
                    >
                      Google Calender
                    </label>
                  </div> */}
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="zoomMeeting"
                  type="checkbox"
                  name="zoomMeeting"
                  value="zoom"
                  onChange={this.handleMeetingProvider}
                  checked={
                    meetingProviders.filter((x) => x === "zoom").length > 0
                  }
                />

                <label
                  className="custom-control-label form-control-label"
                  htmlFor="zoomMeeting"
                >
                  Zoom Meeting
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="googleMeet"
                  type="checkbox"
                  name="googleMeet"
                  value="meet"
                  onChange={this.handleMeetingProvider}
                  checked={
                    meetingProviders.filter((x) => x === "meet").length > 0
                  }
                />

                <label
                  className="custom-control-label form-control-label"
                  htmlFor="googleMeet"
                >
                  Google Meet
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12 form-group">
            <label htmlFor="remarks" className="form-control-label">
              Notes
            </label>
            <textarea
              name="remarks"
              id="remarks"
              cols="3"
              rows="3"
              value={data["remarks"]}
              className="form-control"
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="col-md-12 text-center">
            <button
              className="btn btn-sm btn-outline-default"
              disabled={this.validate()}
              onClick={this.doSubmit}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i> Send
            </button>
          </div>
        </div>

        <AddSechduleTime
          secShowHide={secShowHide}
          handleSecShowHide={this.handleSecShowHide}
          handleSubmitSchedule={this.handleSubmitSchedule}
        ></AddSechduleTime>
      </>
    );
  }
}

export default ScheduleMeetingPopup;
