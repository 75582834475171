import React, { Component } from "react";
import http from "../services/HttpService";
import config from "../config.json";
import Form from "../comman/form";
import Joi from "joi-browser";
import geo from "../services/geoService";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../comman/toastTemplate";
import Swal from "sweetalert2";
import { getAlert } from "../services/appAlerts";

class AddWorkflowPerformer extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      stateList: [],
      countyList: [],
      selectedStateId: "",
      selectedState: "",
      selectedCountyId: "",
      selectedCounty: "",
      externalInterface: [],
      modalShow: "",
      selectedOption: "",
      allRoles: [],
    };
  }

  async componentDidMount() {
    const stateList = await geo.getStateList();
    const { data: allRoles } = await http.get(
      config.GetCodemanager + "County User Title"
    );
    this.setState({ stateList, allRoles });
  }

  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    contact: Joi.string()
      .length(10)
      .options({
        language: {
          string: {
            length: "Contact Number must be 10 digits.",
          },
        },
      })
      .options({})
      .regex(/^[0-9]+$/)
      .options({
        language: {
          string: {
            regex: {
              base: "This entry can only contain numbers",
            },
          },
        },
      })
      .label("Contact Number"),
    //username: Joi.string().required().label("Username"),
  };

  doSubmit = async () => {
    const { data: performer } = this.state;
    this.props.handleFormSubmit(performer);
    if (performer["id"] > 0) {
      getAlert("", "Updated Successfully", "success");
    } else {
      getAlert("", "Added Successfully", "success");
    }
    this.props.handlePermissionShowHide();
  };

  handleStateChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedState: selectedOption.text,
      selectedStateId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
    await this.bindCountyDropdown(selectedOption.value);
  };

  handleCountyChanged = ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedCounty: selectedOption.text,
      selectedCountyId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
  };

  updateState(name, value) {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  }

  async bindCountyDropdown(geonameId) {
    const countyList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ countyList });

    return countyList;
  }

  handleChange1 = (selectedOption) => {
    const data = { ...this.state.data };
    let interfaces = [];
    selectedOption.map((item) =>
      interfaces.push({ interfaceName: item.value, interfaceName: item.label })
    );
    data["accessInterfaces"] = interfaces;
    this.setState({ data, selectedOption });
  };

  componentWillReceiveProps = async (nextProps) => {
    const { selectedObj } = nextProps;
    console.log(selectedObj, "selectedObj")
    if (selectedObj) {
      const { stateList } = this.state;
      this.setState({ data: selectedObj });
      const selectedState = stateList.filter(
        (x) => x.name === selectedObj.state
      );
      if (selectedState.length > 0) {
        this.setState({ selectedStateId: selectedState[0].geonameId });
        const countyList = await this.bindCountyDropdown(
          selectedState[0].geonameId
        );
        const selectedCounty = countyList.filter(
          (x) => x.name === selectedObj.county
        );
        if (selectedCounty.length > 0) {
          this.setState({ selectedCountyId: selectedCounty[0].geonameId });
        }
        if (selectedObj.accessInterfaces.length > 0) {
          const options = [];
          selectedObj.accessInterfaces.map((item) =>
            options.push({ value: item.id, label: item.interfaceName })
          );
          this.setState({ selectedOption: options });
        }
      }
    }
    else {
      this.setState({ data: {}, selectedStateId: "", selectedCountyId: "", selectedOption: [] })
    }
  };

  render() {
    const {
      stateList,
      countyList,
      selectedCountyId,
      selectedStateId,
      externalInterface,
      selectedOption,
      hcpUserTypes,
      allRoles,
    } = this.state;

    const options = [
      { value: "1", label: "DDRMS" },
      { value: "2", label: "BPRM" },
      // { value: "2", label: "Payment Gateway" },
      // { value: "3", label: "Docusign eSignature" },
    ];

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {this.renderSelect("title", "User Type", allRoles, "name", "name")}
          </div>
          <div className="col-lg-6">{this.renderInput("name", "Name")}</div>
          <div className="col-lg-6">{this.renderInput("email", "Email")}</div>
          <div className="col-lg-6">
            {this.renderInput("contact", "Mobile No.", "phone")}
          </div>
          <div className="col-lg-6 form-group">
            <label className="form-control-label" htmlFor="stateList">
              State
            </label>
            <select
              className="form-control"
              id="stateList"
              name="state"
              value={selectedStateId}
              onChange={this.handleStateChanged}
            >
              <option value="0">All</option>
              {stateList.map((s) => (
                <option key={s.geonameId} value={s.geonameId}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-lg-6 form-group">
            <label className="form-control-label" htmlFor="countyList">
              City
            </label>
            <select
              className="form-control"
              id="countyList"
              name="county"
              value={selectedCountyId}
              onChange={this.handleCountyChanged}
            >
              <option value="0">All</option>
              {countyList.map((s) => (
                <option key={s.geonameId} value={s.geonameId}>
                  {s.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="col-lg-6">{this.renderInput("county", "City")}</div>
          <div className="col-lg-12 form-group">
            <label className="form-control-label" htmlFor="stateList">
              Access to external applications?
            </label>
            <Select
              value={selectedOption}
              onChange={this.handleChange1}
              options={options}
              isMulti={true}
            />
          </div>

          <div className="col-md-12 mt-2 text-center">
            <button
              className="btn btn-sm btn-outline-default"
              disabled={this.validate()}
              onClick={this.doSubmit}
            >
              <i class="fa fa-check" aria-hidden="true"></i> Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default AddWorkflowPerformer;
