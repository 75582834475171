import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import TaskInfo from "../TaskInfo";
import { useDispatch, useSelector } from "react-redux";
import { getSubProccessApp, getSubProccessBprmForm, loadSubPrcBprmForm, loadSubProccessApp, subProcessAppReceived, subProcessBprmFormReceived } from "../../store/application";
import { getCandidateApps, getCandidateSubProccessApps, loadCandidateApps, loadCandidateSubProccessApps } from "../../store/candidate";
import { getCurrentUser } from "../../store/auth";
import JourneySteps from "../JourneySteps";
import WorkflowElement from "../WorkflowElement";
import BcChecks from "./BcChecks";
import Tools from "../../../services/toolsJson.json";
import ToolsPopup from "../../../components/OMCRegistry/ToolsPopup";
import InternalReview from "../../../components/PortalForms/InternalReview";

const ProccessApp = (props) => {

    const { processShowHide, handleProcessShowHide,
        subProccessTask, candidateObj } = props;

    const [selectedApp, setSelectedApp] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [currentTask, setCurrentTask] = useState(null);
    const [apiCallCount, setApiCallCount] = useState(0);
    const [subProccessId, setSubProccessId] = useState(0);
    const [uploadType, setUploadType] = useState(0);
    const [toolObj, setToolObj] = useState(null);
    const [toolShowHide, setToolShowHide] = useState(false);
    const [journeyShowHide, setJourneyShowHide] = useState(true);
    const [wfForm, setWfForm] = useState(null);

    const subProccessAppSelector = useSelector(getSubProccessApp);
    const candidateSubProccessAppsSelector = useSelector(getCandidateSubProccessApps);
    const subPrcBprmFormSelector = useSelector(getSubProccessBprmForm);

    const currentUser = getCurrentUser();

    const dispatch = useDispatch();

    useEffect(() => {
        if (subProccessTask) {
            dispatch(loadSubProccessApp(subProccessTask));
        }
    }, [subProccessTask]);

    useEffect(() => {
        if (subProccessAppSelector > 0 && subProccessTask?.applicantId) {
            setSubProccessId(subProccessAppSelector);
            setApiCallCount(1);
            dispatch(loadCandidateSubProccessApps(subProccessTask.applicantId, "admin", currentUser.id, subProccessAppSelector));
            dispatch(subProcessAppReceived(0));
        }
    }, [subProccessAppSelector]);


    useEffect(() => {
        if (candidateSubProccessAppsSelector.length > 0) {
            const latestApp = candidateSubProccessAppsSelector[0];
            setSelectedApp(latestApp);
            let wfTasks = latestApp.workflowTasks.filter(
                (x) => x.itemType === 1 && x.wiid > 0 && x.taskDoneStatus === 2 //&& x.formType === "WF"
            ).sort((a, b) => a.orderNumber - b.orderNumber);

            if (wfTasks.length === 0) {
                wfTasks = latestApp.workflowTasks.filter(
                    (x) => x.itemType === 1 && x.wiid > 0 && x.taskDoneStatus === 4 //&& x.formType === "WF"
                ).sort((a, b) => b.orderNumber - a.orderNumber);
            }
            const latestTask = wfTasks[0];
            dispatch(loadSubPrcBprmForm(latestTask.formId))
            if (((currentTask?.id !== latestTask?.id) || apiCallCount > 2) && latestTask?.wiid > 0) {
                setSelectedTask(latestTask.wiid);
                setCurrentTask(latestTask);
            }
            else {
                if (apiCallCount > 0 && apiCallCount <= 2) {
                    setApiCallCount(apiCallCount + 1);
                    dispatch(loadCandidateSubProccessApps(subProccessTask.applicantId, "admin", 1, subProccessId));
                }
            }
        }
    }, [candidateSubProccessAppsSelector]);

    useEffect(() => {
        if (subPrcBprmFormSelector) setWfForm(subPrcBprmFormSelector);
    }, [subPrcBprmFormSelector])


    const handleReAssignSubmit = () => {

    }

    const handleShowHideSection = () => {

    }

    const handleJourneyShowHide = () => {
        setJourneyShowHide(!journeyShowHide);
    };

    const handleJorneySteps = (wiid) => {
        const wfTask = selectedApp.workflowTasks.filter((x) => x.wiid === wiid);
        if (wfTask.length > 0) {
            dispatch(loadSubPrcBprmForm(wfTask[0].formId));
            setSelectedTask(wfTask[0].wiid);
            setCurrentTask(wfTask[0]);
        }
    };

    const handleNextTask = () => {
        setTimeout(() => {
            if (subProccessTask)
                dispatch(loadSubProccessApp(subProccessTask));
        }, 3000);
    }

    const handleToolShowHide = (r, type) => {
        setUploadType(type);
        setToolShowHide(true);
        const comp = Tools.filter(x => x.id === r);
        if (comp.length > 0)
            setToolObj(comp[0]);
    };

    const handleToolObjShowHide = (r, p) => {
        const comp = Tools.filter(x => x.id === r);
        if (comp.length > 0) {
            const allTasks = selectedApp.workflowTasks.filter(x => x.wfPhase === p);
            if (allTasks.length > 0) {
                setWfForm(null);
                setSelectedTask(0);
            }

            setToolObj(comp[0]);
        }
    }

    const handleCloseButton = () => {
        handleProcessShowHide("");
    }

    return <>
        <Modal show={processShowHide} size="xl">
            <Modal.Header closeButton onHide={() => handleProcessShowHide("")}>
                <Modal.Title>
                    <img
                        src="/assets/Images/WFAssociationIcons/subprocess.gif"
                        title="Subprocess"
                        //onClick={() => this.handleWorkflowModalShowHide(application)}
                        style={{
                            height: "18px",
                            marginRight: "5px",
                            marginTop: "-3px",
                        }}
                    />
                    {subProccessTask?.itemName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        {currentTask && <TaskInfo
                            wfTask={currentTask}
                            app={selectedApp}
                            handleReAssignSubmit={handleReAssignSubmit}
                            handleShowHideSection={handleShowHideSection}
                        />
                        }
                    </div>
                    <div className="col-md-12">
                        <a
                            href="https://ows01.hireright.com/login/"
                            target="_blank"
                        >
                            Link To Hire Right
                        </a>
                    </div>
                    <div className="col-md-12" style={{ maxHeight: "200px", overflow: "auto" }}>
                        <BcChecks subProccessTask={currentTask} candidateObj={candidateObj} />
                    </div>
                    <div className="col-md-12">
                        <button
                            className="btn btn-sm btn-link pl-0"
                            onClick={handleJourneyShowHide}
                        >
                            {journeyShowHide ? "Hide" : "Show"} Journey Step
                        </button>
                        <table className="table table-bordered align-items-center">
                            <thead>
                                <tr>
                                    {journeyShowHide && (
                                        <th>Workflow Phases / Journey Steps</th>
                                    )}
                                    <th>Review</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedApp &&
                                    <tr>
                                        {journeyShowHide && (
                                            <td
                                                className="bg-white pl-2 pr-4 pb-5"
                                                style={{ minWidth: "30%" }}
                                            >
                                                <JourneySteps custApp={selectedApp}
                                                    handleJorneySteps={handleJorneySteps}
                                                    handleToolShowHide={handleToolShowHide}
                                                    handleToolObjShowHide={handleToolObjShowHide}
                                                //candidateObj={candidateObj}
                                                //compList={componentList.filter((x) => x.group === "Message" || x.group === "Interface" || x.group === "")}
                                                />
                                            </td>
                                        )}
                                        <td>
                                            {selectedTask === 0 && toolObj?.id === 1 && <InternalReview
                                                workflowTask={currentTask}
                                            //handleAppCommentCount={this.handleAppCommentCount}
                                            />}
                                            {wfForm &&
                                                <WorkflowElement
                                                    wfTask={currentTask}
                                                    wfForm={wfForm}
                                                    handleNextTask={handleNextTask}
                                                    handleCloseButton={handleCloseButton} />}
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-danger" onClick={() => handleProcessShowHide("")}>
                    <i class="fa fa-times" aria-hidden="true"></i> Close
                </button>
            </Modal.Footer>
        </Modal>
        <ToolsPopup
            toolShowHide={toolShowHide}
            handleToolShowHide={handleToolShowHide}
            toolObj={toolObj}
            item={selectedApp}
            task={currentTask}
            app={selectedApp}
            candidateObj={candidateObj}
            uploadType={uploadType}
        //setViewBprmFormShowHide={setViewBprmFormShowHide}
        ></ToolsPopup>
    </>
}

export default ProccessApp;